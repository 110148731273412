<script setup lang="tsx">
  import CustomDropdown from '~/components/UI/Forms/CustomDropdown.vue';
  import CustomInput from '~/components/UI/Forms/CustomInput.vue';
  import CustomTextArea from '~/components/UI/Forms/CustomTextArea.vue';
  import { get, post } from '~/services/donationScreen';
  import type { GetResponse, ContactTypeDrawer, ResponseStatus, PostResponse } from '~/types';
  import AuditLogs from '../RoutedDrawerComponents/AuditLogs.vue';
  import RoleBasedButton from '~/components/UI/Others/RoleBasedButton.vue';
  import EditPasswordModal from '../../Modals/EditPasswordModal.vue';

  const props = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const route = useRoute();

  const action = computed(() => {
    return route.query.action as string;
  });

  const formRef = ref();
  const formState = reactive({
    contactTypeName: '',
    contactTypeCategory: '',
    description: ''
  });

  const responseState = ref<ResponseStatus>('idle');
  const data = ref<ContactTypeDrawer>({} as ContactTypeDrawer);

  const isModalVisible = ref<boolean>(false);
  const editStatus = ref<ResponseStatus>('idle');

  const fetchData = async () => {
    responseState.value = 'pending';
    try {
      const response = await get<GetResponse<ContactTypeDrawer>>(`v1/get_donor_type/${props.identifier}`);
      if (response.status === 200) {
        data.value = response.data.data;
        Object.assign(formState, {
          contactTypeName: response?.data?.data?.selected_donor_type?.donor_type_name || '',
          contactTypeCategory: response?.data?.data?.selected_donor_type?.donor_type_category || '',
          description: response?.data?.data?.selected_donor_type?.donor_type_description || ''
        });
        responseState.value = 'success';
      } else {
        responseState.value = 'error';
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      responseState.value = 'error';
    }
  };

  const rules = {
    contactTypeName: [
      {
        required: true,
        message: 'Contact Type Name is required',
        trigger: 'submit'
      }
    ],
    contactTypeCategory: [
      {
        required: true,
        message: 'Contact Type Category is required',
        trigger: 'submit'
      }
    ],
    description: []
  };

  const updateStateField = (value: string, stateKey: keyof typeof formState) => {
    (formState as any)[stateKey] = value;
  };

  const submitHandler = async (modalData: { password: string }) => {
    try {
      editStatus.value = 'pending';
      const response = await post<PostResponse<ContactTypeDrawer>>(`v1/donor_type/${props.identifier}`, {
        data: {
          donor_type_name: formState.contactTypeName,
          donor_type_category: formState.contactTypeCategory,
          donor_type_description: formState.description,
          password: modalData.password
        }
      });

      if (response.status === 401) {
        editStatus.value = 'error';
        return openFailedCustomNotification('Wrong Password!');
      }

      if (response.data.status === 'success') {
        openSuccessfulCustomNotification('Donor Type Updated Successfully');
        isModalVisible.value = false;
        props.onClose();
      } else {
        openFailedCustomNotification('Failed to Update Donor Type');
      }
    } catch (error) {
      console.error(error);
      openFailedCustomNotification('Failed to Update Donor Type');
    }
  };

  const handleCreate = async () => {
    editStatus.value = 'pending';
    try {
      const response = await post<PostResponse<ContactTypeDrawer>>('v1/add_donor_type', {
        data: {
          donor_type_name: formState.contactTypeName,
          donor_type_category: formState.contactTypeCategory,
          donor_type_description: formState.description
        }
      });

      if (response.data.status === 'success') {
        openSuccessfulCustomNotification('Donor Type Created Successfully');
        editStatus.value = 'success';
        props.onClose();
      } else {
        openFailedCustomNotification('Failed to Create Donor Type');
        editStatus.value = 'error';
      }
    } catch (error) {
      console.error(error);
      openFailedCustomNotification('Failed to Create Donor Type');
      editStatus.value = 'error';
    }
  };

  const handleCreateContactType = async () => {
    try {
      await formRef.value.validate();

      if (route.query.action === 'edit') {
        return openModal();
      }

      await handleCreate();
    } catch (error) {
      console.error(error);
    }
  };

  const openModal = async () => {
    try {
      const isFormValid = await formRef.value.validate();
      if (!isFormValid) throw new Error('Form validation failed!');

      isModalVisible.value = true;
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalCancel = () => {
    isModalVisible.value = false;
  };

  onMounted(() => {
    if (props.identifier) {
      fetchData();
    }
  });
</script>

<template>
  <a-drawer
    :open="route.query.drawerFrom === 'donorTypes'"
    :title="
      action === 'edit'
        ? 'Update Donor Type'
        : action === 'add' || action === 'duplicate'
          ? 'Add New Donor Type'
          : 'Donor Type'
    "
    width="750"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    @close="props.onClose"
  >
    <template #extra>
      <RoleBasedButton
        v-if="!props.identifier || action"
        component-name="addActionButton"
        active-class="py-2 px-3 bg-blue-600 hover:bg-blue-700 text-white rounded sm-medium duration-150 border border-blue-600"
        disabled-class="py-2 px-3 rounded sm-medium duration-150 !bg-gray-200 !shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_0px_#0000000F] border-none"
        :loading="editStatus === 'pending'"
        @click="handleCreateContactType"
      >
        {{ action === 'edit' ? 'Update Donor Type' : 'Create Donor Type' }}
      </RoleBasedButton>
    </template>
    <a-form
      ref="formRef"
      class="flex flex-col gap-4 p-3 setup-drawer-form"
      :model="formState"
      :rules="rules"
      :colon="false"
      :label-col="{ style: { width: '183px', textAlign: 'left' } }"
      hide-required-mark="true"
    >
      <CustomInput
        label-text="Contact Type Name"
        name="contactTypeName"
        :value="formState.contactTypeName"
        state-key="contactTypeName"
        :is-compulsory="true"
        :is-disabled="!action"
        @update:value="updateStateField"
      />
      <CustomDropdown
        :options="[
          { title: 'Individuals', value: 'Individuals' },
          { title: 'Corporate', value: 'Corporate' },
          { title: 'Foundation', value: 'Foundation' },
          { title: 'Government', value: 'Government' },
          { title: 'Anonymous', value: 'Anonymous' },
          { title: 'NGO', value: 'NGO' }
        ]"
        label-text="Contact Type Category"
        name="contactTypeCategory"
        :value="formState.contactTypeCategory"
        state-key="contactTypeCategory"
        :rule="rules.contactTypeCategory"
        :is-compulsory="true"
        :disabled="!action"
        @update:value="updateStateField"
      />
      <CustomTextArea
        label="Description"
        name="description"
        :value="formState.description"
        state-key="description"
        :is-disabled="!action"
        @update:value="updateStateField"
      />
      <AuditLogs v-if="responseState === 'success'" :items="data?.audit_log_data" title="Contact Type" />
    </a-form>
    <EditPasswordModal
      :visible="isModalVisible"
      :loading="editStatus === 'pending'"
      modal-title="Update Donor Type"
      @update:cancel="handleModalCancel"
      @update:update="submitHandler"
    />
  </a-drawer>
</template>

<style>
  @import '~/assets/css/donationDrawer.css';

  .setup-drawer-form .ant-form-item {
    margin-bottom: 0px;
  }
</style>
