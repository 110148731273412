import { useAuth } from '~/composables/useAuth';
import { Role } from '~/types/auth';

const routeAccess = new Map([
  [
    'home',
    {
      routes: ['/'],
      roles: [
        Role.Admin,
        Role.DonationManager,
        Role.DonorManager,
        Role.ReadOnly,
        Role.Auditor,
        Role.DonorAuditor,
        Role.GrantReportManager,
        Role.ExpenseReportManager,
        Role.BudgetReportManager,
        Role.DonorReportManager,
        Role.ExpenseApprover,
        Role.ExpenseAdmin,
        Role.ExpenseSubmitter,
        Role.BudgetApprover,
        Role.BudgetMember,
        Role.GrantSubmitter,
        Role.GrantMember,
        Role.UtilitiesAccess,
        Role.VendorSubmitter,
        Role.VendorApprover,
        Role.VendorAdmin,
        Role.AdvanceSubmitter,
        Role.AdvanceApprover,
        Role.AdvanceAdmin,
        Role.OrgReportManager,
        Role.PayeeReportManager,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ]
    }
  ],
  [
    'dashboard',
    {
      routes: [
        '/',
        '/organization-analysis',
        '/donor-analysis',
        '/donation-analysis',
        '/expense-analysis',
        '/payee-analysis',
        '/budget-analysis',
        '/pledges-analysis',
        '/grant-utilization-analysis',
        '/purpose-analysis',
        '/grant-analysis',
        '/grant-utilization-report',
        '/fund-balance-report',
        '/all-grants-data-export',
        '/user-vs-grants',
        '/all-budget-data-export',
        '/expenses-report',
        '/expense-attachment-report',
        '/payment-report',
        '/donation-report',
        '/payee-expense-report'
      ],
      roles: [Role.Admin, Role.OrgReportManager, Role.ReadOnly]
    }
  ],
  [
    'expense',
    {
      routes: ['/expense-analysis', '/expenses-report', '/expense-attachment-report', '/payment-report'],
      roles: [Role.ExpenseReportManager]
    }
  ],
  [
    'payee',
    {
      routes: ['/payee-analysis', '/payee-expense-report'],
      roles: [Role.PayeeReportManager]
    }
  ],
  [
    'budget',
    {
      routes: ['/budget-analysis', '/all-budget-data-export'],
      roles: [Role.BudgetReportManager]
    }
  ],
  [
    'grants',
    {
      routes: [
        '/grant-utilization-analysis',
        '/grant-utilization-report',
        '/fund-balance-report',
        '/all-grants-data-export',
        '/all-budget-data-export',
        '/user-vs-grants'
      ],
      roles: [Role.GrantReportManager]
    }
  ],
  [
    'donation',
    {
      routes: [
        '/organization-analysis',
        '/donor-analysis',
        '/donation-analysis',
        '/pledges-analysis',
        '/donation-report'
      ],
      roles: [Role.DonorReportManager]
    }
  ],
  [
    'purpose',
    {
      routes: ['/purpose-analysis'],
      roles: [Role.Admin, Role.OrgReportManager]
    }
  ],
  [
    'expensesAddAndEdit',
    {
      routes: ['/expenses/add-new-expense', '/expenses/edit-expense'],
      roles: [
        Role.Admin,
        Role.ReadOnly,
        Role.Auditor,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ]
    }
  ],
  [
    'expenses',
    {
      routes: ['/expenses'],
      roles: [
        Role.Admin,
        Role.ReadOnly,
        Role.Auditor,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ]
    }
  ],
  [
    'recurringExpenses',
    {
      routes: ['/recurring-expenses'],
      roles: [
        Role.Admin,
        Role.ReadOnly,
        Role.Auditor,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ]
    }
  ],

  [
    'recurringExpensesAddAndEdit',
    {
      routes: ['/recurring-expenses/add-new-recurring-expense', '/recurring-expenses/edit-recurring-expense'],
      roles: [
        Role.Admin,
        Role.ReadOnly,
        Role.ExpenseSubmitter,
        Role.ExpenseApprover,
        Role.BudgetApprover,
        Role.BudgetMember,
        Role.Auditor,
        Role.DonorAuditor
      ]
    }
  ],
  [
    'advances',
    {
      routes: ['/advances'],
      roles: [Role.Admin, Role.ReadOnly, Role.AdvanceSubmitter, Role.AdvanceApprover, Role.AdvanceAdmin]
    }
  ],
  [
    'addAndEditAdvances',
    {
      routes: ['/advances/add-new-advance', '/advances/edit-advance'],
      roles: [Role.Admin, Role.AdvanceSubmitter, Role.AdvanceApprover, Role.AdvanceAdmin]
    }
  ],

  [
    'vendors',
    {
      routes: ['/vendors'],
      roles: [Role.Admin, Role.ReadOnly, Role.VendorSubmitter, Role.VendorApprover, Role.VendorAdmin]
    }
  ],
  [
    'addNewVendor',
    {
      routes: ['/vendors/add-new-vendor'],
      roles: [Role.Admin, Role.VendorSubmitter, Role.VendorApprover, Role.VendorAdmin]
    }
  ],
  [
    'donors',
    {
      routes: ['/donors'],
      roles: [Role.Admin, Role.DonorManager, Role.ReadOnly, Role.DonationManager, Role.DonorAuditor, Role.Auditor]
    }
  ],
  [
    'addAndEditDonor',
    {
      routes: ['/donors/add-new-donor', '/donors/edit-donor'],
      roles: [Role.Admin, Role.DonorManager]
    }
  ],
  [
    'donations',
    {
      routes: ['/donations'],
      roles: [Role.Admin, Role.DonationManager, Role.DonorManager, Role.ReadOnly, Role.Auditor]
    }
  ],
  [
    'addAndEditNewDonation',
    {
      routes: ['/donations/add-new-donation', '/donations/edit-donation'],
      roles: [Role.Admin, Role.DonationManager]
    }
  ],
  [
    'grants',
    {
      routes: ['/grants'],
      roles: [Role.Admin, Role.GrantSubmitter, Role.GrantMember, Role.ReadOnly]
    }
  ],
  [
    'addNewGrant',
    {
      routes: ['/grants/add-new-grant'],
      roles: [Role.Admin, Role.GrantSubmitter]
    }
  ],
  [
    'editGrant',
    {
      routes: ['/grants/edit-grant'],
      roles: [Role.Admin, Role.GrantSubmitter, Role.GrantMember]
    }
  ],

  [
    'pledges',
    {
      routes: ['/pledges'],
      roles: [Role.Admin, Role.DonationManager, Role.DonorManager, Role.ReadOnly, Role.Auditor]
    }
  ],
  [
    'addAndEditPledge',
    {
      routes: ['/pledges/add-new-pledge', '/pledges/edit-pledge'],
      roles: [Role.Admin, Role.DonationManager, Role.DonorManager]
    }
  ],
  [
    'budgets',
    {
      routes: ['/budgets'],
      roles: [Role.Admin, Role.ReadOnly, Role.DonorAuditor, Role.BudgetApprover, Role.BudgetMember, Role.Auditor]
    }
  ],
  [
    'addNewBudget',
    {
      routes: ['/budgets/add-new-budget'],
      roles: [Role.Admin, Role.BudgetApprover, Role.BudgetMember]
    }
  ],
  [
    'editBudget',
    {
      routes: ['/budgets/edit-budget'],
      roles: [Role.Admin, Role.BudgetApprover, Role.BudgetMember, Role.ReadOnly]
    }
  ],
  [
    'utilities',
    {
      routes: [
        '/utilities',
        '/utilities/import_payments',
        '/utilities/import_ledgers',
        '/utilities/statutory_reports',
        '/utilities/download_formats',
        '/utilities/10be_emailer',
        '/utilities/export_tally_income',
        '/utilities/export_tally_expense',
        '/utilities/expense_reconciliation',
        '/utilities/expense_reconciliation_status'
      ],
      roles: [Role.Admin, Role.UtilitiesAccess]
    }
  ],
  [
    'admin',
    {
      routes: ['/admin', '/admin/add_new_user', '/admin/edit_user', '/admin/users_list', '/admin/audit_logs_list'],
      roles: [Role.Admin]
    }
  ],
  [
    'setup',
    {
      routes: [
        '/setup',
        '/setup/cost_centres',
        '/setup/ledgers',
        '/setup/accounting_periods',
        '/setup/purposes',
        '/setup/stock_items',
        '/setup/payee_types'
      ],
      roles: [Role.Admin]
    }
  ]
]);

const tabAccess = new Map([
  [
    'defaultExpenses',
    {
      tabs: ['default'],
      roles: [Role.Admin, Role.Auditor, Role.ReadOnly, Role.DefaultExpenseApprover, Role.DefaultExpenseSubmitter]
    }
  ],
  [
    'payrollExpenses',
    {
      tabs: ['payroll'],
      roles: [Role.Admin, Role.Auditor, Role.ReadOnly, Role.PayrollExpenseApprover, Role.PayrollExpenseSubmitter]
    }
  ],
  [
    'customExp3Expenses',
    {
      tabs: ['custom_exp3'],
      roles: [Role.Admin, Role.Auditor, Role.ReadOnly, Role.CustomExp3ExpenseApprover, Role.CustomExp3ExpenseSubmitter]
    }
  ],
  [
    'allExpenses',
    {
      tabs: ['all_expenses', 'submitted_by_me', 'pending_approval_by_me', 'petty_cash', 'sub_grant', '_sub_grant'],
      roles: [
        Role.Admin,
        Role.ReadOnly,
        Role.Auditor,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ]
    }
  ]
]);

export const canAccessRoute = (route: string): boolean => {
  const { role } = useAuth();

  if (route === '/forbidden/no-subscription') {
    return true;
  }

  // For expenses and recurring-expenses routes, if no tab is specified, check if user has access to any tab
  if (route === '/expenses' || route === '/recurring-expenses') {
    // Check if user has access to any of the tabs
    for (const tabConfig of tabAccess.values()) {
      const hasAccess = Object.values(tabConfig).some(
        roles => Array.isArray(roles) && roles.some(allowedRole => role.value.includes(allowedRole))
      );
      if (hasAccess) return true;
    }
  }

  for (const accessGroup of routeAccess.values()) {
    if (accessGroup.routes.includes(route)) {
      const allowedToAccess = accessGroup.roles.some(requiredRole => role.value.includes(requiredRole));
      return allowedToAccess;
    }
  }
  return false;
};

export const canAccessTab = (tab: string): boolean => {
  const { role } = useAuth();

  for (const accessGroup of tabAccess.values()) {
    if (accessGroup.tabs.includes(tab)) {
      return accessGroup.roles.some(requiredRole => role.value.includes(requiredRole));
    }
  }
  return false;
};
