<script setup lang="ts">
  import { post } from '~/services/donationScreen';
  import AsteriskText from '../Forms/AsteriskText.vue';
  import type { ResponseStatus } from '~/types';

  const props = defineProps<{
    visible: boolean;
    close: () => void;
    advanceId: string;
    redirectTo?: string;
  }>();

  const modalState = reactive<{
    edit_reason: string;
    password: string;
  }>({
    edit_reason: '',
    password: ''
  });

  const loading = ref<ResponseStatus>('idle');
  const router = useRouter();

  const updateModalRef = ref();
  const updateModalRules = {
    edit_reason: [{ required: true, message: 'Reason is required!', trigger: 'submit' }],
    password: [{ required: true, message: 'Password is required!', trigger: 'submit' }]
  };

  const handleModalOk = async () => {
    loading.value = 'pending';
    try {
      const isValid = await updateModalRef.value.validate();
      if (isValid) {
        const response = await post('/v1/reject_advance', {
          data: {
            uid: props.advanceId,
            rejection_note: modalState.edit_reason,
            edit_password: modalState.password
          }
        });
        if (response.status === 200) {
          loading.value = 'success';
          if (props.redirectTo) {
            router.push(props.redirectTo);
          } else {
            props.close();
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
</script>

<template>
  <a-modal
    :visible="props.visible"
    title="Deny Advance"
    class="account-info-modal"
    ok-text="Deny"
    @ok="handleModalOk"
    @cancel="props.close"
  >
    <a-form
      ref="updateModalRef"
      layout="vertical"
      label-align="left"
      class="flex flex-col gap-3"
      :label-col="{ style: { width: '183px' } }"
      :colon="false"
      :rules="updateModalRules"
      :model="modalState"
      :hide-required-mark="true"
    >
      <a-form-item name="edit_reason" class="!sm-medium">
        <template #label>
          <AsteriskText label="Reason for denial" />
        </template>
        <a-textarea v-model:value="modalState.edit_reason" class="w-[547px]" :rows="3" />
      </a-form-item>
      <a-form-item name="password" class="!sm-medium">
        <template #label>
          <AsteriskText label="Edit Password" />
        </template>
        <a-input v-model:value="modalState.password" type="password" class="w-[248px]" />
      </a-form-item>
    </a-form>
    <template #footer>
      <div class="flex gap-1 w-full justify-end border-t border-gray-300 p-3 bg-gray-50 rounded-b-lg">
        <a-button
          :disabled="loading === 'pending'"
          class="rounded text-sm font-medium border border-gray-300 text-gray-500 px-3 py-1.5"
          @click="props.close"
          >Cancel</a-button
        >
        <a-button
          :disabled="loading === 'pending'"
          class="px-3 border !border-red-700 bg-red-700 !text-white disabled:hover:!bg-red-50 hover:!bg-red-600 disabled:!text-red-300 disabled:!border-red-300 disabled:bg-red-50 rounded py-1.5 flex items-center justify-center text-sm font-medium"
          type="primary"
          @click="handleModalOk"
          >Deny Advance</a-button
        >
      </div>
    </template>
  </a-modal>
</template>

<style scoped>
  @import '~/assets/css/modal.css';

  .ant-form-item {
    margin-bottom: 0px !important;
  }
</style>
