export const formatter = (value: number | string): string => {
  if (!value && value !== 0) return '';

  // Convert to string and remove any existing commas
  const numStr = value.toString().replace(/,/g, '');

  // Convert to number
  const num = parseFloat(numStr);
  if (isNaN(num)) return '';

  // Indian currency formatting
  const result = num.toString().split('.');
  let lastThree = result[0].substring(result[0].length - 3);
  const otherNumbers = result[0].substring(0, result[0].length - 3);
  if (otherNumbers !== '') {
    lastThree = ',' + lastThree;
  }
  const formatted = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;

  return result[1] ? formatted + '.' + result[1] : formatted;
};

export const parser = (value: string): string => {
  return value.replace(/,/g, '');
};

export const allowOnlyNumbersAndDecimalPoint = (e: any) => {
  const charCode = e.which ? e.which : e.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    e.preventDefault();
  }
};
