import { isEmpty } from 'lodash';
import { toDateTime } from '~/functions/dateTimeUtilities';
import type {
  AddNewGrantFormState,
  DisbursementDate,
  DisbursementFromAPI,
  GrantMemberFromAPI,
  LineItemState,
  LineItemFromAPI,
  Member,
  ReportDate,
  ReportFromAPI,
  SelectedDonorFromAPI,
  SelectedGrant,
  TableStatus
} from '~/types/grants';

export const addNewGrantBreadCrumb: { route: string; title: string }[] = [
  { route: '/grants', title: 'Grants' },
  { route: '/grants/add-new-grant', title: 'New Grant' }
];

export enum GrantAttachmentCategories {
  GrantDocuments = 'Grant Documents',
  'Disbursement / Fund Request' = 'Disbursement / Fund Request',
  Reports = 'Reports',
  Formats = 'Formats',
  Proposals = 'Proposals',
  Others = 'Others'
}

export const formDefaultState: AddNewGrantFormState = {
  donorName: '',
  donorUid: null,
  grantId: '',
  signingDate: null,
  startDate: null,
  endDate: null,
  description: '',
  purpose: '',
  grantAmount: null,
  members: [],
  lineItems: [
    {
      key: Date.now(),
      name: '',
      amount: null,
      note: '',
      subItems: []
    }
  ],
  disbursementReminderEmailList: [],
  disbursementRemindBeforeDays: null,
  reportSendReminderTo: [],
  reportRemindBeforeDays: null,
  attachments: [],
  reportDatesTable: [{ key: Date.now(), dueDate: null, reportName: '', status: 'Pending' }],
  disbursementDatesTable: [{ key: Date.now(), dueDate: null, amount: null, status: 'Pending' }]
};

export const newGrantRules: {
  [key: string]: { required: boolean; message: string; trigger: string }[];
} = {
  donorName: [
    {
      required: true,
      message: 'Donor name is required!',
      trigger: 'submit'
    }
  ],
  grantId: [
    {
      required: true,
      message: 'Grant ID is required!',
      trigger: 'submit'
    }
  ],
  signingDate: [
    {
      required: true,
      message: 'Signing date is required!',
      trigger: 'submit'
    }
  ],
  startDate: [
    {
      required: true,
      message: 'Start date is required!',
      trigger: 'submit'
    }
  ],
  endDate: [
    {
      required: true,
      message: 'End date is required!',
      trigger: 'submit'
    }
  ],
  purpose: [
    {
      required: true,
      message: 'Purpose is required!',
      trigger: 'submit'
    }
  ],
  grantAmount: [
    {
      required: true,
      message: 'Grant amount is required!',
      trigger: 'submit'
    }
  ],
  members: [
    {
      required: false,
      message: 'Grant members are required!',
      trigger: 'submit'
    }
  ]
};

const grandMemberData = (members: Member[]) => {
  return members.reduce((acc, member) => {
    return acc + `${member.firstName}_${member.lastName}_${member.email}, `;
  }, '');
};

const lineItemData = (lineItems: LineItemState[]) => {
  const formattedData: Record<string, any> = {};
  let itemNumber = 1;
  lineItems.forEach(item => {
    if (isEmpty(item.subItems)) {
      formattedData[itemNumber] = {
        [`li_text_${itemNumber}`]: item.name,
        [`li_subtext_${itemNumber}`]: '',
        [`li_amount_${itemNumber}`]: item.amount,
        [`li_note_${itemNumber}`]: item.note
      };
      itemNumber++;
    } else {
      item.subItems.forEach(subItem => {
        formattedData[itemNumber] = {
          [`li_text_${itemNumber}`]: item.name,
          [`li_subtext_${itemNumber}`]: subItem.name,
          [`li_amount_${itemNumber}`]: subItem.amount,
          [`li_note_${itemNumber}`]: item.note
        };
        itemNumber++;
      });
    }
  });
  return JSON.stringify(formattedData).toString();
};

export const createEmailList = (members: Member[]) => {
  return members.reduce((acc, member, index) => {
    if (index === members.length - 1) {
      return acc + member.email.trim();
    }
    return acc + `${member.email.trim()}, `;
  }, '');
};

const disbursementDateData = (disbursementDates: DisbursementDate[]) => {
  const formattedData: Record<string, any> = {};
  disbursementDates.forEach((disbursement, index) => {
    const itemNumber = index + 1;
    formattedData[itemNumber] = {
      [`dd_date_${itemNumber}`]: disbursement.dueDate?.toISOString(),
      [`dd_amount_${itemNumber}`]: disbursement.amount?.toString(),
      [`dd_status_${itemNumber}`]: disbursement.status
    };
  });
  return JSON.stringify(formattedData).toString();
};

const reportDateData = (reportDates: ReportDate[]) => {
  const formattedData: Record<string, any> = {};
  reportDates.forEach((date, index) => {
    const itemNumber = index + 1;
    formattedData[itemNumber] = {
      [`rd_date_${itemNumber}`]: date.dueDate?.toISOString(),
      [`rd_text_${itemNumber}`]: date.reportName,
      [`rd_status_${itemNumber}`]: date.status
    };
  });
  return JSON.stringify(formattedData).toString();
};

export const createFormData = (formState: AddNewGrantFormState) => {
  const formData: any = {
    donor_id: formState.donorUid?.toString() || '',
    grant_id: formState.grantId,
    start_date: formState.startDate?.toISOString(),
    end_date: formState.endDate?.toISOString(),
    signing_date: formState.signingDate?.toISOString(),
    description: formState.description,
    purpose: formState.purpose,
    amount: formState.grantAmount?.toString(),
    grant_members: grandMemberData(formState.members),
    line_items: lineItemData(formState.lineItems),
    disbursement_emails: createEmailList(formState.disbursementReminderEmailList),
    disbursement_days_before: formState.disbursementRemindBeforeDays,
    disbursements_json: disbursementDateData(formState.disbursementDatesTable),
    reporting_emails: createEmailList(formState.reportSendReminderTo),
    reporting_days_before: formState.reportRemindBeforeDays,
    reports_json: reportDateData(formState.reportDatesTable)
  };
  formState.attachments.forEach((file, index) => {
    if (file.rawFile) {
      formData[`at_file_${index + 1}`] = file.rawFile;
      formData[`at_cat_${index + 1}`] = file.category;
    }
  });
  return formData;
};

export const lineItemFormData = (lineItem: LineItemFromAPI[]) => {
  const lineItemArray: any[] = [];
  lineItem.forEach((item, index: number) => {
    const exitingLineItem = lineItemArray.find(lineItem => lineItem.name === item.li_text);
    if (exitingLineItem) {
      exitingLineItem.subItems.push({
        key: Date.now() + index,
        name: item.li_subtext,
        amount: +item.li_amount,
        note: item.li_note || ''
      });
    } else {
      if (item.li_subtext) {
        lineItemArray.push({
          key: Date.now() + index,
          name: item.li_text,
          amount: +item.li_amount,
          note: item.li_note || '',
          subItems: [{ key: Date.now(), name: item.li_subtext, amount: item.li_amount, note: item.li_note || '' }]
        });
      } else {
        lineItemArray.push({
          key: Date.now() + index,
          name: item.li_text,
          amount: +item.li_amount,
          note: item.li_note || '',
          subItems: []
        });
      }
    }
  });
  return lineItemArray;
};

export const createFormStateObject = (
  selectedGrant: SelectedGrant,
  selectedDonor: SelectedDonorFromAPI,
  reminderUserList: GrantMemberFromAPI[]
) => {
  const formState: AddNewGrantFormState = {
    donorUid: selectedGrant.donor_id,
    donorName: selectedDonor.name,
    grantId: selectedGrant.grant_id,
    startDate: toDateTime(selectedGrant.start_date),
    endDate: toDateTime(selectedGrant.end_date),
    signingDate: toDateTime(selectedGrant.signing_date),
    description: selectedGrant.description,
    purpose: selectedGrant.purpose,
    grantAmount: +selectedGrant.amount,
    members: selectedGrant.grant_members
      .split(', ')
      .filter(Boolean)
      .map((member: string) => {
        const [firstName, lastName, email] = member.trim().split('_', 3);
        return {
          firstName: firstName,
          lastName: lastName,
          email: email
        };
      }),
    lineItems: lineItemFormData(selectedGrant.line_items),
    disbursementDatesTable: selectedGrant.disbursements.map((data: DisbursementFromAPI, index: number) => ({
      key: Date.now() + index,
      dueDate: toDateTime(data.dd_date) || null,
      amount: Number(data.dd_amount) || null,
      status: data.dd_status as TableStatus
    })),
    reportDatesTable: selectedGrant.reports.map((report: ReportFromAPI, index: number) => ({
      key: Date.now() + index,
      dueDate: toDateTime(report.rd_date) || null,
      reportName: report.rd_text,
      status: report.rd_status as TableStatus
    })),
    disbursementReminderEmailList: selectedGrant.disbursement_emails
      .split(', ')
      .filter(Boolean)
      .map((email: string) => {
        const user = reminderUserList.find((user: GrantMemberFromAPI) => user.email === email);
        if (user) {
          return {
            firstName: user?.first_name,
            lastName: user?.last_name,
            email: user?.email
          };
        } else {
          return {
            firstName: null,
            lastName: null,
            email: email
          };
        }
      }),
    disbursementRemindBeforeDays: +selectedGrant.disbursement_days_before,
    reportSendReminderTo: selectedGrant.reporting_emails
      .split(', ')
      .filter(Boolean)
      .map((email: string) => {
        const user = reminderUserList.find((user: GrantMemberFromAPI) => user.email === email);
        if (user) {
          return {
            firstName: user?.first_name,
            lastName: user?.last_name,
            email: user?.email
          };
        } else {
          return {
            firstName: null,
            lastName: null,
            email: email
          };
        }
      }),
    reportRemindBeforeDays: selectedGrant.reporting_days_before,
    attachments:
      selectedGrant.attachments_data?.map((attachment: any, index: number) => ({
        key: Date.now() + index,
        filename: attachment.filename,
        category: attachment.category
      })) || []
  };
  return formState;
};

interface ValidationResult {
  tableError: string;
  isValid: boolean;
}

export const validateLineItems = (lineItems: LineItemState[]): ValidationResult => {
  for (const item of lineItems) {
    if (isEmpty(item.subItems)) {
      if (!item.name || !item.amount) {
        return {
          tableError: 'All fields in the table are required!',
          isValid: false
        };
      }
    } else {
      for (const subItem of item.subItems) {
        if (!subItem.name || !subItem.amount || !item.name) {
          return {
            tableError: 'All fields in the table are required!',
            isValid: false
          };
        }
      }
    }
  }
  return {
    tableError: '',
    isValid: true
  };
};

export const validateDisbursements = (disbursementDates: DisbursementDate[]): ValidationResult => {
  for (const item of disbursementDates) {
    if (!item.dueDate || !item.amount) {
      return {
        tableError: 'All fields in the table are required!',
        isValid: false
      };
    }
  }
  return {
    tableError: '',
    isValid: true
  };
};

export const validateReports = (reportDates: ReportDate[]): ValidationResult => {
  for (const item of reportDates) {
    if (!item.dueDate || !item.reportName) {
      return {
        tableError: 'All fields in the table are required!',
        isValid: false
      };
    }
  }
  return {
    tableError: '',
    isValid: true
  };
};
