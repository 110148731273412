import { cloneDeep, isEmpty } from 'lodash';
import type {
  AddNewBudgetFormState,
  BudgetCreator,
  BudgetLineItem,
  BudgetLineItemState,
  SelectedBudgetFromApi
} from '~/types/budget';

export const addNewBudgetBreadCrumb: { route: string; title: string }[] = [
  { route: '/budgets', title: 'Budgets' },
  {
    route: '/budgets/add-new-budget',
    title: 'New Budget'
  }
];

export enum BudgetAttachmentCategories {
  BUDGET_WORKINGS = 'Budget Workings',
  FORMATS = 'Formats',
  OTHERS = 'Others'
}

export const monthInitialAmounts = reactive<Record<string, number | null>>({
  january: null,
  february: null,
  march: null,
  april: null,
  may: null,
  june: null,
  july: null,
  august: null,
  september: null,
  october: null,
  november: null,
  december: null
});

export const addNewBudgetFormDefaultState = reactive<AddNewBudgetFormState>({
  budgetName: '',
  budgetId: '',
  budgetFinancialYear: '',
  description: '',
  budgetMembers: [],
  firstApproval: '',
  secondApproval: '',
  thirdApproval: '',
  fourthApproval: '',
  fifthApproval: '',
  sixthApproval: '',
  seventhApproval: '',
  eighthApproval: '',
  ninthApproval: '',
  tenthApproval: '',
  lineItems: [
    {
      key: Date.now(),
      name: '',
      amount: null,
      note: '',
      breakdownType: 'monthly',
      breakdowns: cloneDeep(monthInitialAmounts),
      subItems: []
    }
  ],
  attachments: [],
  totalBudgetAmount: 0,
  approvalCount: 0
});

export const newBudgetRules = {
  budgetName: [{ required: true, message: 'Budget Name is required', trigger: 'submit' }],
  budgetId: [{ required: true, message: 'Budget ID is required', trigger: 'submit' }],
  budgetFinancialYear: [{ required: true, message: 'Budget Financial Year is required', trigger: 'submit' }],
  budgetMembers: [{ required: true, message: 'Budget Members are required', trigger: 'submit' }],
  firstApproval: [{ required: true, message: 'First Approval is required', trigger: 'submit' }],
  secondApproval: [{ required: true, message: 'Second Approval is required', trigger: 'submit' }],
  thirdApproval: [{ required: true, message: 'Third Approval is required', trigger: 'submit' }],
  fourthApproval: [{ required: true, message: 'Fourth Approval is required', trigger: 'submit' }],
  fifthApproval: [{ required: true, message: 'Fifth Approval is required', trigger: 'submit' }],
  sixthApproval: [{ required: true, message: 'Sixth Approval is required', trigger: 'submit' }],
  seventhApproval: [{ required: true, message: 'Seventh Approval is required', trigger: 'submit' }],
  eighthApproval: [{ required: true, message: 'Eighth Approval is required', trigger: 'submit' }],
  ninthApproval: [{ required: true, message: 'Ninth Approval is required', trigger: 'submit' }],
  tenthApproval: [{ required: true, message: 'Tenth Approval is required', trigger: 'submit' }]
};

export const months = [
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
  'january',
  'february',
  'march'
];

export const approvalKeys = {
  1: '1st Approval',
  2: '2nd Approval',
  3: '3rd Approval',
  4: '4th Approval',
  5: '5th Approval',
  6: '6th Approval',
  7: '7th Approval',
  8: '8th Approval',
  9: '9th Approval',
  10: '10th Approval'
};

export const approvalStateKeys: Record<number, string> = {
  1: 'firstApproval',
  2: 'secondApproval',
  3: 'thirdApproval',
  4: 'fourthApproval',
  5: 'fifthApproval',
  6: 'sixthApproval',
  7: 'seventhApproval',
  8: 'eighthApproval',
  9: 'ninthApproval',
  10: 'tenthApproval'
};

export const validateLineItems = (lineItems: BudgetLineItemState[]) => {
  for (const item of lineItems) {
    if (isEmpty(item.subItems)) {
      if (!item.name || !item.amount) {
        return {
          tableError: 'All fields in the table are required!',
          isValid: false
        };
      }
    } else {
      for (const subItem of item.subItems) {
        if (!subItem.name || !subItem.amount || !item.name) {
          return {
            tableError: 'All fields in the table are required!',
            isValid: false
          };
        }
      }
    }
  }
  return {
    tableError: '',
    isValid: true
  };
};

const monthNumbers: Record<string, number> = {
  january: 1,
  february: 2,
  march: 3,
  april: 4,
  may: 5,
  june: 6,
  july: 7,
  august: 8,
  september: 9,
  october: 10,
  november: 11,
  december: 12
};

const numberMonth: Record<number, string> = {
  1: 'january',
  2: 'february',
  3: 'march',
  4: 'april',
  5: 'may',
  6: 'june',
  7: 'july',
  8: 'august',
  9: 'september',
  10: 'october',
  11: 'november',
  12: 'december'
};

const lineItemData = (lineItems: BudgetLineItemState[]) => {
  const formattedData: Record<string, any> = {};
  let itemNumber = 1;
  lineItems.forEach(item => {
    if (isEmpty(item.subItems)) {
      formattedData[itemNumber] = {
        [`li_text_${itemNumber}`]: item.name,
        [`li_subtext_${itemNumber}`]: '',
        [`li_amount_${itemNumber}`]: item.amount,
        [`li_note_${itemNumber}`]: item.note,
        [`li_calendar_${itemNumber}`]: {
          [`li_isCustom_${itemNumber}`]: item.breakdownType === 'custom' ? 'true' : 'false',
          [`li_breakdown_${itemNumber}`]: Object.keys(item.breakdowns).reduce((acc: Record<number, string>, key) => {
            acc[monthNumbers[key]] = item.breakdowns[key] !== null ? item.breakdowns[key].toString() : '0';
            return acc;
          }, {})
        }
      };
      itemNumber++;
    } else {
      item.subItems.forEach(subItem => {
        formattedData[itemNumber] = {
          [`li_text_${itemNumber}`]: item.name,
          [`li_subtext_${itemNumber}`]: subItem.name,
          [`li_amount_${itemNumber}`]: subItem.amount,
          [`li_note_${itemNumber}`]: subItem.note,
          [`li_calendar_${itemNumber}`]: {
            [`li_isCustom_${itemNumber}`]: subItem.breakdownType === 'custom' ? 'true' : 'false',
            [`li_breakdown_${itemNumber}`]: Object.keys(subItem.breakdowns).reduce(
              (acc: Record<number, string>, key) => {
                acc[monthNumbers[key]] = subItem.breakdowns[key] !== null ? subItem.breakdowns[key].toString() : '0';
                return acc;
              },
              {}
            )
          }
        };
        itemNumber++;
      });
    }
  });
  return JSON.stringify(formattedData).toString();
};

const approvalListData = (approversList: string[]) => {
  const formattedData: Record<string, any> = {};
  approversList.forEach((approver, index) => {
    const itemNumber = index + 1;
    if (isEmpty(approver)) return;
    formattedData[itemNumber] = {
      [`budget_approver_${itemNumber}`]: approver,
      [`approver_status_${itemNumber}`]: 'Pending',
      [`approver_notes_${itemNumber}`]: ''
    };
  });
  return JSON.stringify(formattedData).toString();
};

export const createBudgetFormData = (formState: AddNewBudgetFormState, budgetCreator: BudgetCreator) => {
  const approversList = approvalListData([
    formState.firstApproval,
    formState.secondApproval,
    formState.thirdApproval,
    formState.fourthApproval,
    formState.fifthApproval,
    formState.sixthApproval,
    formState.seventhApproval,
    formState.eighthApproval,
    formState.ninthApproval,
    formState.tenthApproval
  ]);
  const formData: any = {
    budget_name: formState.budgetName,
    budget_id: formState.budgetId,
    financial_year: formState.budgetFinancialYear,
    description: formState.description,
    budget_creator: `${budgetCreator.first_name} ${budgetCreator.last_name} ${budgetCreator.email}`,
    line_items: lineItemData(formState.lineItems),
    budget_members: formState.budgetMembers.reduce((acc, member) => {
      return acc + `${member.firstName}_${member.lastName}_${member.email}, `;
    }, ''),
    approvers_list: approversList
  };
  formState.attachments.forEach((file, index) => {
    if (file.rawFile) {
      formData[`at_file_${index + 1}`] = file.rawFile;
      formData[`at_cat_${index + 1}`] = file.category;
    }
  });
  return formData;
};

const breakdownType = (item: any) => {
  return item.li_calendar.li_isCustom === 'true' ? 'custom' : 'monthly';
};

export const lineItemFormData = (lineItem: BudgetLineItem[]) => {
  const lineItemArray: any[] = [];
  lineItem.forEach((item, index: number) => {
    const exitingLineItem = lineItemArray.find(lineItem => lineItem.name === item.li_text);
    if (exitingLineItem) {
      exitingLineItem.subItems.push({
        key: Date.now() + index,
        name: item.li_subtext,
        amount: +item.li_amount,
        note: item.li_note || '',
        breakdownType: breakdownType(item),
        breakdowns: Object.keys(item.li_calendar.li_breakdown).reduce((acc: Record<string, number>, key) => {
          acc[numberMonth[+key]] = item.li_calendar.li_breakdown[key];
          return acc;
        }, {})
      });
    } else {
      if (item.li_subtext) {
        lineItemArray.push({
          key: Date.now() + index,
          name: item.li_text,
          amount: +item.li_amount,
          note: item.li_note || '',
          breakdownType: breakdownType(item),
          breakdowns: Object.keys(item.li_calendar.li_breakdown).reduce((acc: Record<string, number>, key) => {
            acc[numberMonth[+key]] = item.li_calendar.li_breakdown[key];
            return acc;
          }, {}),
          subItems: [
            {
              key: Date.now(),
              name: item.li_subtext,
              amount: item.li_amount,
              note: item.li_note || '',
              breakdownType: 'custom',
              breakdowns: Object.keys(item.li_calendar.li_breakdown).reduce((acc: Record<string, number>, key) => {
                acc[numberMonth[+key]] = item.li_calendar.li_breakdown[key];
                return acc;
              }, {})
            }
          ]
        });
      } else {
        lineItemArray.push({
          key: Date.now() + index,
          name: item.li_text,
          amount: +item.li_amount,
          note: item.li_note || '',
          breakdownType: breakdownType(item),
          breakdowns: Object.keys(item.li_calendar.li_breakdown).reduce((acc: Record<string, number>, key) => {
            acc[numberMonth[+key]] = item.li_calendar.li_breakdown[key];
            return acc;
          }, {}),
          subItems: []
        });
      }
    }
  });
  return lineItemArray;
};

export const createBudgetFormState = (selectedBudget: SelectedBudgetFromApi) => {
  const formState: any = {
    budgetName: selectedBudget.name,
    budgetId: selectedBudget.budget_id,
    budgetFinancialYear: selectedBudget.financial_year,
    description: selectedBudget.description,
    budgetMembers: selectedBudget.budget_members
      .split(', ')
      .filter(Boolean)
      .map((member: string) => {
        const [firstName, lastName, email] = member.trim().split('_', 3);
        return {
          firstName: firstName,
          lastName: lastName,
          email: email
        };
      }),
    totalBudgetAmount: selectedBudget.amount,
    lineItems: lineItemFormData(selectedBudget.line_items),
    attachments:
      selectedBudget.attachments_data?.map((attachment: any, index: number) => ({
        key: Date.now() + index,
        filename: attachment.filename,
        category: attachment.category
      })) || []
  };
  const approvalValues = Object.values(selectedBudget.approvers_list).map(
    (approver: any, index: number) => approver[`budget_approver_${index + 1}`]
  );
  let approvalCount = 0;
  Object.values(approvalStateKeys).forEach((key: string, index) => {
    if (approvalValues[index]) approvalCount++;
    formState[key as keyof AddNewBudgetFormState] = approvalValues[index] || '';
  });
  formState.approvalCount = approvalCount;
  return formState;
};

export const createBudgetLineItemsFromTemplate = (lineItems: BudgetLineItem[]) => {
  return lineItemFormData(lineItems);
};
