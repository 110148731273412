<script lang="ts" setup>
  import AsteriskText from '~/components/shared/Forms/AsteriskText.vue';

  interface Option {
    title: string;
    value: string;
  }

  const props = defineProps<{
    isCompulsory?: boolean;
    labelText: string;
    value: string;
    options: Option[];
    name: string;
    rules?: any;
    isDisabled?: boolean;
    placeholder?: string;
    stateKey?: string;
    itemKey?: number;
  }>();
  const emit = defineEmits(['update:value']);

  const localValue = ref<string>(props.value);

  const filterOption = (input: string, option: Option) => {
    const searchTerms = input.toLowerCase().split(' ');
    const optionValue = option.value.toLowerCase();
    return searchTerms.every(term => optionValue.includes(term));
  };

  const onSelect = (selectedValue: string) => {
    localValue.value = selectedValue;
    if (props.itemKey) {
      emit('update:value', selectedValue, props.itemKey, props.stateKey);
    } else {
      emit('update:value', selectedValue, props.stateKey);
    }
  };

  // watch for changes in the prop
  watch([() => props.value], ([value]) => {
    localValue.value = value;
  });
</script>

<template>
  <a-form-item class="!font-medium mb-3 custom-dropdown-with-search" :name="props.name" :rules="props.rules">
    <template v-if="props.isCompulsory" #label>
      <AsteriskText :label="props.labelText" />
    </template>
    <template v-else #label>
      <p class="text-zinc-600">{{ props.labelText }}</p>
    </template>
    <a-select
      :value="localValue"
      style="width: 248px"
      :placeholder="props.placeholder"
      :filter-option="filterOption"
      show-search
      :allow-clear="false"
      name="donorName"
      :disabled="props.isDisabled"
      @select="onSelect"
    >
      <a-select-option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.title }}
      </a-select-option>
    </a-select>
  </a-form-item>
</template>

<style>
  .custom-dropdown-with-search .ant-form-item-label > label {
    color: #52525b !important;
    line-height: 21px;
  }
  .custom-dropdown-with-search .ant-select-selector {
    padding: 8px !important;
    height: 39px !important;
  }

  .custom-dropdown-with-search .ant-select-selection-item {
    line-height: 21px !important;
    font-weight: 500 !important;
    color: #27272a;
  }

  .custom-dropdown-with-search .ant-select-selection-placeholder {
    line-height: 21px !important;
    font-weight: 500 !important;
  }
</style>
