<script setup lang="tsx">
  import { PencilIcon, PlusIcon } from '@heroicons/vue/solid';
  import { get } from '~/services/donationScreen';
  import type { VendorDrawerType, GetResponse, ResponseStatus } from '~/types';
  import Attachments from '../../Others/Attachments.vue';
  import AttachmentUpdates from '../RoutedDrawerComponents/AttachmentUpdates.vue';
  import InfoRow from '~/components/UI/Drawers/InfoRow.vue';
  import InfoSection from '~/components/UI/Drawers/InfoSection.vue';
  import DrawerLayout from '~/components/UI/Drawers/DrawerLayout.vue';
  import OverviewCard from '~/components/UI/Others/OverviewCard.vue';
  import { checkValueAndLength } from '~/functions/stringUtilities';
  import AuditLogs from '../RoutedDrawerComponents/AuditLogs.vue';
  import RoleBasedLinkButton from '~/components/UI/Others/RoleBasedLinkButton.vue';
  import RoleBasedButton from '~/components/UI/Others/RoleBasedButton.vue';
  import DeactivateVendorModal from '../../Modals/DeactivateVendorModal.vue';

  const { onClose, identifier } = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const deactivateVendorModal = ref(false);

  const data = ref<VendorDrawerType>({} as VendorDrawerType);
  const responseState = ref<ResponseStatus>('idle');

  const fetchData = async () => {
    try {
      const response = await get<GetResponse<VendorDrawerType>>(`v1/get_vendor/${identifier}`);
      if (response.data.status === 'success') {
        data.value = response.data.data;
        responseState.value = 'success';
      } else {
        responseState.value = 'error';
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDeactivateVendorModalOpen = () => {
    deactivateVendorModal.value = true;
  };

  const handleDeactivateVendorModalClose = () => {
    deactivateVendorModal.value = false;
  };

  onMounted(fetchData);
</script>

<template>
  <a-drawer
    v-if="identifier"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    title="Vendor Information"
    :open="identifier !== null"
    width="1050"
    @close="onClose"
  >
    <template #extra>
      <div class="flex gap-2">
        <RoleBasedButton
          component-name="deactivateVendorButton"
          active-class="text-blue-700 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center text-gray-500 border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          disabled-class="flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          @click="handleDeactivateVendorModalOpen"
        >
          Deactivate Vendor
        </RoleBasedButton>
        <RoleBasedLinkButton
          :to="{ path: '/vendors/edit-vendor', query: { identifier } }"
          component-name="headerPrimaryButton"
          active-class="text-blue-700 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center text-gray-500 border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          disabled-class="flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
        >
          <PencilIcon class="h-4 w-4" />
          Edit Vendor
        </RoleBasedLinkButton>
      </div>
    </template>
    <DrawerLayout
      :response-state="responseState"
      :main-display="data?.selected_vendor?.name"
      :sub-display="data?.selected_vendor?.vendor_type"
      :side-display="''"
      @close="onClose"
    >
      <template #column1>
        <InfoSection>
          <InfoRow label="Email" :value="checkValueAndLength(data?.selected_vendor?.email)" />
          <InfoRow label="Phone Number" :value="checkValueAndLength(data?.selected_vendor?.phone)" />
          <InfoRow label="Vendor ID" :value="checkValueAndLength(data?.selected_vendor?.vendor_id)" />
          <InfoRow label="Vendor Type" :value="checkValueAndLength(data?.selected_vendor?.vendor_type)" />
          <InfoRow label="Expiry Date" :value="checkValueAndLength(data?.selected_vendor?.expiry_date)" />
        </InfoSection>
        <InfoSection title="SPOC Vendor">
          <InfoRow label="Name" :value="checkValueAndLength(data?.selected_vendor?.spoc_vendor_name)" />
          <InfoRow label="Title" :value="checkValueAndLength(data?.selected_vendor?.spoc_vendor_title)" />
          <InfoRow label="Email" :value="checkValueAndLength(data?.selected_vendor?.spoc_vendor_email)" />
          <InfoRow label="Phone Number" :value="checkValueAndLength(data?.selected_vendor?.spoc_vendor_phone)" />
        </InfoSection>
        <InfoSection title="SPOC Internal">
          <InfoRow label="Name" :value="checkValueAndLength(data?.selected_vendor?.spoc_internal_name)" />
          <InfoRow label="Title" :value="checkValueAndLength(data?.selected_vendor?.spoc_internal_title)" />
          <InfoRow label="Email" :value="checkValueAndLength(data?.selected_vendor?.spoc_internal_email)" />
          <InfoRow label="Phone Number" :value="checkValueAndLength(data?.selected_vendor?.spoc_internal_phone)" />
        </InfoSection>
        <InfoSection title="Bank and Financials">
          <InfoRow label="PAN" :value="checkValueAndLength(data?.selected_vendor?.pan)" />
          <InfoRow label="% of TDS" :value="checkValueAndLength(data?.selected_vendor?.tds_percentage)" />
          <InfoRow label="Reference" :value="checkValueAndLength(data?.selected_vendor?.reference)" />
          <InfoRow label="GST Number" :value="checkValueAndLength(data?.selected_vendor?.gst)" />
          <InfoRow label="MSME Vendor" :value="checkValueAndLength(data?.selected_vendor?.msme_vendor)" />
          <InfoRow label="Udhyan Number" :value="checkValueAndLength(data?.selected_vendor?.udhyan_reg_no)" />
          <InfoRow label="Default Party Ledger (Non-FCRA)" :value="checkValueAndLength('None')" />
          <InfoRow label="Default Party Ledger (FCRA)" :value="checkValueAndLength('None')" />
        </InfoSection>
        <InfoSection title="Additional Information">
          <InfoRow label="Vendor Description" :value="checkValueAndLength(data?.selected_vendor?.description)" />
          <InfoRow label="Vendor Valid Upto" :value="checkValueAndLength(data?.selected_vendor?.expiry_date)" />
          <InfoRow
            label="Validity Reminder Emails List"
            :value="checkValueAndLength(data?.selected_vendor?.vendor_validity_emails)"
          />
          <InfoRow
            label="Reminder Days Before"
            :value="checkValueAndLength(data?.selected_vendor?.contract_days_before.toString())"
          />
          <InfoRow label="KYC Completed" :value="checkValueAndLength(data?.selected_vendor?.kyc_completed)" />
        </InfoSection>
      </template>
      <template #column2>
        <Attachments :items="data?.selected_vendor?.attachments" :table="data?.selected_vendor?.table" />
        <OverviewCard title="Vendor Quick Links">
          <div class="flex flex-col gap-4">
            <div class="flex justify-between items-center">
              <div class="flex gap-2 items-center">
                <p
                  class="flex h-5 w-5 shrink-0 grow-0 items-center justify-center rounded-full bg-zinc-200 text-zinc-700 xs-medium"
                >
                  {{ data?.selected_vendor?.tds_percentage }}
                </p>
                <p class="sm-medium text-zinc-500">Expenses</p>
              </div>
              <div class="flex gap-3 items-center">
                <nuxt-link
                  :to="{ path: '/expenses', query: { vendor_uid: identifier } }"
                  class="p-0 border-none shadow-none !bg-transparent text-blue-700"
                >
                  View
                </nuxt-link>
                <nuxt-link
                  :to="{ path: '/expenses/add-new-expense', query: { vendorId: identifier } }"
                  class="p-0 border-none shadow-none !bg-transparent text-blue-700"
                >
                  <PlusIcon class="w-5 h-5" />
                </nuxt-link>
              </div>
            </div>
          </div>
        </OverviewCard>
        <AuditLogs :items="data?.audit_logs" title="Vendor" />
        <AttachmentUpdates :items="data?.attachment_logs" />
      </template>
    </DrawerLayout>
  </a-drawer>
  <DeactivateVendorModal
    :visible="deactivateVendorModal"
    :vendor-id="identifier || ''"
    :close="handleDeactivateVendorModalClose"
  />
</template>

<style>
  @import '~/assets/css/donationDrawer.css';
</style>
