import { isEmpty } from 'lodash';
import { approvalStateKeys } from '~/components/Budget/Forms/addNewBudgetFormUtils';
import { toDateTime } from '~/functions/dateTimeUtilities';
import type { AddNewExpenseFormState, GrantsAndBudgetAllocation } from '~/types/expenses';
import type { AddNewRecurringExpenseFormState } from '~/types/recurringExpenses';
import dayjs, { type Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const addNewRecurringExpenseBreadCrumb: { route: string; title: string }[] = [
  { route: '/recurring-expenses', title: 'Recurring Expenses' },
  { route: '/recurring-expenses/add-new-recurring-expense', title: 'New Recurring Expense' }
];

export enum BookOfAccountOptions {
  FCRA = 'FCRA',
  NONFCRA = 'Non-FCRA'
}

export const recurringTypes = [
  { title: 'Monthly', value: 'monthly' },
  { title: 'Quarterly', value: 'quarterly' }
];

export const recurringDayOfMonthOptions = [
  { title: 'Last Day', value: 'last_day' },
  { title: 'Second Last Day', value: 'second_last_day' },
  { title: 'Third Last Day', value: 'third_last_day' },
  { title: '1', value: '1' },
  { title: '2', value: '2' },
  { title: '3', value: '3' },
  { title: '4', value: '4' },
  { title: '5', value: '5' },
  { title: '6', value: '6' },
  { title: '7', value: '7' },
  { title: '8', value: '8' },
  { title: '9', value: '9' },
  { title: '10', value: '10' },
  { title: '11', value: '11' },
  { title: '12', value: '12' },
  { title: '13', value: '13' },
  { title: '14', value: '14' },
  { title: '15', value: '15' },
  { title: '16', value: '16' },
  { title: '17', value: '17' },
  { title: '18', value: '18' },
  { title: '19', value: '19' },
  { title: '20', value: '20' },
  { title: '21', value: '21' },
  { title: '22', value: '22' },
  { title: '23', value: '23' },
  { title: '24', value: '24' },
  { title: '25', value: '25' },
  { title: '26', value: '26' },
  { title: '27', value: '27' },
  { title: '28', value: '28' }
];

export const recurringExpenseFormDefaultState: AddNewRecurringExpenseFormState = {
  expenseType: '',
  invoiceDate: null,
  partyName: '',
  vendorBank: '',
  invoiceNumber: '',
  description: '',
  totalAmount: null,
  firstApproval: '',
  secondApproval: '',
  thirdApproval: '',
  fourthApproval: '',
  fifthApproval: '',
  sixthApproval: '',
  seventhApproval: '',
  eighthApproval: '',
  ninthApproval: '',
  tenthApproval: '',
  recurringConfigStartDate: null,
  recurringConfigEndDate: null,
  recurringConfigRecurringType: '',
  recurringConfigDayOfMonth: '',
  grantsAndBudgetAllocation: [
    {
      key: Date.now(),
      expenseBudget: 'Unallocated',
      expenseLineItem: 'Unallocated',
      expenseBudgetLineItemAmount: 0,
      expenseGrants: 'Unallocated',
      grantsLineItem: 'Unallocated',
      expenseGrantsLineItemAmount: 0,
      purpose: '',
      notes: '',
      isEditing: false,
      isReadMore: false,
      attachment: [],
      allocationAmount: null,
      validationError: '',
      accountInfo: {
        bookOfAccount: '',
        expenseLedger: 'Unallocated',
        partyLedger: 'Unallocated',
        tdsLedger: 'Not Applicable',
        tdsAmount: 0
      }
    }
  ],
  // attachments: [],
  approvalCount: 0,
  totalAllocationAmount: 0,
  table: '',
  auditLogs: []
};

export const newRecurringExpenseRules = {
  expenseType: [{ required: true, trigger: 'submit', message: 'Please select expense type' }],
  invoiceDate: [{ required: true, trigger: 'submit', message: 'Please select invoice date' }],
  partyName: [{ required: true, trigger: 'submit', message: 'Please enter party name' }],
  invoiceNumber: [{ required: true, trigger: 'submit', message: 'Please enter invoice number' }],
  totalAmount: [{ required: true, trigger: 'submit', message: 'Please enter total amount' }],
  recurringConfigStartDate: [{ required: true, trigger: 'submit', message: 'Please select start date' }],
  recurringConfigEndDate: [{ required: true, trigger: 'submit', message: 'Please select end date' }],
  recurringConfigRecurringType: [{ required: true, trigger: 'submit', message: 'Please select recurring type' }],
  recurringConfigDayOfMonth: [{ required: true, trigger: 'submit', message: 'Please select day of month' }],
  firstApproval: [{ required: true, trigger: 'submit', message: 'Please select first approval' }],
  secondApproval: [{ required: true, trigger: 'submit', message: 'Please select second approval' }],
  thirdApproval: [{ required: true, trigger: 'submit', message: 'Please select third approval' }],
  fourthApproval: [{ required: true, trigger: 'submit', message: 'Please select fourth approval' }],
  fifthApproval: [{ required: true, trigger: 'submit', message: 'Please select fifth approval' }],
  sixthApproval: [{ required: true, trigger: 'submit', message: 'Please select sixth approval' }],
  seventhApproval: [{ required: true, trigger: 'submit', message: 'Please select seventh approval' }],
  eighthApproval: [{ required: true, trigger: 'submit', message: 'Please select eighth approval' }],
  ninthApproval: [{ required: true, trigger: 'submit', message: 'Please select ninth approval' }],
  tenthApproval: [{ required: true, trigger: 'submit', message: 'Please select tenth approval' }]
};

export const allocationValidation = (expenseAllocation: GrantsAndBudgetAllocation[]) => {
  const validationErrorsArr = expenseAllocation.map(item => {
    const error = [];
    if (!item.allocationAmount || item.allocationAmount <= 0) {
      error.push('allocation amount');
    }
    if (!item.purpose) {
      error.push('purpose');
    }
    const errorMessage = error.length === 0 ? '' : `Please enter ${error.join(' and ')}!`;

    return {
      key: item.key,
      error: errorMessage
    };
  });
  return validationErrorsArr;
};

const approvalListData = (approversList: string[]) => {
  const formattedData: Record<string, any> = {};
  approversList.forEach((approver, index) => {
    const itemNumber = index + 1;
    if (isEmpty(approver)) return;
    formattedData[itemNumber] = {
      [`expense_approver_${itemNumber}`]: approver,
      [`approver_status_${itemNumber}`]: 'Pending',
      [`approver_notes_${itemNumber}`]: ''
    };
  });
  return JSON.stringify(formattedData).toString();
};

const formatDateUTC = (date: string | Date | Dayjs): string => {
  return dayjs.utc(date).startOf('day').toISOString();
};

export const createNewRecurringExpenseFormData = (formState: AddNewRecurringExpenseFormState) => {
  const approversList = approvalListData([
    formState.firstApproval,
    formState.secondApproval,
    formState.thirdApproval,
    formState.fourthApproval,
    formState.fifthApproval,
    formState.sixthApproval,
    formState.seventhApproval,
    formState.eighthApproval,
    formState.ninthApproval,
    formState.tenthApproval
  ]);
  const formData: Record<string, any> = {
    category: formState.expenseType,
    invoice_date: formState.invoiceDate ? formatDateUTC(formState.invoiceDate) : '',
    party_name: formState.partyName,
    invoice_no: formState.invoiceNumber,
    amount: formState.totalAmount,
    description: formState.description,
    vendor_bank: formState.vendorBank,
    approvers_json: approversList,
    start_date: formState.recurringConfigStartDate ? formatDateUTC(formState.recurringConfigStartDate) : '',
    end_date: formState.recurringConfigEndDate ? formatDateUTC(formState.recurringConfigEndDate) : '',
    expense_type: formState.recurringConfigRecurringType,
    month_date: formState.recurringConfigDayOfMonth
  };

  const lineItemData: Record<string, any> = {};
  formState.grantsAndBudgetAllocation.forEach((allocation, index) => {
    const itemNumber = index + 1;
    lineItemData[itemNumber] = {
      [`li_budget_${itemNumber}`]: allocation.expenseBudget,
      [`li_budget_li_${itemNumber}`]: allocation.expenseLineItem,
      [`li_grant_id_${itemNumber}`]: allocation.expenseGrants,
      [`li_grant_li_${itemNumber}`]: allocation.grantsLineItem,
      [`li_purpose_${itemNumber}`]: allocation.purpose,
      [`li_amount_${itemNumber}`]: allocation.allocationAmount,
      [`li_note_${itemNumber}`]: allocation.notes,
      [`li_fcra_status_${itemNumber}`]: allocation.accountInfo.bookOfAccount,
      [`li_ledger_${itemNumber}`]: allocation.accountInfo.expenseLedger,
      [`li_party_ledger_name_${itemNumber}`]: allocation.accountInfo.partyLedger,
      [`li_tds_ledger_name_${itemNumber}`]: allocation.accountInfo.tdsLedger,
      [`li_tds_amount_${itemNumber}`]: allocation.accountInfo.tdsAmount || 0
    };

    // let attachmentIndex = 1;

    // allocation.attachment.forEach(attachment => {
    //   formData[`at_file_${attachmentIndex}`] = attachment.rawFile;
    //   formData[`at_cat_${attachmentIndex}`] = '';
    //   formData[`at_lineitem_${attachmentIndex}`] = itemNumber;
    //   attachmentIndex++;
    // });
  });

  formData.line_items = JSON.stringify(lineItemData);

  return formData;
};

const getApprovalStatus = (approvalStatus: string) => {
  if (approvalStatus === '0 of 0' || approvalStatus === 'Approved') {
    return 'approved';
  } else if (approvalStatus === 'Not submitted for approval') {
    return 'draft';
  } else {
    return 'pending';
  }
};

export const parseFormDataToState = (formData: Record<string, any>, editPage: boolean) => {
  const formState: any = {
    expenseType: formData.category,
    invoiceDate: formData.invoice_date ? toDateTime(formatDateUTC(formData.invoice_date)) : null,
    partyName: formData.party_name,
    invoiceNumber: formData.invoice_no,
    totalAmount: formData.amount,
    description: formData.description,
    vendorBank: formData.vendor_bank,
    recurringConfigStartDate: formData.start_date ? toDateTime(formatDateUTC(formData.start_date)) : null,
    recurringConfigEndDate: formData.end_date ? toDateTime(formatDateUTC(formData.end_date)) : null,
    recurringConfigRecurringType: formData.expense_type,
    recurringConfigDayOfMonth: formData.month_date
  };

  const approvalValues = Object.values(formData.approvers_list).map(
    (approver: any, index: number) => approver[`expense_approver_${index + 1}`]
  );
  let approvalCount = 0;
  Object.values(approvalStateKeys).forEach((key: string, index) => {
    if (approvalValues[index]) approvalCount++;
    formState[key as keyof AddNewExpenseFormState] = approvalValues[index] || '';
  });
  formState.approvalCount = approvalCount;

  formState.grantsAndBudgetAllocation = formData.line_items.map((item: any, index: number) => ({
    key: Date.now() + index,
    expenseBudget: item.li_budget,
    expenseLineItem: item.li_budget_li,
    expenseGrants: item.li_grant_id,
    grantsLineItem: item.li_grant_li,
    purpose: item.li_purpose,
    allocationAmount: +item.li_amount,
    notes: item.li_note,
    isEditing: false,
    isReadMore: false,
    validationError: '',
    accountInfo: {
      bookOfAccount: item.li_fcra_status,
      expenseLedger: item.li_ledger,
      partyLedger: item.li_party_ledger_name,
      tdsLedger: item.li_tds_ledger_name,
      tdsAmount: +item.li_tds_amount
    }
  }));

  formState.table = formData.table;

  // Also add attachments to the grants and budget allocation.
  // This might need to be removed in the future as there is some difficulties to store in the BE.
  if (editPage) {
    formData.attachments.forEach((att: any) => {
      if (att.line_item_no !== '') {
        formState.grantsAndBudgetAllocation[att.line_item_no - 1].attachment.push({
          filename: att.filename
        });
      }
    });
    formState.auditLogs = formData.audit_logs;
    formState.approvalStatus = getApprovalStatus(formData.approval_status);
    formState.isCurrentApprover = formData.is_current_approver;
    formState.isFinalApprover = formData.is_final_approver;
  }

  return formState;
};

const approvalKeys = [
  'firstApproval',
  'secondApproval',
  'thirdApproval',
  'fourthApproval',
  'fifthApproval',
  'sixthApproval',
  'seventhApproval',
  'eighthApproval',
  'ninthApproval',
  'tenthApproval'
];

export const getApproversNames = (formState: any) => {
  const approvalNames: string[] = [];

  approvalKeys.forEach(key => {
    if (formState[key] !== '') {
      approvalNames.push(formState[key]);
    }
  });
  return approvalNames;
};

export const getTitleFromValue = (value: string, options: { title: string; value: string }[]) => {
  const option = options.find(option => option.value === value);
  return option ? option.title : value;
};
