<script setup lang="ts">
  import { ChevronDownIcon, ChevronUpIcon, DownloadIcon } from '@heroicons/vue/solid';
  import EyeIconOutlined from '~/assets/Icons/EyeIconOutlined.vue';
  import PageIconOutlined from '~/assets/Icons/PageIconOutlined.vue';
  import OverviewCard from '~/components/UI/Others/OverviewCard.vue';
  import { downloadResponseFile, viewResponseFile } from '~/functions/fileActionHandlers';
  import { getAttachment } from '~/services/donationScreen';
  import type { CustomFiles } from '~/types';

  const { items, table } = defineProps<{
    items: CustomFiles;
    table: string;
  }>();

  const route = useRoute();

  const identifier = route.query.identifier as string;

  const showAll = ref(false);

  const handleDownloadAll = async () => {
    try {
      for (const { filename } of items) {
        await downloadFile(filename);
      }
    } catch (error) {
      console.error('Error downloading all files:', error);
    }
  };

  const viewFile = async (fileName: string) => {
    //displays everything except the excel file.
    try {
      const response = await getAttachment(table, identifier, fileName);
      viewResponseFile(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const downloadFile = async (fileName: string) => {
    try {
      const response = await getAttachment(table, identifier, fileName);
      downloadResponseFile(response, fileName);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
</script>

<template>
  <OverviewCard title="Attachment">
    <template #callToAction>
      <a-button
        v-if="items?.length > 0"
        class="flex items-center gap-2 w-git h-fit border-none shadow-none p-0"
        @click="handleDownloadAll"
      >
        <DownloadIcon class="w-[18px] h-[18px] text-blue-600" />
        <p class="text-sm font-medium text-blue-600">Download All</p>
      </a-button>
    </template>
    <transition name="expand-fade">
      <div v-if="items?.length > 0" class="flex flex-col gap-2.5">
        <div
          v-for="{ filename, category } in showAll ? items : items.slice(0, 4)"
          :key="filename"
          class="flex justify-between"
        >
          <div class="flex gap-2 items-center">
            <PageIconOutlined />
            <div class="flex flex-col gap-0.5">
              <p class="text-sm font-medium text-zinc-700">
                {{ filename.slice(0, 32).concat(filename?.length > 32 ? '...' : '') }}
              </p>
              <p class="text-xs font-medium text-zinc-600">{{ category ? category : ' ' }}</p>
            </div>
          </div>
          <div class="flex items-center gap-2">
            <a-button class="h-fit w-fit p-0 border-none" @click="viewFile(filename)">
              <EyeIconOutlined />
            </a-button>
            <a-button class="h-fit w-fit p-0 border-none" @click="downloadFile(filename)">
              <DownloadIcon class="w-[18px] h-[18px] text-zinc-700" />
            </a-button>
          </div>
        </div>
        <div v-if="items?.length > 4" class="flex">
          <a-button class="p-0 border-none shadow-none w-fit h-fit sm-medium text-zinc-600" @click="showAll = !showAll">
            <div v-if="showAll === true" class="flex gap-2 items-center">
              <ChevronUpIcon class="w-4 h-4 text-zinc-600" />
              Show Less
            </div>
            <div v-if="showAll === false" class="flex gap-2 items-center">
              <ChevronDownIcon class="w-4 h-4 text-zinc-600" />
              Show All
            </div>
          </a-button>
        </div>
      </div>
      <div v-else>
        <p class="text-sm font-medium text-zinc-700">No Attachments</p>
      </div>
    </transition>
  </OverviewCard>
</template>

<style scoped>
  .expand-fade-enter-active,
  .expand-fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .expand-fade-enter,
  .expand-fade-leave-to {
    opacity: 0;
  }
</style>
