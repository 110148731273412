import axios from 'axios';

export const getDefaultUrl = () => {
  const config = useRuntimeConfig();
  return config.public.API_BASE_URL;
};

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const api = axios.create({
    baseURL: config.public.API_BASE_URL,
    headers: {
      common: {}
    }
  });
  return {
    provide: {
      api: api
    }
  };
});
