<script setup lang="ts">
  import Navbar from '~/components/Navbar/Navbar.vue';
  import RoutedDrawerController from '~/components/shared/RoutedDrawers/RoutedDrawerController.vue';
  import RoutedModalController from '~/components/shared/RoutedModals/RoutedModalController.vue';
  const authPaths = ['/login', '/forgot-password', '/reset-password'];
</script>

<template>
  <a-config-provider
    :theme="{
      token: {
        fontFamily: 'Inter',
        colorPrimary: '#2563EB'
      }
    }"
  >
    <Navbar v-if="!authPaths.includes($route.path)" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <RoutedDrawerController />
    <RoutedModalController />
  </a-config-provider>
</template>
