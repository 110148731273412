<script setup lang="tsx">
  import CustomDropdown from '~/components/UI/Forms/CustomDropdown.vue';
  import CustomInput from '~/components/UI/Forms/CustomInput.vue';
  import RoleBasedButton from '~/components/UI/Others/RoleBasedButton.vue';
  import { get, post } from '~/services/donationScreen';
  import type { CostCentreDrawer, GetResponse, ResponseStatus, PostResponse } from '~/types';
  import EditPasswordModal from '../../Modals/EditPasswordModal.vue';

  const props = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const route = useRoute();
  const action = computed(() => {
    return route.query.action as string;
  });

  const formRef = ref();
  const formState = reactive({
    costCentreName: '',
    costCategoryName: 'Primary Cost Category',
    booksOfAccount: ''
  });

  const isModalVisible = ref<boolean>(false);
  const editStatus = ref<ResponseStatus>('idle');

  const submitHandler = async (modalData: { password: string }) => {
    try {
      editStatus.value = 'pending';
      const response = await post<PostResponse<CostCentreDrawer>>(`v1/cost_centre/${props.identifier}`, {
        data: {
          cost_centre_name: formState.costCentreName,
          cost_category_name: formState.costCategoryName,
          is_fcra: formState.booksOfAccount,
          password: modalData.password
        }
      });

      if (response.status === 401) {
        editStatus.value = 'error';
        return openFailedCustomNotification('Wrong Password!');
      }

      if (response.data.status === 'success') {
        openSuccessfulCustomNotification('Cost Centre Updated Successfully');
        isModalVisible.value = false;
        props.onClose();
      } else {
        openFailedCustomNotification('Failed to Update Cost Centre');
        editStatus.value = 'error';
      }
    } catch (error) {
      console.error(error);
      openFailedCustomNotification('Failed to Update Cost Centre');
      editStatus.value = 'error';
    }
  };

  const handleCreate = async () => {
    editStatus.value = 'pending';
    try {
      const response = await post<PostResponse<CostCentreDrawer>>('v1/add_new_cost_centre', {
        data: {
          cost_centre_name: formState.costCentreName,
          cost_category_name: formState.costCategoryName,
          is_fcra: formState.booksOfAccount
        }
      });

      if (response.data.status === 'success') {
        openSuccessfulCustomNotification('Cost Centre Created Successfully');
        props.onClose();
        editStatus.value = 'success';
      } else {
        openFailedCustomNotification('Failed to Create Cost Centre');
        editStatus.value = 'error';
      }
    } catch (error) {
      console.error(error);
      openFailedCustomNotification('Failed to Create Cost Centre');
      editStatus.value = 'error';
    }
  };

  const handleCreateCostCentre = async () => {
    try {
      await formRef.value.validate();

      if (route.query.action === 'edit') {
        return openModal();
      }

      await handleCreate();
    } catch (error) {
      console.error(error);
    }
  };

  const openModal = async () => {
    try {
      const isFormValid = await formRef.value.validate();
      if (!isFormValid) throw new Error('Form validation failed!');

      isModalVisible.value = true;
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalCancel = () => {
    isModalVisible.value = false;
  };

  const fetchData = async () => {
    try {
      const costCentreResponse = await get<GetResponse<CostCentreDrawer>>(`v1/get_cost_centre/${props.identifier}`);
      if (costCentreResponse.data) {
        Object.assign(formState, {
          costCentreName: costCentreResponse?.data?.data?.selected_cost_centre?.cost_centre_name || '',
          costCategoryName: costCentreResponse?.data?.data?.selected_cost_centre?.cost_category_name || '',
          booksOfAccount: costCentreResponse?.data?.data?.selected_cost_centre?.is_fcra || ''
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const rules = {
    costCentreName: [
      {
        required: true,
        message: 'Cost Centre Name is required',
        trigger: 'submit'
      }
    ],
    costCategoryName: [
      {
        required: true,
        message: 'Cost Category Name is required',
        trigger: 'submit'
      }
    ],
    booksOfAccount: [
      {
        required: true,
        message: 'Books of Account is required',
        trigger: 'submit'
      }
    ]
  };

  const updateStateField = (value: string, stateKey: keyof typeof formState) => {
    (formState as any)[stateKey] = value;
  };

  onMounted(() => {
    if (props.identifier) {
      fetchData();
    }
  });
</script>

<template>
  <a-drawer
    :open="route.query.drawerFrom === 'costCentres'"
    :title="
      action === 'edit'
        ? 'Update Cost Centre'
        : action === 'create' || action === 'duplicate'
          ? 'Add New Cost Centre'
          : 'Cost Centre'
    "
    width="750"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    @close="props.onClose"
  >
    <template #extra>
      <RoleBasedButton
        v-if="!props.identifier || action"
        component-name="addActionButton"
        active-class="py-2 px-3 bg-blue-600 hover:bg-blue-700 text-white rounded sm-medium duration-150 border border-blue-600"
        disabled-class="py-2 px-3 rounded sm-medium duration-150 !bg-gray-200 !shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_0px_#0000000F] border-none"
        :loading="editStatus === 'pending'"
        @click="handleCreateCostCentre"
      >
        {{ action === 'edit' ? 'Update Cost Centre' : 'Create Cost Centre' }}
      </RoleBasedButton>
    </template>
    <a-form
      ref="formRef"
      class="flex flex-col gap-4 p-3 setup-drawer-form"
      :model="formState"
      :rules="rules"
      :colon="false"
      :label-col="{ style: { width: '250px', textAlign: 'left' } }"
      hide-required-mark="true"
    >
      <CustomInput
        label-text="Cost Centre Name"
        name="costCentreName"
        :value="formState.costCentreName"
        state-key="costCentreName"
        :is-compulsory="true"
        :is-disabled="!action"
        @update:value="updateStateField"
      />
      <CustomInput
        label-text="Cost Category Name"
        name="costCategoryName"
        :value="formState.costCategoryName"
        state-key="costCategoryName"
        :rule="rules.costCategoryName"
        :is-compulsory="true"
        :is-disabled="!action"
        @update:value="updateStateField"
      />
      <CustomDropdown
        :options="[
          { title: 'FCRA Books', value: 'FCRA Books' },
          { title: 'Non-FCRA Books', value: 'Non-FCRA Books' }
        ]"
        label-text="Books of Account"
        name="booksOfAccount"
        :value="formState.booksOfAccount"
        state-key="booksOfAccount"
        :rule="rules.booksOfAccount"
        :is-compulsory="true"
        :disabled="!action"
        @update:value="updateStateField"
      />
    </a-form>
    <EditPasswordModal
      :visible="isModalVisible"
      :loading="editStatus === 'pending'"
      modal-title="Update Cost Centre"
      @update:cancel="handleModalCancel"
      @update:update="submitHandler"
    />
  </a-drawer>
</template>

<style>
  @import '~/assets/css/donationDrawer.css';

  .setup-drawer-form .ant-form-item {
    margin-bottom: 0px;
  }
</style>
