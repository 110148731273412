<script setup lang="tsx">
  import CustomDropdown from '~/components/UI/Forms/CustomDropdown.vue';
  import CustomInput from '~/components/UI/Forms/CustomInput.vue';
  import CustomTextArea from '~/components/UI/Forms/CustomTextArea.vue';
  import RoleBasedButton from '~/components/UI/Others/RoleBasedButton.vue';
  import { get, post } from '~/services/donationScreen';
  import type { GetResponse, ResponseStatus, StockItemDrawer, PostResponse } from '~/types';
  import EditPasswordModal from '../../Modals/EditPasswordModal.vue';

  const props = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const route = useRoute();
  const action = computed(() => {
    return route.query.action as string;
  });

  const formRef = ref();
  const formState = reactive({
    itemName: '',
    unitOfMeasurement: '',
    itemDescription: ''
  });

  const isModalVisible = ref<boolean>(false);
  const editStatus = ref<ResponseStatus>('idle');

  const submitHandler = async (modalData: { password: string }) => {
    try {
      editStatus.value = 'pending';
      const response = await post<PostResponse<StockItemDrawer>>(`v1/stock_item/${props.identifier}`, {
        data: {
          name: formState.itemName,
          units: formState.unitOfMeasurement,
          description: formState.itemDescription,
          password: modalData.password
        }
      });

      if (response.status === 401) {
        editStatus.value = 'error';
        return openFailedCustomNotification('Wrong Password!');
      }

      if (response.data.status === 'success') {
        openSuccessfulCustomNotification('Stock Item Updated Successfully');
        isModalVisible.value = false;
        props.onClose();
      } else {
        openFailedCustomNotification('Failed to Update Stock Item');
        editStatus.value = 'error';
      }
    } catch (error) {
      console.error(error);
      openFailedCustomNotification('Failed to Update Stock Item');
      editStatus.value = 'error';
    }
  };

  const handleCreate = async () => {
    editStatus.value = 'pending';
    try {
      const response = await post<PostResponse<StockItemDrawer>>('v1/add_new_stock_item', {
        data: {
          name: formState.itemName,
          units: formState.unitOfMeasurement,
          description: formState.itemDescription
        }
      });

      if (response.data.status === 'success') {
        openSuccessfulCustomNotification('Stock Item Created Successfully');
        props.onClose();
        editStatus.value = 'success';
      } else {
        openFailedCustomNotification('Failed to Create Stock Item');
        editStatus.value = 'error';
      }
    } catch (error) {
      console.error(error);
      openFailedCustomNotification('Failed to Create Stock Item');
      editStatus.value = 'error';
    }
  };

  const handleCreateStockItem = async () => {
    try {
      await formRef.value.validate();

      if (route.query.action === 'edit') {
        return openModal();
      }

      await handleCreate();
    } catch (error) {
      console.error(error);
    }
  };

  const openModal = async () => {
    try {
      const isFormValid = await formRef.value.validate();
      if (!isFormValid) throw new Error('Form validation failed!');

      isModalVisible.value = true;
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalCancel = () => {
    isModalVisible.value = false;
  };

  const fetchData = async () => {
    try {
      const stockItemResponse = await get<GetResponse<StockItemDrawer>>(`v1/get_stock_item/${props.identifier}`);
      if (stockItemResponse.data) {
        Object.assign(formState, {
          itemName: stockItemResponse?.data?.data?.selected_stock_item?.name || '',
          unitOfMeasurement: stockItemResponse?.data?.data?.selected_stock_item?.units || '',
          itemDescription: stockItemResponse?.data?.data?.selected_stock_item?.description || ''
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const rules = {
    itemName: [
      {
        required: true,
        message: 'Item Name is required',
        trigger: 'submit'
      }
    ],
    unitOfMeasurement: [
      {
        required: true,
        message: 'Unit of Measurement is required',
        trigger: 'submit'
      }
    ],
    itemDescription: []
  };

  const updateStateField = (value: string, stateKey: keyof typeof formState) => {
    (formState as any)[stateKey] = value;
  };

  onMounted(() => {
    if (props.identifier) {
      fetchData();
    }
  });
</script>

<template>
  <a-drawer
    :open="route.query.drawerFrom === 'stockItems'"
    :title="
      action === 'edit'
        ? 'Update Stock Item'
        : action === 'add' || action === 'duplicate'
          ? 'Add New Stock Item'
          : 'Stock Item'
    "
    width="750"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    @close="props.onClose"
  >
    <template #extra>
      <RoleBasedButton
        v-if="!props.identifier || action"
        component-name="addActionButton"
        active-class="py-2 px-3 bg-blue-600 hover:bg-blue-700 text-white rounded sm-medium duration-150 border border-blue-600"
        disabled-class="py-2 px-3 rounded sm-medium duration-150 !bg-gray-200 !shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_0px_#0000000F] border-none"
        :loading="editStatus === 'pending'"
        @click="handleCreateStockItem"
      >
        {{ action === 'edit' ? 'Update Stock Item' : 'Create Stock Item' }}
      </RoleBasedButton>
    </template>
    <a-form
      ref="formRef"
      class="flex flex-col gap-4 p-3 setup-drawer-form"
      :model="formState"
      :rules="rules"
      :colon="false"
      :label-col="{ style: { width: '250px', textAlign: 'left' } }"
      hide-required-mark="true"
    >
      <CustomInput
        label-text="Stock Item Name"
        name="itemName"
        :value="formState.itemName"
        state-key="itemName"
        :is-compulsory="true"
        :is-disabled="!action"
        @update:value="updateStateField"
      />
      <CustomDropdown
        :options="[
          { title: 'Pieces', value: 'Pieces' },
          { title: 'Kg', value: 'Kg' },
          { title: 'Liters', value: 'Liters' },
          { title: 'Boxes', value: 'Boxes' },
          { title: 'Packets', value: 'Packets' },
          { title: 'Meters', value: 'Meters' },
          { title: 'Numbers', value: 'Numbers' },
          { title: 'Hours', value: 'Hours' }
        ]"
        label-text="Unit of Measurement"
        name="unitOfMeasurement"
        :value="formState.unitOfMeasurement"
        state-key="unitOfMeasurement"
        :rule="rules.unitOfMeasurement"
        :is-compulsory="true"
        :disabled="!action"
        @update:value="updateStateField"
      />
      <CustomTextArea
        label="Description"
        name="itemDescription"
        :value="formState.itemDescription"
        state-key="itemDescription"
        :is-disabled="!action"
        @update:value="updateStateField"
      />
    </a-form>
    <EditPasswordModal
      :visible="isModalVisible"
      :loading="editStatus === 'pending'"
      modal-title="Update Stock Item"
      @update:cancel="handleModalCancel"
      @update:update="submitHandler"
    />
  </a-drawer>
</template>

<style>
  @import '~/assets/css/donationDrawer.css';

  .setup-drawer-form .ant-form-item {
    margin-bottom: 0px;
  }
</style>
