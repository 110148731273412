import { Role } from '~/types/auth';

const { role, isExpenseEnabled } = useAuth();

const menuItems = {
  dashboard: [
    Role.Admin,
    Role.GrantReportManager,
    Role.ExpenseReportManager,
    Role.BudgetReportManager,
    Role.DonorReportManager,
    Role.ExpenseApprover,
    Role.ExpenseAdmin,
    Role.ReadOnly,
    Role.Auditor,
    Role.DonorAuditor
  ],
  expenses: [
    Role.Admin,
    Role.ReadOnly,
    Role.ExpenseSubmitter,
    Role.ExpenseApprover,
    Role.BudgetApprover,
    Role.BudgetMember,
    Role.Auditor,
    Role.DonorAuditor
  ],
  advances: [
    Role.Admin,
    Role.ReadOnly,
    Role.ExpenseSubmitter,
    Role.ExpenseApprover,
    Role.BudgetApprover,
    Role.BudgetMember,
    Role.Auditor,
    Role.DonorAuditor
  ],
  contacts: [Role.Admin, Role.DonorManager, Role.ReadOnly, Role.DonationManager, Role.Auditor, Role.DonorAuditor],
  donations: [Role.Admin, Role.DonationManager, Role.DonorManager, Role.ReadOnly, Role.Auditor, Role.DonorAuditor],
  grants: [
    Role.Admin,
    Role.DonationManager,
    Role.DonorManager,
    Role.GrantSubmitter,
    Role.GrantMember,
    Role.ReadOnly,
    Role.Auditor,
    Role.DonorAuditor
  ],
  pledges: [
    Role.Admin,
    Role.DonationManager,
    Role.DonorManager,
    Role.GrantSubmitter,
    Role.GrantMember,
    Role.ReadOnly,
    Role.Auditor,
    Role.DonorAuditor
  ],
  budgets: [
    Role.Admin,
    Role.ReadOnly,
    Role.ExpenseSubmitter,
    Role.ExpenseApprover,
    Role.BudgetApprover,
    Role.BudgetMember,
    Role.Auditor,
    Role.DonorAuditor
  ],
  utilities: [Role.Admin, Role.UtilitiesAccess],
  setup: [Role.Admin],
  admin: [Role.Admin]
};

export const canAccess = (item: keyof typeof menuItems) => {
  const allowedToAccess = menuItems[item].some(requiredRole => role.value.includes(requiredRole));
  if (item === 'expenses' || item === 'budgets') {
    return allowedToAccess && isExpenseEnabled.value;
  }
  return allowedToAccess;
};
