<script lang="ts" setup>
  import Divider from '~/components/UI/Others/Divider.vue';

  const props = defineProps<{
    title: string;
    stateKey?: string;
    checkboxCollection?: {
      name: string;
      checked: boolean;
      checkboxText: string;
    }[];
    isDisabled?: boolean;
  }>();

  const localChecked = ref(props.checkboxCollection);
  const emit = defineEmits(['update:modelValue']);

  watch(
    () => props.checkboxCollection,
    newValue => {
      localChecked.value = newValue;
    }
  );

  watch(
    () => localChecked.value,
    newValue => {
      const newObject: any = {};
      newValue?.forEach((item: any) => {
        newObject[item.name] = item.checked;
      });
      emit('update:modelValue', props.stateKey, newObject);
    },
    { deep: true }
  );

  const handleCheckboxClick = (checkbox: any) => {
    if (props.isDisabled) return;
    checkbox.checked = !checkbox.checked;
  };
</script>

<template>
  <div
    class="flex flex-col gap-4 p-3 rounded-lg bg-white max-w-[320px] border border-gray-200 shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_0px_#0000000F]"
  >
    <p class="text-sm text-gray-600 font-bold">{{ props.title }}</p>
    <Divider />
    <a-checkbox
      v-for="checkbox in localChecked"
      :checked="checkbox.checked"
      :name="checkbox.name"
      class="text-sm text-gray-600 font-medium"
      @click="handleCheckboxClick(checkbox)"
      >{{ checkbox.checkboxText }}</a-checkbox
    >
  </div>
</template>
