<script setup lang="ts">
  import { onMounted, onUnmounted } from 'vue';
  import AsteriskText from '../Forms/AsteriskText.vue';

  const props = defineProps<{
    visible: boolean;
    loading: boolean;
    modalTitle: string;
  }>();

  const modalState = reactive<{
    password: string;
  }>({
    password: ''
  });

  const updateModalRef = ref();
  const updateModalRules = {
    password: [{ required: true, message: 'Password is required!', trigger: 'submit' }]
  };

  const emit = defineEmits(['update:update', 'update:cancel']);

  const handleModalOk = async () => {
    try {
      const isValid = await updateModalRef.value.validate();
      if (isValid) {
        emit('update:update', { ...modalState });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalCancel = () => {
    emit('update:cancel');
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleModalOk();
    }
  };

  onMounted(() => {
    document.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    document.removeEventListener('keydown', handleKeyDown);
  });
</script>

<template>
  <a-modal
    :open="props.visible"
    :title="props.modalTitle"
    class="account-info-modal"
    ok-text="Confirm"
    @ok="handleModalOk"
    @cancel="handleModalCancel"
  >
    <a-form
      ref="updateModalRef"
      layout="vertical"
      label-align="left"
      class="flex flex-col gap-3"
      :label-col="{ style: { width: '183px' } }"
      :colon="false"
      :rules="updateModalRules"
      :model="modalState"
      :hide-required-mark="true"
      :disabled="props.loading"
      submit-on-enter
    >
      <a-form-item name="password" class="!sm-medium">
        <template #label>
          <AsteriskText label="Password" />
        </template>
        <a-input v-model:value="modalState.password" type="password" class="w-[248px]" />
      </a-form-item>
    </a-form>
    <template #footer>
      <div class="flex gap-1 w-full justify-end border-t border-gray-300 p-3 bg-gray-50 rounded-b-lg">
        <a-button
          class="rounded text-sm font-medium border border-gray-300 text-gray-500 px-3 py-1.5"
          @click="handleModalCancel"
          >Cancel</a-button
        >
        <a-button
          class="px-3 border border-blue-700 rounded py-1.5 flex items-center justify-center text-sm font-medium"
          :loading="props.loading"
          type="primary"
          @click="handleModalOk"
          >Confirm</a-button
        >
      </div>
    </template>
  </a-modal>
</template>

<style scoped>
  @import '~/assets/css/modal.css';

  .ant-form-item {
    margin-bottom: 0px !important;
  }
</style>
