<script setup lang="tsx">
  import { ChevronDownIcon, ExclamationIcon, PlusIcon } from '@heroicons/vue/solid';
  import type {
    ResponseStatus,
    InfoBlock,
    AdvancePaymentResponse,
    PaymentUpdateResponse,
    AdvanceSearchOptions,
    AdvancePaymentOptions,
    GetExpenseResponse
  } from '~/types';
  import DrawerLayout from '~/components/UI/Drawers/DrawerLayout.vue';
  import RoleBasedButton from '~/components/UI/Others/RoleBasedButton.vue';
  import CustomInput from '~/components/UI/Forms/CustomInput.vue';
  import CustomDate from '~/components/UI/Forms/CustomDate.vue';
  import CustomRupeeInput from '~/components/UI/Forms/CustomRupeeInput.vue';
  import CustomDropdown from '~/components/UI/Forms/CustomDropdown.vue';
  import CustomDropdownWithSearch from '~/components/UI/Forms/CustomDropdownWithSearch.vue';
  import { get, post } from '~/services/donationScreen';
  import { toDateTime } from '~/functions/dateTimeUtilities';
  import { formatToIndianCurrency } from '~/functions/currencyUtilities';
  import type { Dayjs } from 'dayjs';
  import {
    bookOfAccounts,
    paymentModes,
    updatePaymentFormData,
    parseFormDataToState
  } from '~/components/Expenses/Table/expenseTableUtils';
  import ExpenseTotalAmountModal from '~/components/Expenses/Table/ExpenseTotalAmountModal.vue';
  import AuditLogs from '~/components/shared/RoutedDrawers/RoutedDrawerComponents/AuditLogs.vue';
  import { cloneDeep } from 'lodash';
  import PaymentSummaryRow from '~/components/Expenses/Table/PaymentSummaryRow.vue';

  const { onClose, identifier } = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const responseState = ref<ResponseStatus>('success');
  const getDropdownDataStatus = ref<ResponseStatus>('idle');
  const formState = ref<InfoBlock[]>([]);
  const expenseDetails = reactive({
    totalAmountPaid: null,
    balancePayments: 0,
    balanceForFCRA: 0,
    balanceForNonFCRA: 0,
    remainingToBeAllocated: 0,
    totalInvoiceAmount: 0,
    totalExpenseAllocationNonFcra: 0,
    totalExpenseAllocationFcra: 0,
    totalUnallocatedTotalAmount: 0,
    totalExpenseAllocation: 0,
    tdsNonFcra: 0,
    tdsFcra: 0,
    unallocatedTdsAmount: 0,
    totalTdsAmount: 0,
    payableNetOfTdsNonFcra: 0,
    payableNetOfTdsFcra: 0,
    payableNetOfTdsUnallocated: 0,
    payableNetOfTds: 0,
    auditLog: [],
    grantsAndBudgetAllocation: []
  });
  const activeKey = ref([]);
  const advanceSearchOptions = ref<AdvanceSearchOptions[]>([]);
  const updatePaymentStatus = ref<ResponseStatus>('idle');

  const paymentsNonFcra = computed(() => {
    return formState.value.reduce((acc, payment) => {
      if (!payment.isAdvance && payment.bookOfAccounts === 'Non-FCRA') {
        return acc + (payment.amount || 0);
      }
      return acc;
    }, 0);
  });

  const paymentsFcra = computed(() => {
    return formState.value.reduce((acc, payment) => {
      if (!payment.isAdvance && payment.bookOfAccounts === 'FCRA') {
        return acc + (payment.amount || 0);
      }
      return acc;
    }, 0);
  });

  const totalPayments = computed(() => {
    return +paymentsNonFcra.value + +paymentsFcra.value;
  });

  const paymentsViaAdvancesNonFcra = computed(() => {
    return formState.value.reduce((acc, payment) => {
      if (payment.isAdvance && payment.bookOfAccounts === 'Non-FCRA') {
        return acc + (payment.amount || 0);
      }
      return acc;
    }, 0);
  });

  const paymentsViaAdvancesFcra = computed(() => {
    return formState.value.reduce((acc, payment) => {
      if (payment.isAdvance && payment.bookOfAccounts === 'FCRA') {
        return acc + (payment.amount || 0);
      }
      return acc;
    }, 0);
  });

  const totalPaymentsViaAdvances = computed(() => {
    return +paymentsViaAdvancesNonFcra.value + +paymentsViaAdvancesFcra.value;
  });

  const totalPaymentsAndAdvancesNonFcra = computed(() => {
    return totalPayments.value + totalPaymentsViaAdvances.value;
  });

  const totalPaymentsAndAdvancesFcra = computed(() => {
    return totalPayments.value + totalPaymentsViaAdvances.value;
  });

  const totalPaymentAndAdvancesBoth = computed(() => {
    return +totalPaymentsAndAdvancesNonFcra.value + +totalPaymentsAndAdvancesFcra.value;
  });

  const balanceNonFcra = computed(() => {
    return expenseDetails.payableNetOfTdsNonFcra - totalPaymentsAndAdvancesNonFcra.value;
  });

  const balanceFcra = computed(() => {
    return expenseDetails.payableNetOfTdsFcra - totalPaymentsAndAdvancesFcra.value;
  });

  const balanceBoth = computed(() => {
    return +expenseDetails.payableNetOfTds - +totalPaymentAndAdvancesBoth.value;
  });

  const addPayment = (isAdvance: boolean) => {
    formState.value.push({
      id: Date.now(),
      isAdvance: isAdvance,
      advance: '',
      advancePaymentId: '',
      paymentDate: '',
      paymentMode: '',
      referenceNumber: '',
      bookOfAccounts: '',
      amount: null,
      amountPaid: null,
      outstanding: null,
      error: ''
    });
  };

  const removePayment = (id: number) => {
    formState.value = formState.value.filter(payment => payment.id !== id);
  };

  const updateFormState = (value: string, id: number, key: string) => {
    formState.value = formState.value.map(payment => {
      if (payment.id === id) {
        (payment as any)[key] = value;
      }
      return payment;
    });
  };

  const validateForm = (): Promise<boolean> => {
    let hasError = false;
    formState.value = formState.value.map(payment => {
      const requiredFields = payment.isAdvance
        ? ['advancePaymentId', 'advance', 'paymentDate', 'paymentMode', 'referenceNumber', 'bookOfAccounts', 'amount']
        : ['paymentDate', 'paymentMode', 'referenceNumber', 'bookOfAccounts', 'amount'];

      // Check if any required field is missing.
      const missingField = requiredFields.some(field => !payment[field as keyof InfoBlock]);
      let error = '';
      if (missingField) {
        error = 'All fields are required!';
      } else if (payment.isAdvance && payment.amount && payment.amount > (payment.outstanding || 0)) {
        error = 'Amount exceeds outstanding!';
      }
      payment.error = error;
      if (error) {
        hasError = true;
      }
      return payment;
    });

    return hasError ? Promise.reject(false) : Promise.resolve(true);
  };

  const updatePayment = async () => {
    try {
      const isFormValid = await validateForm();
      if (!isFormValid) {
        return;
      }
      updatePaymentStatus.value = 'pending';
      const formData = updatePaymentFormData(formState.value);
      const response = await post<PaymentUpdateResponse>(`/v1/save_payments_exp_list/${identifier}`, {
        data: formData
      });

      if (response?.data?.status === 'success') {
        openSuccessfulCustomNotification(`Payment updated successfully!`);
        updatePaymentStatus.value = 'success';
        onClose();
      } else {
        updatePaymentStatus.value = 'error';
        openFailedCustomNotification('Something went wrong while updating payment!');
      }
    } catch (error) {
      console.error('Error updating payment:', error);
      updatePaymentStatus.value = 'error';
    }
  };

  onMounted(async () => {
    try {
      getDropdownDataStatus.value = 'pending';
      const advanceDropdownResponse = await get<AdvancePaymentResponse>('/v1/get_advance_payments_line_item');

      const advanceDropdownData = advanceDropdownResponse?.data?.data?.map(advanceOption => {
        const advancePaymentOptions: {
          title: string;
          value: string;
          advancePayments: {
            title: string;
            value: string;
            amountPaid: number;
            outstanding: number;
            paymentDate: Dayjs;
            paymentMode: string;
            referenceNumber: string;
            bookOfAccounts: string;
          }[];
        } = {
          title: advanceOption['advance-head'],
          value: advanceOption['advance-head'],
          advancePayments: []
        };

        advancePaymentOptions.advancePayments = Object.entries(advanceOption['advance-line-items']).map(
          ([key, payment]) => {
            const paymentIndex = parseInt(key);
            return {
              title: `${key}-${payment[`payment_date_${paymentIndex}`]}-${payment[`payment_mode_${paymentIndex}`]}`,
              value: `${key}-${payment[`payment_date_${paymentIndex}`]}-${payment[`payment_mode_${paymentIndex}`]}`,
              amountPaid: +payment[`payment_amount_${paymentIndex}`],
              outstanding: +payment[`payment_amount_${paymentIndex}`] - +payment[`payment_utilized_${paymentIndex}`],
              paymentDate: toDateTime(payment[`payment_date_${paymentIndex}`]),
              paymentMode: payment[`payment_mode_${paymentIndex}`],
              referenceNumber: payment[`payment_ref_${paymentIndex}`],
              bookOfAccounts: payment[`payment_fcra_status_${paymentIndex}`]
            };
          }
        );
        return advancePaymentOptions;
      });
      advanceSearchOptions.value = advanceDropdownData;
    } catch (error) {
      getDropdownDataStatus.value = 'error';
      openFailedCustomNotification('Something went wrong while fetching input data!');
      console.error('Error fetching data:', error);
    }
  });

  onMounted(async () => {
    try {
      const response = await get<GetExpenseResponse>(`/v1/get_expense/${identifier}`);
      const selectedExpense = response?.data?.data?.selected_expense;
      const { expDetails, payments } = parseFormDataToState(selectedExpense);
      Object.assign(expenseDetails, cloneDeep(expDetails));
      const updatedPayments = payments.map((payment: any) => {
        if (payment.isAdvance) {
          const advancePayment = advanceSearchOptions.value.find(option => option.value === payment.advance);
          const advancePaymentOption = advancePayment?.advancePayments.find(
            item => item.value === payment.advancePaymentId
          );
          payment.amountPaid = advancePaymentOption?.amountPaid;
          payment.outstanding = advancePaymentOption?.outstanding;
        }
        return payment;
      });
      formState.value = updatedPayments;
    } catch (error: any) {
      openFailedCustomNotification('Something went wrong while fetching expense data!');
      console.error('Error while fetching expense data: ', error);
    }
  });
</script>

<template>
  <a-drawer
    v-if="identifier"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    title="Payment Information"
    :open="identifier !== null"
    width="1220"
    @close="onClose"
  >
    <template #extra>
      <div class="flex gap-2 items-center">
        <a-button
          class="flex rounded items-center gap-1 sm-medium py-1.5 px-2 justify-center shadow-[0_1px_2px_0px_#0000000D]"
          @click="onClose"
        >
          Cancel
        </a-button>
        <RoleBasedButton
          component-name="updatePaymentActionButton"
          active-class="!text-white hover:!text-white bg-blue-700 hover:bg-blue-600 disabled:!text-blue-300 disabled:border-blue-300 disabled:bg-blue-50 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-blue-700 hover:!border-blue-600 shadow-[0_1px_3px_0px_#0000001A,0_1px_2px_0_#0000000F]"
          disabled-class="flex rounded items-center !text-gray-300 gap-1 text-sm font-medium py-1.5 px-2 justify-center border !border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          :loading="updatePaymentStatus === 'pending'"
          @click="updatePayment"
        >
          Update Payment
        </RoleBasedButton>
      </div>
    </template>
    <DrawerLayout
      :response-state="responseState"
      main-display="null"
      sub-display="null"
      side-display="null"
      no-header-display
      @close="onClose"
    >
      <template #column1>
        <div v-if="formState.length === 0" class="flex min-h-[243px] bg-gray-50 w-full justify-center items-center">
          <p class="sm-medium text-gray-500">No Payments Linked.</p>
        </div>
        <a-form
          v-else
          class="flex flex-col w-full p-3 gap-3 min-h-[243px] paymentManagement"
          :colon="false"
          :label-col="{ style: { width: '158px' } }"
          label-align="left"
          :hide-required-mark="true"
          :disabled="updatePaymentStatus === 'pending'"
          @submit="updatePayment"
        >
          <div
            v-for="payment in formState"
            :key="payment.id"
            class="flex border border-gray-200 rounded-lg shadow-[0_1px_4px_0px_#0C0C0D0D]"
          >
            <div class="flex flex-col gap-3 p-3 w-[430px] border-r border-gray-200">
              <CustomDropdownWithSearch
                v-if="payment.isAdvance"
                label-text="Advance"
                :value="payment.advance"
                name="advance"
                :is-compulsory="true"
                state-key="advance"
                :options="advanceSearchOptions"
                :disabled="updatePaymentStatus === 'pending'"
                @update:value="
                  val => {
                    // reset other details when Advance is selected
                    formState = formState.map(paymentItem => {
                      if (paymentItem.id === payment.id) {
                        paymentItem.advance = val;
                        paymentItem.advancePaymentId = '';
                        paymentItem.paymentDate = '';
                        paymentItem.paymentMode = '';
                        paymentItem.bookOfAccounts = '';
                        paymentItem.referenceNumber = '';
                        paymentItem.amount = null;
                        paymentItem.amountPaid = null;
                        paymentItem.outstanding = null;
                      }
                      return paymentItem;
                    });
                  }
                "
              />
              <CustomDropdownWithSearch
                v-if="payment.isAdvance"
                label-text="Advance Payment ID"
                :value="payment.advancePaymentId"
                name="advancePaymentId"
                :is-compulsory="true"
                :is-disabled="!payment.advance || updatePaymentStatus === 'pending'"
                :options="
                  advanceSearchOptions
                    .find(option => option.value === payment.advance)
                    ?.advancePayments.map(payment => ({
                      title: payment.title,
                      value: payment.value
                    })) || []
                "
                @update:value="
                  val => {
                    const advancePayment = advanceSearchOptions
                      .find(option => option.value === payment.advance)
                      ?.advancePayments.find(paymentItem => paymentItem.value === val) as AdvancePaymentOptions;
                    // auto fill other details when Advance Payment ID is selected
                    formState = formState.map(paymentItem => {
                      if (paymentItem.id === payment.id) {
                        paymentItem.advancePaymentId = val;
                        paymentItem.paymentDate = advancePayment.paymentDate;
                        paymentItem.paymentMode = advancePayment?.paymentMode;
                        paymentItem.bookOfAccounts = advancePayment?.bookOfAccounts;
                        paymentItem.referenceNumber = advancePayment?.referenceNumber;
                        paymentItem.amountPaid = advancePayment?.amountPaid;
                        paymentItem.outstanding = advancePayment?.outstanding;
                      }
                      return paymentItem;
                    });
                  }
                "
              />
              <CustomDate
                label-text="Payment Date"
                :value="payment.paymentDate"
                name="paymentDate"
                :is-compulsory="true"
                state-key="paymentDate"
                :disabled="payment.isAdvance || updatePaymentStatus === 'pending'"
                @update:value="val => updateFormState(val, payment.id, 'paymentDate')"
              />
              <CustomDropdown
                label-text="Payment Mode"
                :value="payment.paymentMode"
                name="paymentMode"
                :is-compulsory="true"
                :options="paymentModes"
                :state-key="`paymentMode`"
                :disabled="payment.isAdvance || updatePaymentStatus === 'pending'"
                @update:value="val => updateFormState(val, payment.id, 'paymentMode')"
              />
              <CustomInput
                label-text="Reference Number"
                :value="payment.referenceNumber"
                name="referenceNumber"
                :state-key="`referenceNumber`"
                :is-compulsory="true"
                :is-disabled="payment.isAdvance || updatePaymentStatus === 'pending'"
                @update:value="val => updateFormState(val, payment.id, 'referenceNumber')"
              />
              <CustomDropdown
                label-text="Book of Accounts"
                :value="payment.bookOfAccounts"
                name="bookOfAccounts"
                :state-key="`bookOfAccounts`"
                :is-compulsory="true"
                :options="bookOfAccounts"
                :disabled="payment.isAdvance || updatePaymentStatus === 'pending'"
                @update:value="val => updateFormState(val, payment.id, 'bookOfAccounts')"
              />
            </div>
            <div class="flex flex-col gap-2 bg-gray-50 p-3 rounded-r-lg justify-between">
              <div class="flex flex-col gap-3">
                <CustomRupeeInput
                  label-text="Amount"
                  :value="payment.amount"
                  name="amount"
                  :is-compulsory="true"
                  :disabled="updatePaymentStatus === 'pending'"
                  :state-key="`amount`"
                  @update:value="val => updateFormState(val, payment.id, 'amount')"
                />
                <div
                  v-if="payment.isAdvance && payment.advancePaymentId"
                  class="flex flex-col gap-2 bg-gray-50 p-3 rounded-lg border border-gray-200"
                >
                  <p class="text-gray-400 sm-medium">Payment Info</p>
                  <div class="flex items-center justify-between text-gray-600">
                    <p>Amount Paid</p>
                    <p>{{ formatToIndianCurrency(payment.amountPaid) }}</p>
                  </div>
                  <div class="flex items-center justify-between text-gray-600">
                    <p>Outstanding</p>
                    <p>{{ formatToIndianCurrency(payment.outstanding) }}</p>
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-2">
                <a-alert v-if="payment.error" :message="payment.error" type="error" show-icon />
                <a-button
                  class="self-end !bg-gray-50 !border-none !shadow-none !text-red-600 !w-fit !h-fit !p-0 sm-medium"
                  :disabled="updatePaymentStatus === 'pending'"
                  @click="removePayment(payment.id)"
                >
                  Remove
                </a-button>
              </div>
            </div>
          </div>
        </a-form>
        <div class="flex gap-3 px-3 py-5 border-b border-gray-100 mb-10">
          <a-button
            class="flex items-center gap-2 !p-0 !w-fit !h-fit !border-none !bg-transparent !shadow-none text-blue-700"
            :disabled="updatePaymentStatus === 'pending'"
            @click="addPayment(false)"
          >
            <PlusIcon class="h-4 w-4" />
            Add Payment
          </a-button>
          <a-button
            class="flex items-center gap-2 !p-0 !w-fit !h-fit !border-none !bg-transparent !shadow-none text-blue-700"
            :disabled="updatePaymentStatus === 'pending'"
            @click="addPayment(true)"
          >
            <PlusIcon class="h-4 w-4" />
            Link Advance
          </a-button>
        </div>
      </template>
      <template #column2>
        <div
          :class="`bg-white border border-gray-200 rounded shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_0px_#0000000F] `"
        >
          <div class="flex justify-between w-full p-3">
            <span class="font-medium text-sm text-gray-600">Total Amount Paid</span>
            <span class="font-bold text-sm text-gray-700"> {{ expenseDetails.totalAmountPaid }}</span>
          </div>
        </div>
        <div
          class="flex flex-col bg-white text-gray-600 sm-medium w-full rounded shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_0px_#0000000F] overflow-hidden"
        >
          <div class="flex items-center justify-between w-full p-3 border-b border-gray-200">
            <p>Balance Payments</p>
            <p class="text-red-700">{{ expenseDetails.balancePayments }}</p>
          </div>
          <div class="flex items-center justify-between w-full px-3 py-2 border-b border-gray-200">
            <div class="flex gap-2 items-center sm-medium">
              <ExclamationIcon class="h-4 w-4 text-red-600" />
              <p>Balance for FCRA</p>
            </div>
            <p>{{ expenseDetails.balanceForFCRA }}</p>
          </div>
          <div class="flex items-center justify-between w-full px-3 py-2 border-b border-gray-200">
            <p>Balance for Non-FCRA</p>
            <p>{{ expenseDetails.balanceForNonFCRA }}</p>
          </div>
          <div class="flex items-center justify-between w-full px-3 py-2 border-b border-gray-200">
            <p>Remaining to be Allocated</p>
            <p>{{ expenseDetails.remainingToBeAllocated }}</p>
          </div>
        </div>
        <ExpenseTotalAmountModal
          :total-invoice-amount="expenseDetails.totalInvoiceAmount"
          :allocation-details="expenseDetails.grantsAndBudgetAllocation"
        />
        <AuditLogs :items="expenseDetails.auditLog" title="Expense" />
      </template>
    </DrawerLayout>
    <a-collapse
      v-model:active-key="activeKey"
      class="fixed bottom-0 right-0 w-[1220px] paymentCollapse"
      :default-active-key="['1']"
    >
      <template #expandIcon="{ isActive }">
        <ChevronDownIcon class="text-black w-4 h-4" :class="{ 'rotate-180': !isActive }" />
      </template>
      <a-collapse-panel key="1" header="Payment Summary" class="">
        <div class="rounded shadow-[0_1px_4px_0px_#0C0C0D0D] overflow-hidden">
          <table class="w-full">
            <thead>
              <tr class="bg-gray-50">
                <td class="border border-gray-200 w-full"></td>
                <td class="border border-gray-200 xs-medium text-gray-600 min-w-[140px] px-3 py-2 text-right">
                  Non-FCRA
                </td>
                <td class="border border-gray-200 xs-medium text-gray-600 min-w-[140px] px-3 py-2 text-right">FCRA</td>
                <td class="border border-gray-200 xs-medium text-gray-600 min-w-[140px] px-3 py-2 text-right">
                  Unallocated
                </td>
                <td class="border border-gray-200 xs-medium text-gray-600 min-w-[140px] px-3 py-2 text-right">Total</td>
              </tr>
            </thead>
            <tbody>
              <PaymentSummaryRow
                :expense-details="[
                  'Total Expense Allocation',
                  expenseDetails.totalExpenseAllocationNonFcra,
                  expenseDetails.totalExpenseAllocationFcra,
                  expenseDetails.totalUnallocatedTotalAmount,
                  expenseDetails.totalExpenseAllocation
                ]"
              />
              <PaymentSummaryRow
                :expense-details="[
                  'TDS',
                  expenseDetails.tdsNonFcra,
                  expenseDetails.tdsFcra,
                  expenseDetails.unallocatedTdsAmount,
                  expenseDetails.totalTdsAmount
                ]"
              />
              <PaymentSummaryRow
                :expense-details="[
                  'Payable (Net of TDS) - (A)',
                  expenseDetails.payableNetOfTdsNonFcra,
                  expenseDetails.payableNetOfTdsFcra,
                  expenseDetails.payableNetOfTdsUnallocated,
                  expenseDetails.payableNetOfTds
                ]"
              />
              <PaymentSummaryRow :expense-details="['Payments', paymentsNonFcra, paymentsFcra, '-', totalPayments]" />
              <PaymentSummaryRow
                :expense-details="[
                  'Payments via Advances',
                  paymentsViaAdvancesNonFcra,
                  paymentsViaAdvancesFcra,
                  '-',
                  totalPaymentsViaAdvances
                ]"
              />
              <PaymentSummaryRow
                :expense-details="[
                  'Total Amount Paid - (B)',
                  totalPaymentsAndAdvancesNonFcra,
                  totalPaymentsAndAdvancesFcra,
                  '-',
                  totalPaymentAndAdvancesBoth
                ]"
              />
              <PaymentSummaryRow
                :expense-details="[
                  'Balance',
                  balanceNonFcra,
                  balanceFcra,
                  expenseDetails.payableNetOfTdsUnallocated,
                  balanceBoth
                ]"
              />
            </tbody>
          </table>
        </div>
      </a-collapse-panel>
    </a-collapse>
  </a-drawer>
</template>

<style>
  @import '~/assets/css/donationDrawer.css';

  .paymentManagement .ant-picker {
    width: 206px;
  }

  .paymentManagement .ant-input {
    width: 206px;
  }

  .paymentManagement .ant-select-selector {
    width: 206px !important;
  }

  .paymentManagement .ant-select {
    width: 206px !important;
  }

  .paymentManagement .ant-form-item-control-input-content {
    width: 206px !important;
  }

  .paymentManagement .ant-input-number {
    width: 150px !important;
  }

  .paymentManagement .ant-form-item {
    margin-bottom: 0px;
  }

  .paymentCollapse .ant-collapse-expand-icon {
    margin-top: 1px;
    margin-right: 8px;
    border: 1px solid #d4d4d8;
    padding: 0px !important;
    background: #fff;
    height: 20px !important;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    z-index: 1;
  }

  .paymentCollapse .ant-collapse-item {
    border-radius: 0px !important;
    border: 0px !important;
  }

  .paymentCollapse .ant-collapse {
    border-radius: 0px !important;
    border: 0px !important;
  }

  .paymentCollapse .ant-collapse-header {
    border-radius: 0px !important;
    border: 0px !important;
  }

  .paymentCollapse .ant-collapse-content {
    border-top: 0px !important;
  }

  .paymentCollapse .ant-collapse-content-box {
    padding: 12px !important;
    padding-top: 0px !important;
  }
</style>
