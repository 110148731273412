import { canAccessRoute, canAccessTab } from '~/utils/auth/routeController';
import { useAuth } from '~/composables/useAuth';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { isAuthenticated, checkAuth, isExpenseEnabled } = useAuth();
  if (!isAuthenticated.value) {
    await checkAuth();
  }

  if (!isAuthenticated.value) {
    if (to.path !== '/login' && to.path !== '/forgot-password' && to.path !== '/reset-password') {
      return navigateTo('/login');
    }
  } else {
    if (to.path === '/login' || to.path === '/forgot-password' || to.path === '/reset-password') {
      return navigateTo('/');
    }
    if (to.path === '/expenses' || to.path === '/recurring-expenses') {
      if (!canAccessRoute(to.path)) {
        return useRouter().push({
          path: '/forbidden',
          query: { from: from.path }
        });
      }
      if (Object.prototype.hasOwnProperty.call(to.query, 'tab')) {
        if (!canAccessTab(to.query.tab as string)) {
          return useRouter().push({
            path: '/forbidden',
            query: { from: from.path }
          });
        }
      }
    }

    if (
      (!isExpenseEnabled.value && to.path.includes('/expenses')) ||
      (!isExpenseEnabled.value && to.path.includes('/budgets'))
    ) {
      return navigateTo('/forbidden/no-subscription');
    }

    if (!canAccessRoute(to.path) && to.path !== '/forbidden') {
      return useRouter().push({
        path: '/forbidden',
        query: { from: from.path }
      });
    }
  }
});
