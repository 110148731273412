<script setup lang="tsx">
  import { PencilIcon, TrashIcon, ChevronDownIcon } from '@heroicons/vue/solid';
  import { get } from '~/services/donationScreen';
  import type {
    AddNewExpenseAllocationDropdownApiResponse,
    AddNewExpenseFormState,
    AllocationBudgetOptions,
    AllocationGrantsOptions,
    GetExpenseResponse,
    ResponseStatus
  } from '~/types';
  import InfoRow from '~/components/UI/Drawers/InfoRow.vue';
  import InfoSection from '~/components/UI/Drawers/InfoSection.vue';
  import DrawerLayout from '~/components/UI/Drawers/DrawerLayout.vue';
  import { formatToIndianCurrency } from '~/functions/currencyUtilities';
  import RoleBasedButton from '~/components/UI/Others/RoleBasedButton.vue';
  import GrantsAndBudgetAllocation from '../RoutedDrawerComponents/GrantsAndBudgetAllocation.vue';
  import { expenseFormDefaultState, parseFormDataToState } from '~/components/Expenses/Forms/addNewExpenseUtils';
  import { cloneDeep } from 'lodash';
  import dayjs from 'dayjs';
  import Attachments from '../../Others/Attachments.vue';
  import AuditLogs from '~/components/shared/RoutedDrawers/RoutedDrawerComponents/AuditLogs.vue';
  import PaymentSummaryRow from '~/components/Expenses/Table/PaymentSummaryRow.vue';
  import { getPaymentsDetails, getPaymentSummary, sumConditional } from '~/components/Expenses/Table/expenseTableUtils';
  import ApproveExpenseModal from '../../Modals/ApproveExpenseModal.vue';
  import DenyExpenseModal from '../../Modals/DenyExpenseModal.vue';
  import CancelExpenseModal from '../../Modals/CancelExpenseModal.vue';
  const { onClose, identifier } = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const { role } = useAuth();

  const data = ref<AddNewExpenseFormState>(cloneDeep(expenseFormDefaultState));
  const responseState = ref<ResponseStatus>('success');
  const isExpenseCancelled = ref<boolean>(false);
  const activeKey = ref([]);
  const paymentAllocations = ref();
  const paymentsDetails = ref();
  const denyExpenseModal = ref<boolean>(false);
  const approveExpenseModal = ref<boolean>(false);
  const cancelExpenseModal = ref<boolean>(false);
  const paymentsNonFcra = computed(() => {
    return sumConditional(
      paymentsDetails.value,
      (item: any) => !item.isAdvance && item.bookOfAccounts === 'Non-FCRA',
      (item: any) => +item.amount
    );
  });

  const paymentsFcra = computed(() => {
    return sumConditional(
      paymentsDetails.value,
      (item: any) => !item.isAdvance && item.bookOfAccounts === 'FCRA',
      (item: any) => +item.amount
    );
  });

  const totalPayments = computed(() => {
    return +paymentsNonFcra.value + +paymentsFcra.value;
  });

  const paymentsViaAdvancesNonFcra = computed(() => {
    return sumConditional(
      paymentsDetails.value,
      (item: any) => item.isAdvance && item.bookOfAccounts === 'Non-FCRA',
      (item: any) => +item.amount
    );
  });

  const paymentsViaAdvancesFcra = computed(() => {
    return sumConditional(
      paymentsDetails.value,
      (item: any) => item.isAdvance && item.bookOfAccounts === 'FCRA',
      (item: any) => +item.amount
    );
  });

  const totalPaymentsViaAdvances = computed(() => {
    return +paymentsViaAdvancesNonFcra.value + +paymentsViaAdvancesFcra.value;
  });

  const totalPaymentsAndAdvancesNonFcra = computed(() => {
    return totalPayments.value + totalPaymentsViaAdvances.value;
  });

  const totalPaymentsAndAdvancesFcra = computed(() => {
    return totalPayments.value + totalPaymentsViaAdvances.value;
  });

  const totalPaymentAndAdvancesBoth = computed(() => {
    return +totalPaymentsAndAdvancesNonFcra.value + +totalPaymentsAndAdvancesFcra.value;
  });

  const balanceNonFcra = computed(() => {
    return paymentAllocations.value.payableNetOfTdsNonFcra - totalPaymentsAndAdvancesNonFcra.value;
  });

  const balanceFcra = computed(() => {
    return paymentAllocations.value.payableNetOfTdsFcra - totalPaymentsAndAdvancesFcra.value;
  });

  const balanceBoth = computed(() => {
    return +paymentAllocations.value.payableNetOfTds - +totalPaymentAndAdvancesBoth.value;
  });

  const allowToEditExpense = computed(() => {
    if (data.value.approvalStatus === 'pending') {
      return data.value.isCurrentApprover;
    } else if (data.value.approvalStatus === 'draft' || data.value.approvalStatus === 'approved') {
      if (
        !role.value.includes('read-only') &&
        !role.value.includes('auditor') &&
        !role.value.includes('donor_auditor')
      ) {
        return true;
      }
    }
    return true;
  });

  const allowToCancelExpense = computed(() => {
    if (data.value.approvalStatus === 'draft') {
      return true;
    } else if (data.value.approvalStatus === 'pending') {
      return data.value.isCurrentApprover;
    } else if (data.value.approvalStatus === 'approved') {
      return role.value.includes('admin') || data.value.isFinalApprover;
    }
    return true;
  });

  const showDenyExpenseModal = () => {
    denyExpenseModal.value = true;
  };

  const hideDenyExpenseModal = () => {
    denyExpenseModal.value = false;
    fetchData(); // Refresh data after closing modal
  };

  const showApproveExpenseModal = () => {
    approveExpenseModal.value = true;
  };

  const hideApproveExpenseModal = () => {
    approveExpenseModal.value = false;
    fetchData(); // Refresh data after closing modal
  };

  const showCancelExpenseModal = () => {
    cancelExpenseModal.value = true;
  };

  const hideCancelExpenseModal = () => {
    cancelExpenseModal.value = false;
  };

  const fetchData = async () => {
    responseState.value = 'pending';
    try {
      const expenseResponse = await get<GetExpenseResponse>(`/v1/get_expense/${identifier}`);

      if (expenseResponse?.status === 200) {
        const selectedExpense = expenseResponse?.data?.data?.selected_expense;
        const allocationDropdownResponse = await get<AddNewExpenseAllocationDropdownApiResponse>(
          `/v1/add_new_expense/${selectedExpense.category}`
        );

        const budgetOptions = allocationDropdownResponse?.data?.data?.budgets_list?.map(budget => ({
          mainLabel: budget.budget_name,
          subLabel: budget.budget_id,
          value: budget.budget_id_value,
          amount: budget.amount - budget.utilization.approved + budget.utilization.not_approved,
          lineItem: budget.line_items.map(lineItem => ({
            label: `${lineItem.data.li} - ${lineItem.data.sub_li}`,
            value: lineItem.data.li_value,
            amount: lineItem.amount - lineItem.utilized.approved + lineItem.utilized.not_approved
          }))
        })) as AllocationBudgetOptions[];

        const grantOptions = allocationDropdownResponse?.data?.data?.grants_list?.map(grant => ({
          mainLabel: grant.grant_id,
          subLabel: grant.donor_name,
          value: grant.grant_id_value,
          amount: grant.amount - grant.utilization.approved + grant.utilization.not_approved,
          purpose: grant.purpose,
          lineItem: grant.line_items.map(lineItem => ({
            label: `${lineItem.data.li} - ${lineItem.data.sub_li}`,
            value: lineItem.data.li_value,
            amount: lineItem.amount - lineItem.utilized.approved + lineItem.utilized.not_approved
          }))
        })) as AllocationGrantsOptions[];

        const parsedData = parseFormDataToState(selectedExpense, true);
        // We are not storing all the data that we need to show in the drawer.
        // Get other fields from the allocation dropdown response.
        parsedData.grantsAndBudgetAllocation = parsedData.grantsAndBudgetAllocation.map((item: any) => {
          const budgetOption = budgetOptions.find(budget => budget.value === item.expenseBudget);
          const grantOption = grantOptions.find(grant => grant.value === item.expenseGrants);
          const budgetSubLabel = budgetOption?.subLabel;
          const grantSubLabel = grantOption?.subLabel;
          const budgetLineItemAmount = budgetOption?.lineItem.find(
            lineItem => lineItem.value === item.expenseLineItem
          )?.amount;
          const grantLineItemAmount = grantOption?.lineItem.find(
            lineItem => lineItem.value === item.grantsLineItem
          )?.amount;
          return { ...item, budgetSubLabel, grantSubLabel, budgetLineItemAmount, grantLineItemAmount };
        });

        isExpenseCancelled.value = selectedExpense.is_cancelled;
        paymentAllocations.value = getPaymentSummary(selectedExpense.line_items);
        paymentsDetails.value = getPaymentsDetails(selectedExpense.payments);
        data.value = parsedData;
        responseState.value = 'success';
      } else {
        responseState.value = 'error';
      }
    } catch (error) {
      openFailedCustomNotification('Error fetching expense details!');
      console.error('Error fetching data:', error);
    }
  };

  onMounted(fetchData);
</script>

<template>
  <a-drawer
    v-if="identifier"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    title="Expense Information"
    :open="identifier !== null"
    width="1220"
    @close="onClose"
  >
    <template #extra>
      <div v-if="!isExpenseCancelled" class="flex gap-2">
        <RoleBasedButton
          v-if="data.approvalStatus !== 'draft'"
          component-name="deleteActionButton"
          :disabled="!allowToCancelExpense"
          active-class="text-blue-700 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center text-gray-500 border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          disabled-class="flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          @click="showCancelExpenseModal"
        >
          <TrashIcon class="h-4 w-4 text-red-700" />
          Cancel Expense
        </RoleBasedButton>
        <RoleBasedButton
          v-if="data.approvalStatus !== 'draft'"
          component-name="editActionButton"
          :disabled="data.approvalStatus !== 'approved'"
          active-class="text-blue-700 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center text-gray-500 border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          disabled-class="flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          @click="
            () => navigateTo({ path: '/expenses', query: { drawerFrom: 'expensesPaymentManagement', identifier } })
          "
        >
          <PencilIcon class="h-4 w-4" />
          Update Payments
        </RoleBasedButton>
        <RoleBasedButton
          component-name="editActionButton"
          :disabled="!allowToEditExpense"
          active-class="text-blue-700 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center text-gray-500 border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          disabled-class="flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          @click="
            () => navigateTo({ path: '/expenses', query: { drawerFrom: 'expensesPaymentManagement', identifier } })
          "
        >
          <PencilIcon class="h-4 w-4" />
          Edit Expense
        </RoleBasedButton>
        <RoleBasedButton
          component-name="closeActionButton"
          :disabled="!(data.isCurrentApprover && data.approvalStatus === 'pending')"
          active-class="!text-white hover:!text-white bg-red-700 hover:bg-red-600 disabled:!text-red-300 disabled:border-red-300 disabled:bg-red-50 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-red-700 hover:!border-red-600 shadow-[0_1px_3px_0px_#0000001A,0_1px_2px_0_#0000000F]"
          disabled-class="flex rounded items-center !text-red-300 gap-1 text-sm font-medium py-1.5 px-2 justify-center border !border-red-300 shadow-[0_1px_2px_0px_#0000000D]"
          @click="showDenyExpenseModal"
        >
          Deny Expense
        </RoleBasedButton>
        <RoleBasedButton
          component-name="approveActionButton"
          :disabled="!(data.isCurrentApprover && data.approvalStatus === 'pending')"
          active-class="!text-white hover:!text-white bg-green-700 hover:bg-green-600 disabled:!text-green-300 disabled:border-green-300 disabled:bg-green-50 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-green-700 hover:!border-green-600 shadow-[0_1px_3px_0px_#0000001A,0_1px_2px_0_#0000000F]"
          disabled-class="flex rounded items-center !text-green-500 gap-1 text-sm font-medium py-1.5 px-2 justify-center !bg-green-100 border !border-green-500 shadow-[0_1px_2px_0px_#0000000D]"
          @click="showApproveExpenseModal"
        >
          Approve Expense
        </RoleBasedButton>
      </div>
      <p v-else class="text-base font-bold text-red-600">Expense is Closed</p>
    </template>
    <DrawerLayout
      :response-state="responseState"
      :main-display="data.partyName"
      :sub-display="data.invoiceNumber"
      :side-display="formatToIndianCurrency(data.totalAmount)"
      @close="onClose"
    >
      <template #column1>
        <div class="p-3">
          <a-alert v-if="data.approvalStatus === 'approved'" message="Expense is Approved" type="success" show-icon />
          <a-alert
            v-if="data.approvalStatus === 'pending'"
            message="Expense is Pending Approval"
            type="warning"
            show-icon
          />
          <a-alert v-if="data.approvalStatus === 'draft'" message="Expense is Draft" type="info" show-icon />
        </div>
        <InfoSection>
          <InfoRow label="Expense Type" :value="data.expenseType" class="capitalize" />
          <InfoRow label="Invoice Date" :value="dayjs(data.invoiceDate).format('DD/MM/YYYY')" />
          <InfoRow label="Payee" :value="data.partyName" />
          <InfoRow label="Description" :value="data.description" />
        </InfoSection>
        <GrantsAndBudgetAllocation :allocations="data.grantsAndBudgetAllocation" />
      </template>
      <template #column2>
        <Attachments :items="data?.attachments" :table="data?.table" />
        <AuditLogs :items="data?.auditLogs" title="Expense" />
      </template>
    </DrawerLayout>

    <a-collapse
      v-model:active-key="activeKey"
      class="fixed bottom-0 right-0 w-[1220px] paymentCollapse"
      :default-active-key="['1']"
    >
      <template #expandIcon="{ isActive }">
        <ChevronDownIcon class="text-black w-4 h-4" :class="{ 'rotate-180': !isActive }" />
      </template>
      <a-collapse-panel key="1" header="Payment Summary" class="">
        <div class="rounded shadow-[0_1px_4px_0px_#0C0C0D0D] overflow-hidden">
          <table class="w-full">
            <thead>
              <tr class="bg-gray-50">
                <td class="border border-gray-200 w-full"></td>
                <td class="border border-gray-200 xs-medium text-gray-600 min-w-[140px] px-3 py-2 text-right">
                  Non-FCRA
                </td>
                <td class="border border-gray-200 xs-medium text-gray-600 min-w-[140px] px-3 py-2 text-right">FCRA</td>
                <td class="border border-gray-200 xs-medium text-gray-600 min-w-[140px] px-3 py-2 text-right">
                  Unallocated
                </td>
                <td class="border border-gray-200 xs-medium text-gray-600 min-w-[140px] px-3 py-2 text-right">Total</td>
              </tr>
            </thead>
            <tbody>
              <PaymentSummaryRow
                :expense-details="[
                  'Total Expense Allocation',
                  paymentAllocations.totalExpenseAllocationNonFcra,
                  paymentAllocations.totalExpenseAllocationFcra,
                  paymentAllocations.totalUnallocatedTotalAmount,
                  paymentAllocations.totalExpenseAllocation
                ]"
              />
              <PaymentSummaryRow
                :expense-details="[
                  'TDS',
                  paymentAllocations.tdsNonFcra,
                  paymentAllocations.tdsFcra,
                  paymentAllocations.unallocatedTdsAmount,
                  paymentAllocations.totalTdsAmount
                ]"
              />
              <PaymentSummaryRow
                :expense-details="[
                  'Payable (Net of TDS) - (A)',
                  paymentAllocations.payableNetOfTdsNonFcra,
                  paymentAllocations.payableNetOfTdsFcra,
                  paymentAllocations.payableNetOfTdsUnallocated,
                  paymentAllocations.payableNetOfTds
                ]"
              />
              <PaymentSummaryRow :expense-details="['Payments', paymentsNonFcra, paymentsFcra, '-', totalPayments]" />
              <PaymentSummaryRow
                :expense-details="[
                  'Payments via Advances',
                  paymentsViaAdvancesNonFcra,
                  paymentsViaAdvancesFcra,
                  '-',
                  totalPaymentsViaAdvances
                ]"
              />
              <PaymentSummaryRow
                :expense-details="[
                  'Total Amount Paid - (B)',
                  totalPaymentsAndAdvancesNonFcra,
                  totalPaymentsAndAdvancesFcra,
                  '-',
                  totalPaymentAndAdvancesBoth
                ]"
              />
              <PaymentSummaryRow
                :expense-details="[
                  'Balance',
                  balanceNonFcra,
                  balanceFcra,
                  paymentAllocations.payableNetOfTdsUnallocated,
                  balanceBoth
                ]"
              />
            </tbody>
          </table>
        </div>
      </a-collapse-panel>
    </a-collapse>
  </a-drawer>
  <CancelExpenseModal :visible="cancelExpenseModal" :close="hideCancelExpenseModal" :expense-id="identifier" />
  <DenyExpenseModal :visible="denyExpenseModal" :close="hideDenyExpenseModal" :expense-id="identifier" />
  <ApproveExpenseModal :visible="approveExpenseModal" :close="hideApproveExpenseModal" :expense-id="identifier" />
</template>

<style>
  @import '~/assets/css/donationDrawer.css';
</style>
