<script setup lang="tsx">
  import CustomDropdown from '~/components/UI/Forms/CustomDropdown.vue';
  import CustomInput from '~/components/UI/Forms/CustomInput.vue';
  import CustomTextArea from '~/components/UI/Forms/CustomTextArea.vue';
  import RoleBasedButton from '~/components/UI/Others/RoleBasedButton.vue';
  import { get, post } from '~/services/donationScreen';
  import type { GetResponse, PurposeDrawer, ResponseStatus, PostResponse } from '~/types';
  import EditPasswordModal from '../../Modals/EditPasswordModal.vue';

  const props = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const route = useRoute();

  const action = computed(() => {
    return route.query.action as string;
  });

  const formRef = ref();
  const formState = reactive({
    purposeName: '',
    purposeCategory: '',
    purposeDimension: '',
    purposeDescription: ''
  });

  const purposeDimesntionsOptions = ref<{ title: string; value: string }[]>([]);

  const isModalVisible = ref<boolean>(false);
  const editStatus = ref<ResponseStatus>('idle');

  const fetchData = async () => {
    try {
      const dimensionsResponse = await get<GetResponse<string[]>>(`v1/get_purpose_dimensions`);
      purposeDimesntionsOptions.value = dimensionsResponse.data.data.map(dimension => ({
        title: dimension,
        value: dimension
      }));

      if (props.identifier) {
        const purposeResponse = await get<GetResponse<PurposeDrawer>>(`v1/get_purpose/${props.identifier}`);
        if (purposeResponse.data) {
          Object.assign(formState, {
            purposeName: purposeResponse?.data?.data?.selected_purpose?.purpose_name || '',
            purposeCategory: purposeResponse?.data?.data?.selected_purpose?.purpose_category || '',
            purposeDimension: purposeResponse?.data?.data?.selected_purpose?.purpose_dimension || '',
            purposeDescription: purposeResponse?.data?.data?.selected_purpose?.purpose_description || ''
          });
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const rules = {
    purposeName: [
      {
        required: true,
        message: 'Purpose Name is required',
        trigger: 'submit'
      }
    ],
    purposeCategory: [
      {
        required: true,
        message: 'Purpose Category is required',
        trigger: 'submit'
      }
    ],
    purposeDimension: [
      {
        required: true,
        message: 'Purpose Dimension is required',
        trigger: 'submit'
      }
    ],
    purposeDescription: [
      {
        required: true,
        message: 'Purpose Description is required',
        trigger: 'submit'
      }
    ]
  };

  const updateStateField = (value: string, stateKey: keyof typeof formState) => {
    (formState as any)[stateKey] = value;
  };

  const submitHandler = async (modalData: { password: string }) => {
    try {
      editStatus.value = 'pending';
      const response = await post<PostResponse<PurposeDrawer>>(`v1/purpose/${props.identifier}`, {
        data: {
          purpose_name: formState.purposeName,
          purpose_category: formState.purposeCategory,
          purpose_dimension: formState.purposeDimension,
          purpose_description: formState.purposeDescription,
          password: modalData.password
        }
      });

      if (response.status === 401) {
        editStatus.value = 'error';
        return openFailedCustomNotification('Wrong Password!');
      }

      if (response.data.status === 'success') {
        openSuccessfulCustomNotification('Purpose Updated Successfully');
        isModalVisible.value = false;
        props.onClose();
      } else {
        openFailedCustomNotification('Failed to Update Purpose');
        editStatus.value = 'error';
      }
    } catch (error) {
      console.error(error);
      openFailedCustomNotification('Failed to Update Purpose');
      editStatus.value = 'error';
    }
  };

  const handleCreate = async () => {
    editStatus.value = 'pending';
    try {
      const response = await post<PostResponse<PurposeDrawer>>('v1/add_new_purpose', {
        data: {
          purpose_name: formState.purposeName,
          purpose_category: formState.purposeCategory,
          purpose_dimension: formState.purposeDimension,
          purpose_description: formState.purposeDescription
        }
      });

      if (response.data.status === 'success') {
        openSuccessfulCustomNotification('Purpose Created Successfully');
        props.onClose();
        editStatus.value = 'success';
      } else {
        openFailedCustomNotification('Failed to Create Purpose');
        editStatus.value = 'error';
      }
    } catch (error) {
      console.error(error);
      openFailedCustomNotification('Failed to Create Purpose');
      editStatus.value = 'error';
    }
  };

  const handleCreatePurpose = async () => {
    try {
      await formRef.value.validate();

      if (route.query.action === 'edit') {
        return openModal();
      }

      await handleCreate();
    } catch (error) {
      console.error(error);
    }
  };

  const openModal = async () => {
    try {
      const isFormValid = await formRef.value.validate();
      if (!isFormValid) throw new Error('Form validation failed!');

      isModalVisible.value = true;
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalCancel = () => {
    isModalVisible.value = false;
  };

  onMounted(() => {
    fetchData();
  });
</script>

<template>
  <a-drawer
    :open="route.query.drawerFrom === 'purposes'"
    :title="
      action === 'edit' ? 'Update Purpose' : action === 'add' || action === 'duplicate' ? 'Add New Purpose' : 'Purpose'
    "
    width="750"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    @close="props.onClose"
  >
    <template #extra>
      <RoleBasedButton
        v-if="!props.identifier || action"
        component-name="addActionButton"
        active-class="py-2 px-3 bg-blue-600 hover:bg-blue-700 text-white rounded sm-medium duration-150 border border-blue-600"
        disabled-class="py-2 px-3 rounded sm-medium duration-150 !bg-gray-200 !shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_0px_#0000000F] border-none"
        :loading="editStatus === 'pending'"
        @click="handleCreatePurpose"
      >
        {{ action === 'edit' ? 'Update Purpose' : 'Create Purpose' }}
      </RoleBasedButton>
    </template>
    <a-form
      ref="formRef"
      class="flex flex-col gap-4 p-3 setup-drawer-form"
      :model="formState"
      :rules="rules"
      :colon="false"
      :label-col="{ style: { width: '250px', textAlign: 'left' } }"
      hide-required-mark="true"
    >
      <CustomInput
        label-text="Purpose Name"
        name="purposeName"
        :value="formState.purposeName"
        state-key="purposeName"
        :is-compulsory="true"
        :is-disabled="!action"
        @update:value="updateStateField"
      />
      <CustomDropdown
        :options="[
          { title: 'Unrestricted', value: 'Unrestricted' },
          { title: 'Restricted', value: 'Restricted' },
          { title: 'Corpus', value: 'Corpus' },
          { title: 'Other Receipts', value: 'Other Receipts' }
        ]"
        label-text="Purpose Category"
        name="purposeCategory"
        :value="formState.purposeCategory"
        state-key="purposeCategory"
        :rule="rules.purposeCategory"
        :is-compulsory="true"
        :disabled="!action"
        @update:value="updateStateField"
      />
      <CustomDropdown
        v-if="purposeDimesntionsOptions && purposeDimesntionsOptions.length"
        :options="purposeDimesntionsOptions"
        label-text="Purpose Dimension"
        name="purposeDimension"
        :value="formState.purposeDimension"
        state-key="purposeDimension"
        :rule="rules.purposeDimension"
        :is-compulsory="true"
        :disabled="!action"
        @update:value="updateStateField"
      />
      <CustomTextArea
        label="Purpose Description"
        name="purposeDescription"
        :value="formState.purposeDescription"
        state-key="purposeDescription"
        :is-disabled="!action"
        @update:value="updateStateField"
      />
    </a-form>
    <EditPasswordModal
      :visible="isModalVisible"
      :loading="editStatus === 'pending'"
      modal-title="Update Purpose"
      @update:cancel="handleModalCancel"
      @update:update="submitHandler"
    />
  </a-drawer>
</template>

<style>
  @import '~/assets/css/donationDrawer.css';

  .setup-drawer-form .ant-form-item {
    margin-bottom: 0px;
  }
</style>
