<script setup lang="ts">
  import AsteriskText from '~/components/shared/Forms/AsteriskText.vue';
  import { allowOnlyNumbersAndDecimalPoint, formatter, parser } from '~/functions/formatterAndParser';

  const props = defineProps<{
    value: number | null;
    labelText: string;
    name: string;
    stateKey: string;
    placeholder?: string;
    rules?: any;
    labelClass?: string;
    inputClass?: string;
    isCompulsory?: boolean;
  }>();

  const localValue = ref<number | null>(props.value);

  const emit = defineEmits(['update:value']);

  watch(
    () => props.value,
    newValue => {
      localValue.value = newValue;
    }
  );

  watch(
    () => localValue.value,
    newValue => {
      emit('update:value', newValue, props.stateKey);
    }
  );
</script>
<template>
  <a-form-item :class="`!font-medium custom-input-rupee ${labelClass}`" :name="props.name" :rules="props.rules">
    <template #label>
      <AsteriskText v-if="props.isCompulsory" :label="props.labelText" />
      <p v-else class="text-zinc-600">{{ props.labelText }}</p>
    </template>
    <a-input-number
      v-model:value="localValue"
      addon-before="₹"
      :formatter="formatter"
      :parser="parser"
      :controls="false"
      :placeholder="props.placeholder"
      :class="`w-[248px] ${props.inputClass}`"
      @keypress="allowOnlyNumbersAndDecimalPoint"
    />
  </a-form-item>
</template>

<style>
  .custom-input-rupee .ant-form-item-label > label {
    line-height: 21px;
  }

  .custom-input-rupee .ant-input-number-input {
    color: #27272a !important;
    padding: 8px !important;
    line-height: 21px !important;
  }

  .custom-input-rupee .ant-input-number .ant-input-number-input {
    height: 39px !important;
  }
</style>
