<script setup lang="ts">
  import { AnnotationIcon, DocumentIcon } from '@heroicons/vue/outline';
  import { ChevronRightIcon, ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/vue/solid';
  import { formatToIndianCurrency } from '~/functions/currencyUtilities';

  const props = defineProps<{
    allocations: any[];
  }>();
</script>

<template>
  <div class="flex flex-col p-3 sm-medium gap-3">
    <div class="flex gap-2 items-center">
      <p class="text-gray-800 whitespace-nowrap">Grants and Budget Allocation</p>
      <div class="flex w-full h-0.5 bg-gray-200"></div>
    </div>
    <div class="flex flex-col gap-2">
      <div
        v-for="allocation in props.allocations"
        :key="allocation.id"
        class="flex border border-gray-200 shadow-[0_1px_3px_0px_#0000001A,0_1px_2px_0_#0000000F] rounded-lg"
      >
        <div class="border-r border-gray-200 w-full">
          <div class="flex flex-col">
            <div class="flex items-center justify-between w-full border-b border-gray-200 p-3 gap-2.5">
              <div class="flex flex-col gap-2 min-w-[190px] max-w-[190px]">
                <p class="text-gray-800">Expense Budget</p>
                <div class="flex flex-col">
                  <p class="text-gray-800 xs-medium">{{ allocation.expenseBudget }}</p>
                  <div class="flex items-center gap-2">
                    <p class="text-gray-600 xs-medium">{{ allocation.budgetSubLabel }}</p>
                    <InformationCircleIcon class="h-4 w-4 text-gray-600" />
                  </div>
                </div>
              </div>
              <div class="flex items-center p-1 bg-gray-100 rounded-full">
                <ChevronRightIcon class="h-5 w-5 text-gray-400" />
              </div>
              <div class="flex flex-col gap-2 min-w-[190px] max-w-[190px]">
                <p class="text-gray-800">Line Item</p>
                <div class="flex flex-col">
                  <p class="text-gray-800 xs-medium">{{ allocation.expenseLineItem }}</p>
                  <div class="flex items-center gap-2">
                    <p class="text-green-700 xs-medium">
                      {{ ` ${formatToIndianCurrency(allocation.budgetLineItemAmount)} Left` }}
                    </p>
                    <InformationCircleIcon class="h-4 w-4 text-gray-600" />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex items-center justify-between w-full border-b border-gray-200 p-3 gap-2.5">
              <div class="flex flex-col gap-2 min-w-[190px] max-w-[190px]">
                <p class="text-gray-800">Expense Grants</p>
                <div class="flex flex-col">
                  <p class="text-gray-800 xs-medium">{{ allocation.expenseGrants }}</p>
                  <div class="flex items-center gap-2">
                    <p class="text-gray-600 xs-medium">{{ allocation.grantSubLabel }}</p>
                    <InformationCircleIcon class="h-4 w-4 text-gray-600" />
                  </div>
                </div>
              </div>
              <div class="flex items-center p-1 bg-gray-100 rounded-full">
                <ChevronRightIcon class="h-5 w-5 text-gray-400" />
              </div>
              <div class="flex flex-col gap-2 min-w-[190px] max-w-[190px]">
                <p class="text-gray-800">Line Item</p>
                <div class="flex flex-col">
                  <p class="text-gray-800 xs-medium">{{ allocation.grantsLineItem }}</p>
                  <div class="flex items-center gap-2">
                    <p class="text-green-700 xs-medium">
                      {{ ` ${formatToIndianCurrency(allocation.grantLineItemAmount)} Left` }}
                    </p>
                    <InformationCircleIcon class="h-4 w-4 text-gray-600" />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col w-full border-b border-gray-200 px-3 py-2 gap-2 text-gray-800 sm-medium">
              <p>Purpose</p>
              <p>{{ allocation.purpose }}</p>
            </div>
            <div class="flex flex-col w-full px-3 py-2 gap-2 text-gray-800 sm-medium">
              <div class="flex gap-2">
                <AnnotationIcon class="h-6 w-6 text-gray-500" />
                <p class="text-gray-700">{{ allocation.notes }}</p>
              </div>
              <div class="flex gap-2 items-center">
                <DocumentIcon class="h-[28px] w-6 text-gray-500" />
                <p v-for="attachment in allocation.attachment" :key="attachment.id" class="text-gray-500">
                  {{ attachment.filename }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="min-w-[280px] max-w-[280px] flex flex-col gap-2 py-3 px-2 sm-medium">
          <div class="flex flex-col pb-2">
            <p class="text-gray-600">Allocated Amount</p>
            <p class="text-gray-800 text-xl">{{ formatToIndianCurrency(allocation.allocationAmount) }}</p>
          </div>
          <div v-if="allocation.allocationAmount - allocation.grantLineItemAmount > 0" class="flex gap-2 text-red-700">
            <ExclamationCircleIcon class="h-5 w-5 flex-shrink-0" />
            <p class="w-full">Exceeds grant line item allocation</p>
          </div>
          <div v-if="allocation.allocationAmount - allocation.budgetLineItemAmount > 0" class="flex gap-2 text-red-700">
            <ExclamationCircleIcon class="h-5 w-5 flex-shrink-0" />
            <p>Exceeds expense line item allocation</p>
          </div>
          <div class="flex flex-col gap-2 pt-2">
            <div class="flex flex-col gap-1 py-0.5">
              <p class="text-gray-500">FCRA Status</p>
              <p class="text-black">{{ allocation.accountInfo.bookOfAccount }}</p>
            </div>
            <div class="flex flex-col gap-1 py-0.5">
              <p class="text-gray-500">Ledger</p>
              <p class="text-black">{{ allocation.accountInfo.expenseLedger }}</p>
            </div>
            <div class="flex flex-col gap-1 py-0.5">
              <p class="text-gray-500">Party Ledger</p>
              <p class="text-black">{{ allocation.accountInfo.partyLedger }}</p>
            </div>
            <div class="flex flex-col gap-1 py-0.5">
              <p class="text-gray-500">TDS Ledger</p>
              <p class="text-black">{{ allocation.accountInfo.tdsLedger }}</p>
            </div>
          </div>
          <div class="flex justify-between border-t border-gray-200 pb-2 pt-1">
            <p class="text-gray-500">TDS Amount</p>
            <p class="text-black">{{ formatToIndianCurrency(allocation.accountInfo.tdsAmount) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
