<script setup lang="tsx">
  import CustomDropdown from '~/components/UI/Forms/CustomDropdown.vue';
  import CustomInput from '~/components/UI/Forms/CustomInput.vue';
  import RoleBasedButton from '~/components/UI/Others/RoleBasedButton.vue';
  import { get, post } from '~/services/donationScreen';
  import type { GetResponse, ResponseStatus, LedgerDrawer, PostResponse } from '~/types';
  import EditPasswordModal from '../../Modals/EditPasswordModal.vue';

  const props = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const route = useRoute();

  const action = computed(() => {
    return route.query.action as string;
  });

  const formRef = ref();
  const formState = reactive({
    ledgerName: '',
    ledgerType: '',
    booksOfAccount: ''
  });

  const isModalVisible = ref<boolean>(false);
  const editStatus = ref<ResponseStatus>('idle');

  const fetchData = async () => {
    try {
      const ledgerResponse = await get<GetResponse<LedgerDrawer>>(`v1/get_ledger/${props.identifier}`);
      if (ledgerResponse.data) {
        Object.assign(formState, {
          ledgerName: ledgerResponse?.data?.data?.selected_ledger?.ledger_name || '',
          ledgerType: ledgerResponse?.data?.data?.selected_ledger?.ledger_type || '',
          booksOfAccount: ledgerResponse?.data?.data?.selected_ledger?.is_fcra || ''
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const rules = {
    ledgerName: [
      {
        required: true,
        message: 'Ledger Name is required',
        trigger: 'submit'
      }
    ],
    ledgerType: [
      {
        required: true,
        message: 'Ledger Type is required',
        trigger: 'submit'
      }
    ],
    booksOfAccount: [
      {
        required: true,
        message: 'Books of Account is required',
        trigger: 'submit'
      }
    ]
  };

  const updateStateField = (value: string, stateKey: keyof typeof formState) => {
    (formState as any)[stateKey] = value;
  };

  const submitHandler = async (modalData: { password: string }) => {
    try {
      editStatus.value = 'pending';
      const response = await post<PostResponse<LedgerDrawer>>(`v1/ledger/${props.identifier}`, {
        data: {
          ledger_name: formState.ledgerName,
          ledger_type: formState.ledgerType,
          is_fcra: formState.booksOfAccount,
          password: modalData.password
        }
      });

      if (response.status === 401) {
        editStatus.value = 'error';
        return openFailedCustomNotification('Wrong Password!');
      }

      if (response.data.status === 'success') {
        openSuccessfulCustomNotification('Ledger Updated Successfully');
        isModalVisible.value = false;
        props.onClose();
      } else {
        openFailedCustomNotification('Failed to Update Ledger');
        editStatus.value = 'error';
      }
    } catch (error) {
      console.error(error);
      openFailedCustomNotification('Failed to Update Ledger');
      editStatus.value = 'error';
    }
  };

  const handleCreate = async () => {
    editStatus.value = 'pending';
    try {
      const response = await post<PostResponse<LedgerDrawer>>('v1/add_new_ledger', {
        data: {
          ledger_name: formState.ledgerName,
          ledger_type: formState.ledgerType,
          is_fcra: formState.booksOfAccount
        }
      });

      if (response.data.status === 'success') {
        openSuccessfulCustomNotification('Ledger Created Successfully');
        props.onClose();
        editStatus.value = 'success';
      } else {
        openFailedCustomNotification('Failed to Create Ledger');
        editStatus.value = 'error';
      }
    } catch (error) {
      console.error(error);
      openFailedCustomNotification('Failed to Create Ledger');
      editStatus.value = 'error';
    }
  };

  const handleCreateLedger = async () => {
    try {
      await formRef.value.validate();

      if (route.query.action === 'edit') {
        return openModal();
      }

      await handleCreate();
    } catch (error) {
      console.error(error);
    }
  };

  const openModal = async () => {
    try {
      const isFormValid = await formRef.value.validate();
      if (!isFormValid) throw new Error('Form validation failed!');

      isModalVisible.value = true;
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalCancel = () => {
    isModalVisible.value = false;
  };

  onMounted(() => {
    if (props.identifier) {
      fetchData();
    }
  });
</script>

<template>
  <a-drawer
    :open="route.query.drawerFrom === 'ledgers'"
    :title="
      action === 'edit' ? 'Update Ledger' : action === 'add' || action === 'duplicate' ? 'Add New Ledger' : 'Ledger'
    "
    width="750"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    @close="props.onClose"
  >
    <template #extra>
      <RoleBasedButton
        v-if="!props.identifier || action"
        component-name="addActionButton"
        active-class="py-2 px-3 bg-blue-600 hover:bg-blue-700 text-white rounded sm-medium duration-150 border border-blue-600"
        disabled-class="py-2 px-3 rounded sm-medium duration-150 !bg-gray-200 !shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_0px_#0000000F] border-none"
        :loading="editStatus === 'pending'"
        @click="handleCreateLedger"
      >
        {{ action === 'edit' ? 'Update Ledger' : 'Create Ledger' }}
      </RoleBasedButton>
    </template>
    <a-form
      ref="formRef"
      class="flex flex-col gap-4 p-3 setup-drawer-form"
      :model="formState"
      :rules="rules"
      :colon="false"
      :label-col="{ style: { width: '250px', textAlign: 'left' } }"
      hide-required-mark="true"
    >
      <CustomInput
        label-text="Ledger Name"
        name="ledgerName"
        :value="formState.ledgerName"
        state-key="ledgerName"
        :is-compulsory="true"
        :is-disabled="!action"
        @update:value="updateStateField"
      />
      <CustomDropdown
        :options="[
          { title: 'Bank / Cash / Receipt Ledger', value: 'cash-bank' },
          { title: 'Donation Ledger', value: 'income' },
          { title: 'Supplier / Vendor Ledger', value: 'creditor' },
          { title: 'Expense Ledger', value: 'expense' },
          { title: 'Fixed Asset Ledger', value: 'fixed_asset' },
          { title: 'TDS Ledger', value: 'tds' }
        ]"
        label-text="Ledger Type"
        name="ledgerType"
        :value="formState.ledgerType"
        state-key="ledgerType"
        :rule="rules.ledgerType"
        :is-compulsory="true"
        :disabled="!action"
        @update:value="updateStateField"
      />
      <CustomDropdown
        :options="[
          { title: 'FCRA Books', value: 'FCRA Books' },
          { title: 'Non-FCRA Books', value: 'Non-FCRA Books' }
        ]"
        label-text="Books of Account"
        name="booksOfAccount"
        :value="formState.booksOfAccount"
        state-key="booksOfAccount"
        :rule="rules.booksOfAccount"
        :is-compulsory="true"
        :disabled="!action"
        @update:value="updateStateField"
      />
    </a-form>
    <EditPasswordModal
      :visible="isModalVisible"
      :loading="editStatus === 'pending'"
      modal-title="Update Ledger"
      @update:cancel="handleModalCancel"
      @update:update="submitHandler"
    />
  </a-drawer>
</template>

<style>
  @import '~/assets/css/donationDrawer.css';

  .setup-drawer-form .ant-form-item {
    margin-bottom: 0px;
  }
</style>
