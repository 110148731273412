import { default as add_new_userLOi5pjF2oIMeta } from "/app/pages/admin/add_new_user.vue?macro=true";
import { default as audit_logs_listBjfpeD0RBCMeta } from "/app/pages/admin/audit_logs_list.vue?macro=true";
import { default as edit_user8uTq7fLF0fMeta } from "/app/pages/admin/edit_user.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as indexawW49VDMt6Meta } from "/app/pages/admin/users_list/index.vue?macro=true";
import { default as add_45new_45advancetO1BcpCArXMeta } from "/app/pages/advances/add-new-advance.vue?macro=true";
import { default as edit_45advanceaXkcuqmZvoMeta } from "/app/pages/advances/edit-advance.vue?macro=true";
import { default as indexxE7jqVeAcsMeta } from "/app/pages/advances/index.vue?macro=true";
import { default as all_45budget_45data_45export9HkUksfd3PMeta } from "/app/pages/all-budget-data-export.vue?macro=true";
import { default as all_45grants_45data_45export1dBGCwYocRMeta } from "/app/pages/all-grants-data-export.vue?macro=true";
import { default as budget_45analysisPPtC3HJ7bQMeta } from "/app/pages/budget-analysis.vue?macro=true";
import { default as add_45new_45budget43lAJn7aBrMeta } from "/app/pages/budgets/add-new-budget.vue?macro=true";
import { default as edit_45budgetSTZNH95xTcMeta } from "/app/pages/budgets/edit-budget.vue?macro=true";
import { default as indexI08es032vOMeta } from "/app/pages/budgets/index.vue?macro=true";
import { default as donation_45analysisxzPmRwH8SYMeta } from "/app/pages/donation-analysis.vue?macro=true";
import { default as donation_45reportroLvEVPWq1Meta } from "/app/pages/donation-report.vue?macro=true";
import { default as add_45new_45donationM7hsgjXqmiMeta } from "/app/pages/donations/add-new-donation.vue?macro=true";
import { default as edit_45donationUBjzYjweO7Meta } from "/app/pages/donations/edit-donation.vue?macro=true";
import { default as indexWUuiwNpZZeMeta } from "/app/pages/donations/index.vue?macro=true";
import { default as donor_45analysisdkV9KrQWldMeta } from "/app/pages/donor-analysis.vue?macro=true";
import { default as indexx5kczy2fnOMeta } from "/app/pages/donors/add-new-donor/index.vue?macro=true";
import { default as indexwytB7jBwBYMeta } from "/app/pages/donors/edit-donor/index.vue?macro=true";
import { default as indextvCs4IDz35Meta } from "/app/pages/donors/index.vue?macro=true";
import { default as expense_45analysisaKNcW1KW4uMeta } from "/app/pages/expense-analysis.vue?macro=true";
import { default as expense_45attachment_45reportAGZBHhXDi3Meta } from "/app/pages/expense-attachment-report.vue?macro=true";
import { default as expenses_45reportobnoGavIz3Meta } from "/app/pages/expenses-report.vue?macro=true";
import { default as add_45new_45expenseahqKlNw7LIMeta } from "/app/pages/expenses/add-new-expense.vue?macro=true";
import { default as edit_45expensej9PUYYR5EtMeta } from "/app/pages/expenses/edit-expense.vue?macro=true";
import { default as indexqQwwGsgaUsMeta } from "/app/pages/expenses/index.vue?macro=true";
import { default as indexbaVB2GpKMcMeta } from "/app/pages/forbidden/index.vue?macro=true";
import { default as no_45subscriptionbs7nO5lsIwMeta } from "/app/pages/forbidden/no-subscription.vue?macro=true";
import { default as indexnol22AXlVtMeta } from "/app/pages/forgot-password/index.vue?macro=true";
import { default as fund_45balance_45reportolgnJNVUCJMeta } from "/app/pages/fund-balance-report.vue?macro=true";
import { default as grant_45analysistEoMMdiz81Meta } from "/app/pages/grant-analysis.vue?macro=true";
import { default as grant_45utilization_45analysisr5k5xDI9rmMeta } from "/app/pages/grant-utilization-analysis.vue?macro=true";
import { default as grant_45utilization_45reportBpBVuIi5fKMeta } from "/app/pages/grant-utilization-report.vue?macro=true";
import { default as add_45new_45grantmzQDIYVJCTMeta } from "/app/pages/grants/add-new-grant.vue?macro=true";
import { default as edit_45grantIggDH7BDuGMeta } from "/app/pages/grants/edit-grant.vue?macro=true";
import { default as indexU496UweDhTMeta } from "/app/pages/grants/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as organization_45analysisYFmTu1luHeMeta } from "/app/pages/organization-analysis.vue?macro=true";
import { default as payee_45analysisd8WNxSCRXwMeta } from "/app/pages/payee-analysis.vue?macro=true";
import { default as payee_45expense_45reportcAa5AxWiR0Meta } from "/app/pages/payee-expense-report.vue?macro=true";
import { default as payment_45reporthKVE8dPBuUMeta } from "/app/pages/payment-report.vue?macro=true";
import { default as pledges_45analysiscPriBEhYNmMeta } from "/app/pages/pledges-analysis.vue?macro=true";
import { default as add_45new_45pledgeSAq8LMFyGlMeta } from "/app/pages/pledges/add-new-pledge.vue?macro=true";
import { default as edit_45pledgeGSWan884g4Meta } from "/app/pages/pledges/edit-pledge.vue?macro=true";
import { default as indexs6x39PndESMeta } from "/app/pages/pledges/index.vue?macro=true";
import { default as purpose_45analysis4RoAvh62woMeta } from "/app/pages/purpose-analysis.vue?macro=true";
import { default as add_45new_45recurring_45expenseuncPAPd56hMeta } from "/app/pages/recurring-expenses/add-new-recurring-expense.vue?macro=true";
import { default as edit_45recurring_45expenseKNK7p6Z1bOMeta } from "/app/pages/recurring-expenses/edit-recurring-expense.vue?macro=true";
import { default as indexT2X91Gg85cMeta } from "/app/pages/recurring-expenses/index.vue?macro=true";
import { default as indexfnbdhqPUvqMeta } from "/app/pages/reset-password/index.vue?macro=true";
import { default as accounting_periodsKR2ZIDi4tiMeta } from "/app/pages/setup/accounting_periods.vue?macro=true";
import { default as cost_centres0vOhpTtlLPMeta } from "/app/pages/setup/cost_centres.vue?macro=true";
import { default as index6ox58Fk0byMeta } from "/app/pages/setup/index.vue?macro=true";
import { default as ledgersGPiFLAHsr7Meta } from "/app/pages/setup/ledgers.vue?macro=true";
import { default as payee_typesh23IBrMukkMeta } from "/app/pages/setup/payee_types.vue?macro=true";
import { default as purposesuAScauOwHJMeta } from "/app/pages/setup/purposes.vue?macro=true";
import { default as stock_itemsTZINHhVDwRMeta } from "/app/pages/setup/stock_items.vue?macro=true";
import { default as indexrVEAE3qysDMeta } from "/app/pages/support/index.vue?macro=true";
import { default as user_45vs_45grants2iGLOvYN6KMeta } from "/app/pages/user-vs-grants.vue?macro=true";
import { default as _10be_emailerqybfLUL6swMeta } from "/app/pages/utilities/10be_emailer.vue?macro=true";
import { default as download_formatssRjEOoLPA8Meta } from "/app/pages/utilities/download_formats.vue?macro=true";
import { default as expense_reconciliation_statusaC4g7nGlv9Meta } from "/app/pages/utilities/expense_reconciliation_status.vue?macro=true";
import { default as expense_reconciliationf311jQQMmwMeta } from "/app/pages/utilities/expense_reconciliation.vue?macro=true";
import { default as export_tally_expense2XdAoHHBdGMeta } from "/app/pages/utilities/export_tally_expense.vue?macro=true";
import { default as export_tally_incomeHFXFbXZSeOMeta } from "/app/pages/utilities/export_tally_income.vue?macro=true";
import { default as import_ledgersaNllj7F08EMeta } from "/app/pages/utilities/import_ledgers.vue?macro=true";
import { default as import_paymentsNlDkt2tvZOMeta } from "/app/pages/utilities/import_payments.vue?macro=true";
import { default as indexEzEw8CXtVOMeta } from "/app/pages/utilities/index.vue?macro=true";
import { default as statutory_reportsmeqeQ8mYQAMeta } from "/app/pages/utilities/statutory_reports.vue?macro=true";
import { default as add_45new_45vendorL0g9HIGtXrMeta } from "/app/pages/vendors/add-new-vendor.vue?macro=true";
import { default as edit_45vendor2s9xYMKWo7Meta } from "/app/pages/vendors/edit-vendor.vue?macro=true";
import { default as indexJEJnh0nyRrMeta } from "/app/pages/vendors/index.vue?macro=true";
export default [
  {
    name: "admin-add_new_user",
    path: "/admin/add_new_user",
    meta: add_new_userLOi5pjF2oIMeta || {},
    component: () => import("/app/pages/admin/add_new_user.vue").then(m => m.default || m)
  },
  {
    name: "admin-audit_logs_list",
    path: "/admin/audit_logs_list",
    meta: audit_logs_listBjfpeD0RBCMeta || {},
    component: () => import("/app/pages/admin/audit_logs_list.vue").then(m => m.default || m)
  },
  {
    name: "admin-edit_user",
    path: "/admin/edit_user",
    meta: edit_user8uTq7fLF0fMeta || {},
    component: () => import("/app/pages/admin/edit_user.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexi8nCATZISJMeta || {},
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users_list",
    path: "/admin/users_list",
    meta: indexawW49VDMt6Meta || {},
    component: () => import("/app/pages/admin/users_list/index.vue").then(m => m.default || m)
  },
  {
    name: "advances-add-new-advance",
    path: "/advances/add-new-advance",
    component: () => import("/app/pages/advances/add-new-advance.vue").then(m => m.default || m)
  },
  {
    name: "advances-edit-advance",
    path: "/advances/edit-advance",
    component: () => import("/app/pages/advances/edit-advance.vue").then(m => m.default || m)
  },
  {
    name: "advances",
    path: "/advances",
    component: () => import("/app/pages/advances/index.vue").then(m => m.default || m)
  },
  {
    name: "all-budget-data-export",
    path: "/all-budget-data-export",
    meta: all_45budget_45data_45export9HkUksfd3PMeta || {},
    component: () => import("/app/pages/all-budget-data-export.vue").then(m => m.default || m)
  },
  {
    name: "all-grants-data-export",
    path: "/all-grants-data-export",
    meta: all_45grants_45data_45export1dBGCwYocRMeta || {},
    component: () => import("/app/pages/all-grants-data-export.vue").then(m => m.default || m)
  },
  {
    name: "budget-analysis",
    path: "/budget-analysis",
    meta: budget_45analysisPPtC3HJ7bQMeta || {},
    component: () => import("/app/pages/budget-analysis.vue").then(m => m.default || m)
  },
  {
    name: "budgets-add-new-budget",
    path: "/budgets/add-new-budget",
    component: () => import("/app/pages/budgets/add-new-budget.vue").then(m => m.default || m)
  },
  {
    name: "budgets-edit-budget",
    path: "/budgets/edit-budget",
    component: () => import("/app/pages/budgets/edit-budget.vue").then(m => m.default || m)
  },
  {
    name: "budgets",
    path: "/budgets",
    component: () => import("/app/pages/budgets/index.vue").then(m => m.default || m)
  },
  {
    name: "donation-analysis",
    path: "/donation-analysis",
    meta: donation_45analysisxzPmRwH8SYMeta || {},
    component: () => import("/app/pages/donation-analysis.vue").then(m => m.default || m)
  },
  {
    name: "donation-report",
    path: "/donation-report",
    meta: donation_45reportroLvEVPWq1Meta || {},
    component: () => import("/app/pages/donation-report.vue").then(m => m.default || m)
  },
  {
    name: "donations-add-new-donation",
    path: "/donations/add-new-donation",
    component: () => import("/app/pages/donations/add-new-donation.vue").then(m => m.default || m)
  },
  {
    name: "donations-edit-donation",
    path: "/donations/edit-donation",
    component: () => import("/app/pages/donations/edit-donation.vue").then(m => m.default || m)
  },
  {
    name: "donations",
    path: "/donations",
    component: () => import("/app/pages/donations/index.vue").then(m => m.default || m)
  },
  {
    name: "donor-analysis",
    path: "/donor-analysis",
    meta: donor_45analysisdkV9KrQWldMeta || {},
    component: () => import("/app/pages/donor-analysis.vue").then(m => m.default || m)
  },
  {
    name: "donors-add-new-donor",
    path: "/donors/add-new-donor",
    component: () => import("/app/pages/donors/add-new-donor/index.vue").then(m => m.default || m)
  },
  {
    name: "donors-edit-donor",
    path: "/donors/edit-donor",
    component: () => import("/app/pages/donors/edit-donor/index.vue").then(m => m.default || m)
  },
  {
    name: "donors",
    path: "/donors",
    component: () => import("/app/pages/donors/index.vue").then(m => m.default || m)
  },
  {
    name: "expense-analysis",
    path: "/expense-analysis",
    meta: expense_45analysisaKNcW1KW4uMeta || {},
    component: () => import("/app/pages/expense-analysis.vue").then(m => m.default || m)
  },
  {
    name: "expense-attachment-report",
    path: "/expense-attachment-report",
    meta: expense_45attachment_45reportAGZBHhXDi3Meta || {},
    component: () => import("/app/pages/expense-attachment-report.vue").then(m => m.default || m)
  },
  {
    name: "expenses-report",
    path: "/expenses-report",
    meta: expenses_45reportobnoGavIz3Meta || {},
    component: () => import("/app/pages/expenses-report.vue").then(m => m.default || m)
  },
  {
    name: "expenses-add-new-expense",
    path: "/expenses/add-new-expense",
    component: () => import("/app/pages/expenses/add-new-expense.vue").then(m => m.default || m)
  },
  {
    name: "expenses-edit-expense",
    path: "/expenses/edit-expense",
    component: () => import("/app/pages/expenses/edit-expense.vue").then(m => m.default || m)
  },
  {
    name: "expenses",
    path: "/expenses",
    component: () => import("/app/pages/expenses/index.vue").then(m => m.default || m)
  },
  {
    name: "forbidden",
    path: "/forbidden",
    component: () => import("/app/pages/forbidden/index.vue").then(m => m.default || m)
  },
  {
    name: "forbidden-no-subscription",
    path: "/forbidden/no-subscription",
    component: () => import("/app/pages/forbidden/no-subscription.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: indexnol22AXlVtMeta || {},
    component: () => import("/app/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: "fund-balance-report",
    path: "/fund-balance-report",
    meta: fund_45balance_45reportolgnJNVUCJMeta || {},
    component: () => import("/app/pages/fund-balance-report.vue").then(m => m.default || m)
  },
  {
    name: "grant-analysis",
    path: "/grant-analysis",
    meta: grant_45analysistEoMMdiz81Meta || {},
    component: () => import("/app/pages/grant-analysis.vue").then(m => m.default || m)
  },
  {
    name: "grant-utilization-analysis",
    path: "/grant-utilization-analysis",
    meta: grant_45utilization_45analysisr5k5xDI9rmMeta || {},
    component: () => import("/app/pages/grant-utilization-analysis.vue").then(m => m.default || m)
  },
  {
    name: "grant-utilization-report",
    path: "/grant-utilization-report",
    meta: grant_45utilization_45reportBpBVuIi5fKMeta || {},
    component: () => import("/app/pages/grant-utilization-report.vue").then(m => m.default || m)
  },
  {
    name: "grants-add-new-grant",
    path: "/grants/add-new-grant",
    component: () => import("/app/pages/grants/add-new-grant.vue").then(m => m.default || m)
  },
  {
    name: "grants-edit-grant",
    path: "/grants/edit-grant",
    component: () => import("/app/pages/grants/edit-grant.vue").then(m => m.default || m)
  },
  {
    name: "grants",
    path: "/grants",
    component: () => import("/app/pages/grants/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "organization-analysis",
    path: "/organization-analysis",
    meta: organization_45analysisYFmTu1luHeMeta || {},
    component: () => import("/app/pages/organization-analysis.vue").then(m => m.default || m)
  },
  {
    name: "payee-analysis",
    path: "/payee-analysis",
    meta: payee_45analysisd8WNxSCRXwMeta || {},
    component: () => import("/app/pages/payee-analysis.vue").then(m => m.default || m)
  },
  {
    name: "payee-expense-report",
    path: "/payee-expense-report",
    meta: payee_45expense_45reportcAa5AxWiR0Meta || {},
    component: () => import("/app/pages/payee-expense-report.vue").then(m => m.default || m)
  },
  {
    name: "payment-report",
    path: "/payment-report",
    meta: payment_45reporthKVE8dPBuUMeta || {},
    component: () => import("/app/pages/payment-report.vue").then(m => m.default || m)
  },
  {
    name: "pledges-analysis",
    path: "/pledges-analysis",
    meta: pledges_45analysiscPriBEhYNmMeta || {},
    component: () => import("/app/pages/pledges-analysis.vue").then(m => m.default || m)
  },
  {
    name: "pledges-add-new-pledge",
    path: "/pledges/add-new-pledge",
    component: () => import("/app/pages/pledges/add-new-pledge.vue").then(m => m.default || m)
  },
  {
    name: "pledges-edit-pledge",
    path: "/pledges/edit-pledge",
    component: () => import("/app/pages/pledges/edit-pledge.vue").then(m => m.default || m)
  },
  {
    name: "pledges",
    path: "/pledges",
    component: () => import("/app/pages/pledges/index.vue").then(m => m.default || m)
  },
  {
    name: "purpose-analysis",
    path: "/purpose-analysis",
    meta: purpose_45analysis4RoAvh62woMeta || {},
    component: () => import("/app/pages/purpose-analysis.vue").then(m => m.default || m)
  },
  {
    name: "recurring-expenses-add-new-recurring-expense",
    path: "/recurring-expenses/add-new-recurring-expense",
    component: () => import("/app/pages/recurring-expenses/add-new-recurring-expense.vue").then(m => m.default || m)
  },
  {
    name: "recurring-expenses-edit-recurring-expense",
    path: "/recurring-expenses/edit-recurring-expense",
    component: () => import("/app/pages/recurring-expenses/edit-recurring-expense.vue").then(m => m.default || m)
  },
  {
    name: "recurring-expenses",
    path: "/recurring-expenses",
    component: () => import("/app/pages/recurring-expenses/index.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: indexfnbdhqPUvqMeta || {},
    component: () => import("/app/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "setup-accounting_periods",
    path: "/setup/accounting_periods",
    meta: accounting_periodsKR2ZIDi4tiMeta || {},
    component: () => import("/app/pages/setup/accounting_periods.vue").then(m => m.default || m)
  },
  {
    name: "setup-cost_centres",
    path: "/setup/cost_centres",
    meta: cost_centres0vOhpTtlLPMeta || {},
    component: () => import("/app/pages/setup/cost_centres.vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "/setup",
    meta: index6ox58Fk0byMeta || {},
    component: () => import("/app/pages/setup/index.vue").then(m => m.default || m)
  },
  {
    name: "setup-ledgers",
    path: "/setup/ledgers",
    meta: ledgersGPiFLAHsr7Meta || {},
    component: () => import("/app/pages/setup/ledgers.vue").then(m => m.default || m)
  },
  {
    name: "setup-payee_types",
    path: "/setup/payee_types",
    meta: payee_typesh23IBrMukkMeta || {},
    component: () => import("/app/pages/setup/payee_types.vue").then(m => m.default || m)
  },
  {
    name: "setup-purposes",
    path: "/setup/purposes",
    meta: purposesuAScauOwHJMeta || {},
    component: () => import("/app/pages/setup/purposes.vue").then(m => m.default || m)
  },
  {
    name: "setup-stock_items",
    path: "/setup/stock_items",
    meta: stock_itemsTZINHhVDwRMeta || {},
    component: () => import("/app/pages/setup/stock_items.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/app/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "user-vs-grants",
    path: "/user-vs-grants",
    meta: user_45vs_45grants2iGLOvYN6KMeta || {},
    component: () => import("/app/pages/user-vs-grants.vue").then(m => m.default || m)
  },
  {
    name: "utilities-10be_emailer",
    path: "/utilities/10be_emailer",
    meta: _10be_emailerqybfLUL6swMeta || {},
    component: () => import("/app/pages/utilities/10be_emailer.vue").then(m => m.default || m)
  },
  {
    name: "utilities-download_formats",
    path: "/utilities/download_formats",
    meta: download_formatssRjEOoLPA8Meta || {},
    component: () => import("/app/pages/utilities/download_formats.vue").then(m => m.default || m)
  },
  {
    name: "utilities-expense_reconciliation_status",
    path: "/utilities/expense_reconciliation_status",
    meta: expense_reconciliation_statusaC4g7nGlv9Meta || {},
    component: () => import("/app/pages/utilities/expense_reconciliation_status.vue").then(m => m.default || m)
  },
  {
    name: "utilities-expense_reconciliation",
    path: "/utilities/expense_reconciliation",
    meta: expense_reconciliationf311jQQMmwMeta || {},
    component: () => import("/app/pages/utilities/expense_reconciliation.vue").then(m => m.default || m)
  },
  {
    name: "utilities-export_tally_expense",
    path: "/utilities/export_tally_expense",
    meta: export_tally_expense2XdAoHHBdGMeta || {},
    component: () => import("/app/pages/utilities/export_tally_expense.vue").then(m => m.default || m)
  },
  {
    name: "utilities-export_tally_income",
    path: "/utilities/export_tally_income",
    meta: export_tally_incomeHFXFbXZSeOMeta || {},
    component: () => import("/app/pages/utilities/export_tally_income.vue").then(m => m.default || m)
  },
  {
    name: "utilities-import_ledgers",
    path: "/utilities/import_ledgers",
    meta: import_ledgersaNllj7F08EMeta || {},
    component: () => import("/app/pages/utilities/import_ledgers.vue").then(m => m.default || m)
  },
  {
    name: "utilities-import_payments",
    path: "/utilities/import_payments",
    meta: import_paymentsNlDkt2tvZOMeta || {},
    component: () => import("/app/pages/utilities/import_payments.vue").then(m => m.default || m)
  },
  {
    name: "utilities",
    path: "/utilities",
    meta: indexEzEw8CXtVOMeta || {},
    component: () => import("/app/pages/utilities/index.vue").then(m => m.default || m)
  },
  {
    name: "utilities-statutory_reports",
    path: "/utilities/statutory_reports",
    meta: statutory_reportsmeqeQ8mYQAMeta || {},
    component: () => import("/app/pages/utilities/statutory_reports.vue").then(m => m.default || m)
  },
  {
    name: "vendors-add-new-vendor",
    path: "/vendors/add-new-vendor",
    component: () => import("/app/pages/vendors/add-new-vendor.vue").then(m => m.default || m)
  },
  {
    name: "vendors-edit-vendor",
    path: "/vendors/edit-vendor",
    component: () => import("/app/pages/vendors/edit-vendor.vue").then(m => m.default || m)
  },
  {
    name: "vendors",
    path: "/vendors",
    component: () => import("/app/pages/vendors/index.vue").then(m => m.default || m)
  }
]