import dayjs from 'dayjs';
import { formatToIndianCurrency } from '~/functions/currencyUtilities';
import { toDateTime } from '~/functions/dateTimeUtilities';

export const paymentModes = [
  { title: 'Cash', value: 'Cash' },
  { title: 'Cheque', value: 'Cheque' },
  { title: 'Bank Transfer', value: 'Bank Transfer' }
];

export const bookOfAccounts = [
  { title: 'FCRA', value: 'FCRA' },
  { title: 'Non-FCRA', value: 'Non-FCRA' }
];

export const updatePaymentFormData = (data: any) => {
  const formData: any = {};

  data.forEach((item: any, index: number) => {
    const id = index + 1;
    formData[id] = {
      [`payment_date_${id}`]: dayjs(item.paymentDate).format('YYYY-MM-DD'),
      [`payment_mode_${id}`]: item.paymentMode,
      [`payment_fcra_status_${id}`]: item.bookOfAccounts,
      [`payment_ref_${id}`]: item.referenceNumber,
      [`payment_amount_${id}`]: item.amount,
      [`advance_uid_${id}`]: item.isAdvance ? item.advance : '',
      [`advance_payment_li_${id}`]: item.isAdvance ? item.advancePaymentId : ''
    };
  });
  return formData;
};

const ifNotNAReturnIndianFormat = (value: string) => {
  if (value !== 'N.A.') {
    return formatToIndianCurrency(+value);
  } else {
    return value;
  }
};

export function sumConditional<T>(
  arr: T[],
  predicate: (item: T) => boolean,
  valueExtractor: (item: T) => number
): number {
  return arr.reduce((acc, item) => (predicate(item) ? acc + valueExtractor(item) : acc), 0);
}

export const getPaymentSummary = (lineItems: any) => {
  const expDetails: any = {};

  expDetails.totalExpenseAllocationNonFcra = sumConditional(
    Object.values(lineItems),
    (item: any) => item.li_fcra_status === 'Non-FCRA',
    (item: any) => +item.li_amount
  );
  expDetails.totalExpenseAllocationFcra = sumConditional(
    Object.values(lineItems),
    (item: any) => item.li_fcra_status === 'FCRA',
    (item: any) => +item.li_amount
  );

  expDetails.totalUnallocatedTotalAmount = sumConditional(
    Object.values(lineItems),
    (item: any) => item.li_grant_id === 'Unallocated',
    (item: any) => +item.li_amount
  );
  expDetails.totalExpenseAllocation =
    expDetails.totalExpenseAllocationNonFcra +
    expDetails.totalExpenseAllocationFcra +
    expDetails.totalUnallocatedTotalAmount;

  expDetails.tdsNonFcra = sumConditional(
    Object.values(lineItems),
    (item: any) => item.li_fcra_status === 'Non-FCRA',
    (item: any) => +item.li_tds_amount
  );
  expDetails.tdsFcra = sumConditional(
    Object.values(lineItems),
    (item: any) => item.li_fcra_status === 'FCRA',
    (item: any) => +item.li_tds_amount
  );
  expDetails.unallocatedTdsAmount = sumConditional(
    Object.values(lineItems),
    (item: any) => item.li_grant_id === 'Unallocated',
    (item: any) => +item.li_tds_amount
  );
  expDetails.totalTdsAmount = expDetails.tdsNonFcra + expDetails.tdsFcra + expDetails.unallocatedTdsAmount;

  expDetails.payableNetOfTdsNonFcra = expDetails.totalExpenseAllocationNonFcra - expDetails.tdsNonFcra;
  expDetails.payableNetOfTdsFcra = expDetails.totalExpenseAllocationFcra - expDetails.tdsFcra;
  expDetails.payableNetOfTdsUnallocated = expDetails.totalUnallocatedTotalAmount - expDetails.unallocatedTdsAmount;
  expDetails.payableNetOfTds =
    expDetails.payableNetOfTdsNonFcra + expDetails.payableNetOfTdsFcra + expDetails.payableNetOfTdsUnallocated;

  return expDetails;
};

export const getPaymentsDetails = (payments: any) =>
  payments?.map((item: any, index: number) => ({
    id: Date.now() + index,
    advance: item.advance_uid,
    advancePaymentId: item.advance_payment_li,
    paymentDate: item.payment_date ? toDateTime(item.payment_date) : '',
    paymentMode: item.payment_mode,
    referenceNumber: item.payment_ref,
    bookOfAccounts: item.payment_fcra_status,
    amount: item.payment_amount,
    amountPaid: null,
    outstanding: null,
    isAdvance: item.advance_uid ? true : false,
    error: ''
  }));

export const parseFormDataToState = (formData: Record<string, any>) => {
  const expDetails: any = {
    totalAmountPaid:
      formData.payment_stats.amount_paid !== 'Not Paid'
        ? formatToIndianCurrency(+formData.payment_stats.amount_paid)
        : formData.payment_stats.amount_paid,
    balancePayments: ifNotNAReturnIndianFormat(formData.payment_stats.balance_payments),
    balanceForFCRA: ifNotNAReturnIndianFormat(formData.payment_stats.balance_for_fcra),
    balanceForNonFCRA: ifNotNAReturnIndianFormat(formData.payment_stats.balance_for_non_fcra),
    remainingToBeAllocated: formatToIndianCurrency(formData.payment_stats.remaining_to_be_allocated),
    totalInvoiceAmount: formData.amount,
    auditLog: formData.audit_logs
  };

  expDetails.grantsAndBudgetAllocation = formData.line_items.map((item: any, index: number) => ({
    key: Date.now() + index,
    expenseBudget: item.li_budget,
    expenseLineItem: item.li_budget_li,
    expenseGrants: item.li_grant_id,
    grantsLineItem: item.li_grant_li,
    purpose: item.li_purpose,
    allocationAmount: +item.li_amount,
    notes: item.li_note,
    isEditing: false,
    isReadMore: false,
    validationError: '',
    attachment: [],
    accountInfo: {
      bookOfAccount: item.li_fcra_status,
      expenseLedger: item.li_ledger,
      partyLedger: item.li_party_ledger_name,
      tdsLedger: item.li_tds_ledger_name,
      tdsAmount: +item.li_tds_amount
    }
  }));

  const paymentSummary = getPaymentSummary(formData.line_items);

  Object.assign(expDetails, {
    totalExpenseAllocationNonFcra: paymentSummary.totalExpenseAllocationNonFcra,
    totalExpenseAllocationFcra: paymentSummary.totalExpenseAllocationFcra,
    totalUnallocatedTotalAmount: paymentSummary.totalUnallocatedTotalAmount,
    totalTdsAmount: paymentSummary.totalTdsAmount,
    payableNetOfTdsNonFcra: paymentSummary.payableNetOfTdsNonFcra,
    payableNetOfTdsFcra: paymentSummary.payableNetOfTdsFcra,
    payableNetOfTdsUnallocated: paymentSummary.payableNetOfTdsUnallocated,
    payableNetOfTds: paymentSummary.payableNetOfTds,
    tdsFcra: paymentSummary.tdsFcra,
    tdsNonFcra: paymentSummary.tdsNonFcra,
    totalExpenseAllocation: paymentSummary.totalExpenseAllocation,
    unallocatedTdsAmount: paymentSummary.unallocatedTdsAmount
  });

  const payments = getPaymentsDetails(formData.payments);

  return { expDetails, payments };
};
