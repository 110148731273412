<script setup lang="ts">
  import { post } from '~/services/donationScreen';
  import AsteriskText from '../Forms/AsteriskText.vue';
  import type { ExpenseOperationResponse, ResponseStatus } from '~/types';

  const props = defineProps<{
    visible: boolean;
    close: () => void;
    expenseId: string;
  }>();

  const modalState = reactive<{
    approvalNote: string;
    password: string;
  }>({
    approvalNote: '',
    password: ''
  });

  const loading = ref<ResponseStatus>('idle');
  const router = useRouter();

  const updateModalRef = ref();
  const updateModalRules = {
    password: [{ required: true, message: 'Password is required!', trigger: 'submit' }]
  };

  const handleModalOk = async () => {
    loading.value = 'pending';
    try {
      const isValid = await updateModalRef.value.validate();
      if (isValid) {
        const response = await post<ExpenseOperationResponse>('/v1/approve_expense', {
          data: {
            uid: props.expenseId,
            approval_note: modalState.approvalNote,
            edit_password: modalState.password
          }
        });
        if (response?.data?.status === 'success') {
          loading.value = 'success';
          router.push(`/expenses?drawerFrom=allExpenses&identifier=${props.expenseId}`);
        }
      }
    } catch (error) {
      openFailedCustomNotification('Something went wrong while approving the expense!');
      console.error(error);
    }
  };
</script>

<template>
  <a-modal
    :visible="props.visible"
    title="Approve Expense"
    class="account-info-modal"
    ok-text="Approve"
    @ok="handleModalOk"
    @cancel="props.close"
  >
    <a-form
      ref="updateModalRef"
      layout="vertical"
      label-align="left"
      class="flex flex-col gap-3"
      :label-col="{ style: { width: '190px' } }"
      :colon="false"
      :rules="updateModalRules"
      :model="modalState"
      :hide-required-mark="true"
    >
      <p class="text-sm font-medium">Are you sure you want to approve this expense?</p>
      <a-form-item name="approvalNote" class="!sm-medium">
        <template #label>
          <p>Comment on Approval</p>
        </template>
        <a-textarea v-model:value="modalState.approvalNote" class="w-[547px]" :rows="3" />
      </a-form-item>
      <a-form-item name="password" class="!sm-medium">
        <template #label>
          <AsteriskText label="Edit Password" />
        </template>
        <a-input v-model:value="modalState.password" type="password" class="w-[248px]" />
      </a-form-item>
    </a-form>
    <template #footer>
      <div class="flex gap-1 w-full justify-end border-t border-gray-300 p-3 bg-gray-50 rounded-b-lg">
        <a-button
          :disabled="loading === 'pending'"
          class="rounded text-sm font-medium border border-gray-300 text-gray-500 px-3 py-1.5"
          @click="props.close"
          >Cancel</a-button
        >
        <a-button
          :disabled="loading === 'pending'"
          class="px-3 border !border-green-700 hover:!bg-green-600 bg-green-700 !text-white rounded py-1.5 flex items-center justify-center text-sm font-medium"
          type="primary"
          @click="handleModalOk"
          >Approve</a-button
        >
      </div>
    </template>
  </a-modal>
</template>

<style scoped>
  @import '~/assets/css/modal.css';

  .ant-form-item {
    margin-bottom: 0px !important;
  }
</style>
