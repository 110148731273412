<script setup lang="tsx">
  import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/vue/solid';
  import { formatToIndianCurrency } from '~/functions/currencyUtilities';
  import { checkValidDate, formatDate } from '~/functions/dateTimeUtilities';
  import { del, get } from '~/services/donationScreen';
  import type { DeleteResponse, DonorDrawerType, GetResponse, ResponseStatus } from '~/types';
  import Attachments from '../../Others/Attachments.vue';
  import AttachmentUpdates from '../RoutedDrawerComponents/AttachmentUpdates.vue';
  import InfoRow from '~/components/UI/Drawers/InfoRow.vue';
  import InfoSection from '~/components/UI/Drawers/InfoSection.vue';
  import DrawerLayout from '~/components/UI/Drawers/DrawerLayout.vue';
  import OverviewCard from '~/components/UI/Others/OverviewCard.vue';
  import { checkValueAndLength } from '~/functions/stringUtilities';
  import AuditLogs from '../RoutedDrawerComponents/AuditLogs.vue';
  import RoleBasedLinkButton from '~/components/UI/Others/RoleBasedLinkButton.vue';
  import { checkPermission } from '~/utils/auth/buttonController';
  import InformationCircle from '~/components/UI/Others/InformationCircle.vue';
  import RoleBasedButton from '~/components/UI/Others/RoleBasedButton.vue';
  import EditPasswordModal from '../../Modals/EditPasswordModal.vue';

  const { onClose, identifier } = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const route = useRoute();

  const data = ref<DonorDrawerType>({} as DonorDrawerType);
  const responseState = ref<ResponseStatus>('idle');
  const isEditPasswordModalOpen = ref<boolean>(false);
  const deleteDonorStatus = ref<ResponseStatus>('idle');

  const expiredPledgePath = computed(() => {
    const today = new Date();
    return {
      path: '/pledges',
      query: {
        page: 1,
        donor_uid: identifier,
        end_date: `1970-01-01T18:30:00.000Z,${today.toISOString()}`
      }
    };
  });

  const fetchData = async () => {
    try {
      const response = await get<GetResponse<DonorDrawerType>>(`v1/get_donor/${identifier}`);

      if (response.data.status === 'success') {
        data.value = response.data.data;
        responseState.value = 'success';
      } else {
        responseState.value = 'error';
      }
    } catch (error: any) {
      openFailedCustomNotification('Something went wrong while fetching donor data!');
      responseState.value = 'error';
      console.error('Error fetching data:', error);
    }
  };

  const deleteDonor = async ({ password }: { password: string }) => {
    try {
      deleteDonorStatus.value = 'pending';
      const response = await del<DeleteResponse<string>>(`v1/donors/${identifier}`, {
        data: { data: { password } }
      });

      if (response.data.status === 'error') {
        deleteDonorStatus.value = 'error';
        openFailedCustomNotification(response.data.message);
      }
      if (response.data.status === 'success') {
        deleteDonorStatus.value = 'success';
        isEditPasswordModalOpen.value = false;
        openSuccessfulCustomNotification('Donor deleted successfully');
        onClose();
      }
    } catch (error) {
      deleteDonorStatus.value = 'error';
      console.error('Error deleting donor:', error);
    }
  };

  const showEditPasswordModal = () => {
    isEditPasswordModalOpen.value = true;
  };

  onMounted(fetchData);
</script>

<template>
  <a-drawer
    v-if="identifier"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    title="Donor Information"
    :open="identifier !== null"
    width="1050"
    @close="onClose"
  >
    <template #extra>
      <div class="flex gap-2">
        <RoleBasedButton
          component-name="deleteActionButton"
          :disabled="false"
          active-class="text-blue-700 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center text-gray-500 border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          disabled-class="flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          @click="showEditPasswordModal"
        >
          <TrashIcon class="h-4 w-4 text-red-700" />
          Delete Donor
        </RoleBasedButton>
        <RoleBasedLinkButton
          v-if="checkPermission(route.path, 'viewActionButton')"
          :to="{ path: '/donors/edit-donor', query: { identifier } }"
          component-name="headerPrimaryButton"
          active-class="text-blue-700 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center text-gray-500 border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          disabled-class="flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
        >
          <PencilIcon class="h-4 w-4" />
          Edit Donor
        </RoleBasedLinkButton>
      </div>
    </template>
    <DrawerLayout
      :response-state="responseState"
      :main-display="data?.selected_donor?.name"
      :sub-display="data?.selected_donor?.donor_type"
      :side-display="''"
      @close="onClose"
    >
      <template #column1>
        <InfoSection>
          <InfoRow label="PAN" :value="checkValueAndLength(data?.selected_donor?.pan)" />
          <InfoRow
            :label="
              data?.selected_donor?.aid_type?.length > 0
                ? data?.selected_donor?.aid_type
                : 'Alternate Identification ID'
            "
            :value="checkValueAndLength(data?.selected_donor?.aid_ref)"
          />
        </InfoSection>
        <InfoSection>
          <InfoRow label="Email" :value="checkValueAndLength(data?.selected_donor?.email)" />
          <InfoRow label="Phone Number" :value="checkValueAndLength(data?.selected_donor?.phone)" />
          <InfoRow label="Address" :value="checkValueAndLength(data?.selected_donor?.address)" />
          <InfoRow label="Pincode" :value="checkValueAndLength(data?.selected_donor?.pin_code)" />
          <InfoRow label="City" :value="checkValueAndLength(data?.selected_donor?.city)" />
          <InfoRow label="Country" :value="checkValueAndLength(data?.selected_donor?.country)" />
        </InfoSection>
        <InfoSection>
          <InfoRow label="FCRA" :value="checkValueAndLength(data?.selected_donor?.is_fcra)" />
          <InfoRow label="Grants Enabled" :value="checkValueAndLength(data?.selected_donor?.enable_grants)" />
          <InfoRow label="KYC Status" :value="checkValueAndLength(data?.selected_donor?.kyc_completed)" />
        </InfoSection>
        <div class="flex flex-col p-3 gap-2.5">
          <p class="font-medium text-sm text-zinc-600">Internal Comments</p>
          <p class="font-medium text-sm">
            <template
              v-if="data?.selected_donor?.internal_comments && data.selected_donor.internal_comments.length > 0"
            >
              {{ data.selected_donor.internal_comments }}
            </template>
            <template v-else> - </template>
          </p>
        </div>
      </template>
      <template #column2>
        <Attachments :items="data?.attachments" :table="data?.selected_donor?.table" />
        <OverviewCard title="Donor Quick Links">
          <div class="flex flex-col gap-4">
            <div class="flex justify-between items-center">
              <div class="flex gap-2 items-center">
                <p
                  class="flex h-5 w-5 shrink-0 grow-0 items-center justify-center rounded-full bg-zinc-200 text-zinc-700 xs-medium"
                >
                  {{ data?.quick_links?.donations }}
                </p>
                <p class="sm-medium text-zinc-500">Donations</p>
              </div>
              <div class="flex gap-3 items-center">
                <a-tooltip title="View Donations">
                  <nuxt-link
                    :to="{ path: '/donations', query: { donor_uid: identifier } }"
                    class="p-0 border-none shadow-none !bg-transparent text-blue-700"
                    target="_blank"
                  >
                    View
                  </nuxt-link>
                </a-tooltip>
                <a-tooltip title="Add New Donation" placement="topRight">
                  <nuxt-link
                    :to="{ path: '/donations/add-new-donation', query: { donorId: identifier } }"
                    class="p-0 border-none shadow-none !bg-transparent text-blue-700"
                    target="_blank"
                  >
                    <PlusIcon class="w-5 h-5" />
                  </nuxt-link>
                </a-tooltip>
              </div>
            </div>
            <div class="flex justify-between items-center">
              <div class="flex gap-2 items-center">
                <p
                  class="flex h-5 w-5 shrink-0 grow-0 items-center justify-center rounded-full bg-zinc-200 text-zinc-700 xs-medium"
                >
                  {{ data?.quick_links?.active_grants }}
                </p>
                <p class="sm-medium text-zinc-500">Active Grants</p>
              </div>
              <div class="flex gap-3 items-center">
                <a-tooltip title="View Active Grants">
                  <nuxt-link
                    :to="{ path: '/grants', query: { donor_uid: identifier, status: 'active' } }"
                    class="p-0 border-none shadow-none !bg-transparent text-blue-700"
                    target="_blank"
                  >
                    View
                  </nuxt-link>
                </a-tooltip>
                <a-tooltip title="Add New Grant" placement="topRight">
                  <nuxt-link
                    :to="{ path: '/grants/add-new-grant', query: { donorId: identifier } }"
                    class="p-0 border-none shadow-none !bg-transparent text-blue-700"
                    target="_blank"
                  >
                    <PlusIcon class="w-5 h-5" />
                  </nuxt-link>
                </a-tooltip>
              </div>
            </div>
            <div class="flex justify-between items-center">
              <div class="flex gap-2 items-center">
                <p
                  class="flex h-5 w-5 shrink-0 grow-0 items-center justify-center rounded-full bg-zinc-200 text-zinc-700 xs-medium"
                >
                  {{ data?.quick_links?.closed_grants }}
                </p>
                <p class="sm-medium text-zinc-500">Closed Grants</p>
              </div>
              <div class="flex gap-3 items-center">
                <a-tooltip title="View Closed Grants">
                  <nuxt-link
                    :to="{ path: '/grants', query: { donor_uid: identifier, status: 'closed' } }"
                    class="p-0 border-none shadow-none !bg-transparent text-blue-700"
                    target="_blank"
                  >
                    View
                  </nuxt-link>
                </a-tooltip>

                <a-button class="p-0 border-none shadow-none !bg-transparent w-fit h-fit" disabled>
                  <PlusIcon class="w-5 h-5" />
                </a-button>
              </div>
            </div>
            <div class="flex justify-between items-center">
              <div class="flex gap-2 items-center">
                <p
                  class="flex h-5 w-5 shrink-0 grow-0 items-center justify-center rounded-full bg-zinc-200 text-zinc-700 xs-medium"
                >
                  {{ data?.quick_links?.running_pledges }}
                </p>
                <p class="sm-medium text-zinc-500">Running Pledges</p>
              </div>
              <div class="flex gap-3 items-center">
                <a-tooltip title="View Running Pledges">
                  <nuxt-link
                    :to="{ path: '/pledges', query: { donor_uid: identifier, status: 'active' } }"
                    class="p-0 border-none shadow-none !bg-transparent text-blue-700"
                    target="_blank"
                  >
                    View
                  </nuxt-link>
                </a-tooltip>
                <a-tooltip title="Add New Pledge" placement="topRight">
                  <nuxt-link
                    :to="{ path: '/pledges/add-new-pledge', query: { donorId: identifier } }"
                    class="p-0 h-fit w-fit border-none shadow-none !bg-transparent text-blue-700"
                    target="_blank"
                  >
                    <PlusIcon class="w-5 h-5" />
                  </nuxt-link>
                </a-tooltip>
              </div>
            </div>
            <div class="flex justify-between items-center">
              <div class="flex gap-2 items-center">
                <p
                  class="flex h-5 w-5 shrink-0 grow-0 items-center justify-center rounded-full bg-zinc-200 text-zinc-700 xs-medium"
                >
                  {{ data?.quick_links?.expired_pledges }}
                </p>
                <p class="sm-medium text-zinc-500">Expired Pledges</p>
              </div>
              <div class="flex gap-3 items-center">
                <a-tooltip title="View Expired Pledges">
                  <nuxt-link
                    :to="expiredPledgePath"
                    class="p-0 border-none shadow-none !bg-transparent text-blue-700"
                    target="_blank"
                  >
                    View
                  </nuxt-link>
                </a-tooltip>
                <a-button class="p-0 border-none shadow-none !bg-transparent w-fit h-fit" disabled>
                  <PlusIcon class="w-5 h-5" />
                </a-button>
              </div>
            </div>
          </div>
        </OverviewCard>
        <OverviewCard title="Donor Analysis">
          <div class="flex flex-col gap-4">
            <div class="flex justify-between items-center">
              <div class="flex gap-1 items-center">
                <p class="sm-medium text-zinc-500">Cumulative Donations Received</p>
                <InformationCircle title="Total Donations booked across the system" />
              </div>
              <p class="sm-medium text-green-800">
                {{ formatToIndianCurrency(+data?.analytics?.donation_received || 0) }}
              </p>
            </div>
            <div class="flex justify-between items-center">
              <div class="flex gap-1 items-center">
                <p class="sm-medium text-zinc-500">Pending Donations Due</p>
                <InformationCircle title="Pledges / Grants tranches committed, for a past date but not completed" />
              </div>
              <p class="sm-medium text-red-800">
                {{ formatToIndianCurrency(+data?.analytics?.pending_donations_due || 0) }}
              </p>
            </div>
            <div class="flex justify-between items-center">
              <div class="flex gap-1 items-center">
                <p class="sm-medium text-zinc-500">Upcoming Commitments</p>
                <InformationCircle title="Pledges / Grants tranches upcoming, for a future date." />
              </div>
              <p class="sm-medium text-zinc-800">
                {{ formatToIndianCurrency(+data?.analytics?.upcoming_commitments || 0) }}
              </p>
            </div>
            <div class="flex justify-between items-center">
              <p class="sm-medium text-zinc-500">Last Donation on</p>
              <p class="sm-medium text-zinc-800">
                {{
                  data?.analytics?.last_donation_on
                    ? checkValidDate(data?.analytics?.last_donation_on)
                      ? formatDate(data?.analytics?.last_donation_on)
                      : '-'
                    : '-'
                }}
              </p>
            </div>
          </div>
        </OverviewCard>
        <AuditLogs :items="data?.audit_logs" title="Donor" />
        <AttachmentUpdates :items="data?.attachment_logs" />
      </template>
    </DrawerLayout>
  </a-drawer>
  <EditPasswordModal
    :visible="isEditPasswordModalOpen"
    :loading="deleteDonorStatus === 'pending'"
    modal-title="Delete Donor"
    @update:cancel="() => (isEditPasswordModalOpen = false)"
    @update:update="password => deleteDonor(password)"
  />
</template>

<style>
  @import '~/assets/css/donationDrawer.css';
</style>
