<script setup lang="tsx">
  import { InformationCircleIcon } from '@heroicons/vue/solid';
  import RedCrossIcon from '~/assets/Icons/RedCrossIcon.vue';
  import { get, post } from '~/services/donationScreen';
  import type { EmptyResponse, GetResponse, ResponseStatus } from '~/types';
  import { checkPermission } from '~/utils/auth/buttonController';

  interface EmailDetails {
    to: string[];
    subject: string;
    body: string;
  }
  interface EmailDetailsResponse {
    donor: {
      address: string;
      city: string;
      country: string;
      name: string;
      pincode: string;
      state: string;
      email: string;
    };
    email_data: {
      body: string;
      last_emailed_info: {
        comment: string;
        date: string;
        user: string;
      };
    };
  }

  const { onClose, identifier, modalFor } = defineProps<{
    onClose: () => void;
    identifier: string | null;
    modalFor: string | null;
  }>();

  const route = useRoute();

  const emailDetails = ref<EmailDetails>({
    subject: 'Your Donation Receipt',
    to: [],
    body: ''
  });

  const formRef = ref();

  const rules = ref({
    to: [
      { required: true, message: 'Please select at least one email', trigger: ['change', 'submit'] },
      {
        validator: (_rule: any, value: string[]) => {
          // Email validation regex
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const invalidEmails = value.filter(email => !emailRegex.test(email));

          if (invalidEmails.length > 0) {
            return Promise.reject('Please enter valid email addresses');
          }
          return Promise.resolve();
        }
      }
    ],
    subject: [],
    body: []
  });

  const getEmailData = async () => {
    const response = await get<GetResponse<EmailDetailsResponse>>(`v1/email_receipts/${identifier}`);
    emailDetails.value.body = response?.data?.data?.email_data?.body || '';
    if (response?.data?.data?.donor?.email) {
      emailDetails.value.to = [...emailDetails.value.to, response.data.data.donor.email];
    }
  };

  const responseStatus = ref<ResponseStatus>('idle');

  const sendEmail = async () => {
    responseStatus.value = 'pending';
    try {
      try {
        await formRef.value.validate();
      } catch (err) {
        // eslint-disable-next-line no-restricted-syntax
        console.log(err);
        responseStatus.value = 'error';
        return;
      }

      // Create payload with formatted email string
      const payload = {
        ...emailDetails.value,
        to: emailDetails.value.to.join(',')
      };

      if (route.query.tab === 'inKindDonation') {
        const response = await post<EmptyResponse>(`v1/email_receipts_dik/${identifier}`, {
          data: payload
        });
        if (response.data.status === 'success') {
          responseStatus.value = 'success';
        } else {
          responseStatus.value = 'error';
        }
      } else {
        const response = await post<EmptyResponse>(`v1/email_receipts/${identifier}`, {
          data: payload
        });
        if (response.data.status === 'success') {
          responseStatus.value = 'success';
        } else {
          responseStatus.value = 'error';
        }
      }
      onClose();
    } catch (error) {
      responseStatus.value = 'error';
      console.error('Error sending email', error);
    }
  };

  onMounted(async () => {
    await getEmailData();
  });
</script>

<template>
  <a-modal
    centered
    width="658px"
    title="Email Donation"
    :open="modalFor"
    class="receipt-modal account-info-modal"
    @cancel="onClose"
  >
    <template #footer>
      <div class="flex gap-1 w-full justify-end border-t border-gray-300 p-3">
        <a-button
          key="back"
          class="rounded text-sm font-medium border border-gray-300 text-gray-500 px-3 py-1.5 shadow-[0_1px_2px_0px_##0000000D]"
          @click="onClose"
          >Cancle</a-button
        >
        <a-button
          key="submit"
          class="px-3 border border-blue-700 rounded py-1.5 flex items-center justify-center text-sm font-medium shadow-[0_1px_2px_0px_##0000000D]"
          :loading="responseStatus === 'pending'"
          type="primary"
          @click="sendEmail"
          >Send Email</a-button
        >
      </div>
    </template>
    <a-form
      v-if="checkPermission(route.path, 'mailActionButton')"
      ref="formRef"
      :label-col="{ style: { width: '183px' } }"
      label-align="left"
      :model="emailDetails"
      :rules="rules"
      :colon="false"
      :hide-required-mark="true"
      class="flex flex-col gap-2.5 w-full"
    >
      <a-form-item label="To Email" name="to" class="flex gap-2.5 items-center !mb-0" :rules="rules.to">
        <a-select
          v-model:value="emailDetails.to"
          mode="tags"
          class="!w-[446px]"
          :token-separators="[' ']"
          :open="false"
        ></a-select>
      </a-form-item>
      <a-form-item label="Subject" name="subject" class="flex gap-2.5 items-center !mb-0" :rules="rules.subject">
        <input
          v-model="emailDetails.subject"
          type="text"
          class="border px-2 py-1.5 !w-[446px] border-zinc-200 rounded p-2"
        />
      </a-form-item>
      <a-form-item label="Body" name="body" class="flex gap-2.5 items-start !w-full !mb-0" :rules="rules.body">
        <textarea
          v-model="emailDetails.body"
          class="border px-2 py-1.5 !w-[446px] border-zinc-200 rounded p-2 h-[228px]"
        />
      </a-form-item>
      <div class="flex w-full items-center justify-center px-3 py-2 bg-zinc-100 rounded-lg gap-2.5">
        <InformationCircleIcon class="w-4 h-4 text-[#9CA3AF]" />
        <p class="text-zinc-700 text- font-medium">Receipt will be attached as a pdf along with this email</p>
      </div>
    </a-form>
    <div v-else class="w-full h-full justify-center items-center flex flex-col gap-2 pt-4">
      <RedCrossIcon />
      <p class="text-sm text-zinc-500">You are not authorized to email receipts.</p>
      <a-button class="text-sm text-blue-700 border-none bg-transparent p-0 w-fit h-fit shadow-none" @click="onClose"
        >Go to {{ route.path.split('/')[1].charAt(0).toUpperCase() + route.path.split('/')[1].slice(1) }} List</a-button
      >
    </div>
  </a-modal>
</template>

<style>
  @import '~/assets/css/modal.css';

  .receipt-modal .ant-modal-body {
    padding: 12px !important;
  }
</style>
