import { useAuth } from '~/composables/useAuth';
import { Role } from '~/types/auth';

const permissionConfig = new Map([
  [
    '/donations',
    {
      editActionButton: [Role.Admin, Role.DonationManager],
      mailActionButton: [Role.Admin, Role.DonationManager, Role.DonorManager, Role.Auditor, Role.ReadOnly],
      whatsappActionButton: [Role.Admin, Role.DonationManager, Role.DonorManager],
      receiptActionButton: [Role.Admin, Role.DonationManager, Role.DonorManager],
      deleteActionButton: [Role.Admin, Role.DonationManager],
      viewActionButton: [Role.Admin, Role.DonationManager, Role.DonorManager, Role.ReadOnly, Role.Auditor],
      duplicateActionButton: [Role.Admin, Role.DonationManager],
      headerPrimaryButton: [Role.Admin, Role.DonationManager],
      editLastSentButton: [Role.Admin, Role.DonationManager],
      headerExportButton: [Role.Admin, Role.DonationManager, Role.DonorManager, Role.ReadOnly, Role.Auditor]
    }
  ],
  [
    '/donations/add-new-donation',
    {
      headerPrimaryButton: [Role.Admin, Role.DonationManager]
    }
  ],
  [
    '/donations/edit-donation',
    {
      headerPrimaryButton: [Role.Admin, Role.DonationManager]
    }
  ],
  [
    '/donors',
    {
      headerPrimaryButton: [Role.Admin, Role.DonorManager],
      headerExportButton: [Role.Admin, Role.DonorManager, Role.DonationManager, Role.Auditor],
      editActionButton: [Role.Admin, Role.DonorManager],
      deleteActionButton: [Role.Admin, Role.DonorManager],
      viewActionButton: [Role.Admin, Role.DonorManager, Role.DonationManager, Role.ReadOnly, Role.Auditor],
      addDonationActionButton: [Role.Admin, Role.DonationManager, Role.DonorManager, Role.Auditor],
      addPledgeActionButton: [Role.Admin, Role.DonationManager, Role.DonorManager, Role.Auditor],
      addGrantActionButton: [Role.Admin, Role.DonationManager, Role.DonorManager, Role.Auditor]
    }
  ],
  [
    '/donors/add-new-donor',
    {
      headerPrimaryButton: [Role.Admin, Role.DonorManager]
    }
  ],
  [
    '/donors/edit-donor',
    {
      headerPrimaryButton: [Role.Admin, Role.DonorManager],
      editActionButton: [Role.Admin, Role.DonorManager],
      viewActionButton: [Role.Admin, Role.DonorManager, Role.ReadOnly, Role.DonationManager, Role.Auditor]
    }
  ],
  [
    '/vendors',
    {
      headerPrimaryButton: [Role.Admin],
      headerExportButton: [Role.Admin, Role.ReadOnly, Role.VendorSubmitter, Role.VendorApprover, Role.VendorAdmin],
      viewActionButton: [Role.Admin, Role.ReadOnly, Role.VendorSubmitter, Role.VendorApprover, Role.VendorAdmin],
      editActionButton: [Role.Admin, Role.VendorSubmitter, Role.VendorApprover, Role.VendorAdmin]
    }
  ],
  [
    '/vendors/add-new-vendor',
    {
      headerPrimaryButton: [Role.Admin, Role.VendorSubmitter, Role.VendorApprover, Role.VendorAdmin]
    }
  ],
  [
    '/vendors/edit-vendor',
    {
      headerPrimaryButton: [Role.Admin, Role.VendorSubmitter, Role.VendorApprover, Role.VendorAdmin]
    }
  ],
  [
    '/advances',
    {
      headerPrimaryButton: [Role.Admin, Role.AdvanceSubmitter, Role.AdvanceApprover, Role.AdvanceAdmin],
      headerExportButton: [Role.Admin, Role.AdvanceSubmitter, Role.AdvanceApprover, Role.AdvanceAdmin, Role.ReadOnly],
      viewActionButton: [Role.Admin, Role.AdvanceSubmitter, Role.AdvanceApprover, Role.AdvanceAdmin, Role.ReadOnly],
      editActionButton: [Role.Admin, Role.AdvanceSubmitter, Role.AdvanceApprover, Role.AdvanceAdmin]
    }
  ],
  [
    '/advances/add-new-advance',
    {
      headerPrimaryButton: [Role.Admin, Role.AdvanceSubmitter, Role.AdvanceApprover, Role.AdvanceAdmin]
    }
  ],
  [
    '/advances/edit-advance',
    {
      headerPrimaryButton: [Role.Admin, Role.AdvanceSubmitter, Role.AdvanceApprover, Role.AdvanceAdmin]
    }
  ],
  [
    '/grants',
    {
      headerPrimaryButton: [Role.Admin, Role.GrantSubmitter],
      viewActionButton: [
        Role.Admin,
        Role.GrantSubmitter,
        Role.GrantMember,
        Role.ReadOnly,
        Role.Auditor,
        Role.DonorAuditor
      ],
      editActionButton: [Role.Admin, Role.GrantSubmitter, Role.GrantMember],
      duplicateActionButton: [Role.Admin, Role.GrantSubmitter],
      closeActionButton: [Role.Admin, Role.GrantSubmitter, Role.GrantMember],
      headerExportButton: [
        Role.Admin,
        Role.GrantSubmitter,
        Role.GrantMember,
        Role.ReadOnly,
        Role.Auditor,
        Role.DonorAuditor
      ]
    }
  ],
  [
    '/grants/add-new-grant',
    {
      headerPrimaryButton: [Role.Admin, Role.GrantSubmitter]
    }
  ],
  [
    '/grants/edit-grant',
    {
      headerPrimaryButton: [Role.Admin, Role.GrantSubmitter, Role.GrantMember],
      closeActionButton: [Role.Admin, Role.GrantSubmitter, Role.GrantMember]
    }
  ],
  [
    '/pledges',
    {
      headerPrimaryButton: [Role.Admin, Role.DonorManager, Role.DonationManager],
      duplicateActionButton: [Role.Admin, Role.DonorManager, Role.DonationManager],
      editActionButton: [Role.Admin, Role.DonorManager, Role.DonationManager],
      viewActionButton: [Role.Admin, Role.DonorManager, Role.DonationManager, Role.ReadOnly, Role.Auditor],
      mailActionButton: [Role.Admin, Role.DonorManager, Role.DonationManager],
      whatsappActionButton: [Role.Admin, Role.DonorManager, Role.DonationManager],
      closeActionButton: [Role.Admin, Role.DonorManager, Role.DonationManager],
      headerExportButton: [Role.Admin, Role.DonorManager, Role.DonationManager, Role.ReadOnly, Role.Auditor]
    }
  ],
  [
    '/pledges/add-new-pledge',
    {
      headerPrimaryButton: [Role.Admin, Role.DonorManager, Role.DonationManager]
    }
  ],
  [
    '/pledges/edit-pledge',
    {
      headerPrimaryButton: [Role.Admin, Role.DonorManager, Role.DonationManager],
      closeActionButton: [Role.Admin, Role.DonorManager, Role.DonationManager]
    }
  ],
  [
    '/budgets',
    {
      headerPrimaryButton: [Role.Admin, Role.BudgetApprover, Role.BudgetMember],
      editActionButton: [Role.Admin, Role.BudgetApprover, Role.BudgetMember],
      viewActionButton: [
        Role.Admin,
        Role.ReadOnly,
        Role.Auditor,
        Role.DonorAuditor,
        Role.BudgetApprover,
        Role.BudgetMember
      ],
      duplicateActionButton: [Role.Admin, Role.BudgetApprover, Role.BudgetMember],
      headerExportButton: [
        Role.Admin,
        Role.BudgetApprover,
        Role.BudgetMember,
        Role.ReadOnly,
        Role.Auditor,
        Role.DonorAuditor
      ]
    }
  ],
  [
    '/budgets/add-new-budget',
    {
      headerPrimaryButton: [Role.Admin, Role.BudgetApprover, Role.BudgetMember]
    }
  ],
  [
    '/budgets/edit-budget',
    {
      headerPrimaryButton: [Role.Admin, Role.BudgetApprover, Role.BudgetMember]
    }
  ],
  [
    '/admin',
    {
      headerPrimaryButton: [Role.Admin]
    }
  ],
  [
    '/admin/users_list',
    {
      headerPrimaryButton: [Role.Admin],
      addActionButton: [Role.Admin],
      viewActionButton: [Role.Admin],
      editActionButton: [Role.Admin],
      duplicateActionButton: [Role.Admin],
      headerExportButton: [Role.Admin]
    }
  ],
  [
    '/admin/add_new_user',
    {
      headerPrimaryButton: [Role.Admin]
    }
  ],
  [
    '/admin/audit_logs_list',
    {
      headerExportButton: [Role.Admin]
    }
  ],
  [
    '/setup/accounting_periods',
    {
      headerPrimaryButton: [Role.Admin],
      headerExportButton: [Role.Admin],
      viewActionButton: [Role.Admin, Role.ReadOnly],
      addActionButton: [Role.Admin],
      editActionButton: [Role.Admin],
      duplicateActionButton: [Role.Admin]
    }
  ],

  [
    '/utilities',
    {
      importActionButton: [Role.Admin, Role.UtilitiesAccess]
    }
  ],
  [
    '/utilities/import_ledgers',
    {
      importActionButton: [Role.Admin, Role.UtilitiesAccess],
      downloadActionButton: [Role.Admin, Role.UtilitiesAccess]
    }
  ],
  [
    '/utilities/import_payments',
    {
      importActionButton: [Role.Admin, Role.UtilitiesAccess],
      downloadActionButton: [Role.Admin, Role.UtilitiesAccess]
    }
  ],
  [
    '/utilities/statutory_reports',
    {
      exportActionButton: [Role.Admin, Role.UtilitiesAccess]
    }
  ],
  [
    '/utilities/export_tally_income',
    {
      exportActionButton: [Role.Admin, Role.UtilitiesAccess]
    }
  ],
  [
    '/utilities/export_tally_expense',
    {
      exportActionButton: [Role.Admin, Role.UtilitiesAccess]
    }
  ],
  [
    '/utilities/expense_reconciliation',
    {
      reconcileActionButton: [Role.Admin, Role.UtilitiesAccess]
    }
  ],
  [
    '/utilities/expense_reconciliation_status',
    {
      generateActionButton: [Role.Admin, Role.UtilitiesAccess]
    }
  ],
  [
    '/setup',
    {
      headerPrimaryButton: [Role.Admin],
      viewActionButton: [Role.Admin, Role.ReadOnly],
      addActionButton: [Role.Admin],
      editActionButton: [Role.Admin],
      duplicateActionButton: [Role.Admin],
      headerExportButton: [Role.Admin]
    }
  ],
  [
    '/setup/purposes',
    {
      headerPrimaryButton: [Role.Admin],
      headerExportButton: [Role.Admin],
      viewActionButton: [Role.Admin, Role.ReadOnly],
      addActionButton: [Role.Admin],
      editActionButton: [Role.Admin],
      duplicateActionButton: [Role.Admin]
    }
  ],
  [
    '/setup/stock_items',
    {
      headerPrimaryButton: [Role.Admin],
      headerExportButton: [Role.Admin],
      viewActionButton: [Role.Admin, Role.ReadOnly],
      addActionButton: [Role.Admin],
      editActionButton: [Role.Admin],
      duplicateActionButton: [Role.Admin]
    }
  ],
  [
    '/setup/ledgers',
    {
      headerPrimaryButton: [Role.Admin],
      headerExportButton: [Role.Admin],
      viewActionButton: [Role.Admin, Role.ReadOnly],
      addActionButton: [Role.Admin],
      editActionButton: [Role.Admin],
      duplicateActionButton: [Role.Admin]
    }
  ],
  [
    '/setup/cost_centres',
    {
      headerPrimaryButton: [Role.Admin],
      headerExportButton: [Role.Admin],
      viewActionButton: [Role.Admin, Role.ReadOnly],
      addActionButton: [Role.Admin],
      editActionButton: [Role.Admin],
      duplicateActionButton: [Role.Admin]
    }
  ],
  [
    '/setup/payee_types',
    {
      headerPrimaryButton: [
        Role.Admin,
        Role.ReadOnly,
        Role.ExpenseSubmitter,
        Role.ExpenseApprover,
        Role.BudgetApprover
      ],
      viewActionButton: [Role.Admin, Role.ReadOnly, Role.ExpenseSubmitter, Role.ExpenseApprover, Role.BudgetApprover],
      addActionButton: [Role.Admin, Role.ReadOnly, Role.ExpenseSubmitter, Role.ExpenseApprover, Role.BudgetApprover],
      editActionButton: [Role.Admin, Role.ReadOnly, Role.ExpenseSubmitter, Role.ExpenseApprover, Role.BudgetApprover],
      duplicateActionButton: [
        Role.Admin,
        Role.ReadOnly,
        Role.ExpenseSubmitter,
        Role.ExpenseApprover,
        Role.BudgetApprover
      ]
    }
  ],
  [
    '/expenses/add-new-expense',
    {
      headerPrimaryButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ]
    }
  ],
  [
    '/expenses',
    {
      headerPrimaryButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      headerExportButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      editActionButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      viewActionButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ]
    }
  ],
  [
    '/expenses/edit-expense',
    {
      headerPrimaryButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ]
    }
  ],
  [
    '/recurring-expenses',
    {
      headerPrimaryButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      headerExportButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      editActionButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      viewActionButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ]
    }
  ],
  [
    '/recurring-expenses/add-new-recurring-expense',
    {
      headerPrimaryButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ]
    }
  ],
  [
    '/recurring-expenses/edit-recurring-expense',
    {
      headerPrimaryButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ]
    }
  ]
]);

const tabPermissionConfig = new Map([
  [
    'default',
    {
      headerPrimaryButton: [Role.Admin, Role.DefaultExpenseApprover, Role.DefaultExpenseSubmitter],
      editActionButton: [Role.Admin, Role.DefaultExpenseApprover, Role.DefaultExpenseSubmitter],
      viewActionButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter
      ],
      headerExportButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter
      ]
    }
  ],
  [
    'payroll',
    {
      headerPrimaryButton: [Role.Admin, Role.PayrollExpenseApprover, Role.PayrollExpenseSubmitter],
      headerExportButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter
      ],
      editActionButton: [Role.Admin, Role.PayrollExpenseApprover, Role.PayrollExpenseSubmitter],
      viewActionButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter
      ]
    }
  ],
  [
    'custom_exp3',
    {
      headerPrimaryButton: [Role.Admin, Role.CustomExp3ExpenseApprover, Role.CustomExp3ExpenseSubmitter],
      headerExportButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      editActionButton: [Role.Admin, Role.CustomExp3ExpenseApprover, Role.CustomExp3ExpenseSubmitter],
      viewActionButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ]
    }
  ],
  [
    'all_expenses',
    {
      headerPrimaryButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      headerExportButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      editActionButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      viewActionButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ]
    }
  ],
  [
    'pending_approval_by_me',
    {
      headerPrimaryButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      headerExportButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      editActionButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      viewActionButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ]
    }
  ],
  [
    'submitted_by_me',
    {
      headerPrimaryButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      headerExportButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      editActionButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      viewActionButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ]
    }
  ],
  [
    'sub_grant',
    {
      headerPrimaryButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      headerExportButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      editActionButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      viewActionButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ]
    }
  ],
  [
    '_sub_grant',
    {
      headerPrimaryButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      headerExportButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      editActionButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      viewActionButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ]
    }
  ],
  [
    'petty_cash',
    {
      headerPrimaryButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      headerExportButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      editActionButton: [
        Role.Admin,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ],
      viewActionButton: [
        Role.Admin,
        Role.Auditor,
        Role.ReadOnly,
        Role.DefaultExpenseApprover,
        Role.DefaultExpenseSubmitter,
        Role.PayrollExpenseApprover,
        Role.PayrollExpenseSubmitter,
        Role.CustomExp3ExpenseApprover,
        Role.CustomExp3ExpenseSubmitter
      ]
    }
  ]
]);

export function checkPermission(route: string, action: string, tab?: string): boolean {
  const { role } = useAuth();

  if ((route === '/expenses' || route === '/recurring-expenses') && tab) {
    const tabConfig = tabPermissionConfig.get(tab);
    if (!tabConfig) return false;

    const allowedRoles = tabConfig[action as keyof typeof tabConfig];
    return Array.isArray(allowedRoles) && allowedRoles.some(allowedRole => role.value.includes(allowedRole));
  }

  const routeConfig = permissionConfig.get(route);

  if (!routeConfig) return false;

  const allowedRoles = routeConfig[action as keyof typeof routeConfig];
  return Array.isArray(allowedRoles) && allowedRoles.some(allowedRole => role.value.includes(allowedRole));
}
