<script setup lang="tsx">
  import { TrashIcon } from '@heroicons/vue/outline';
  import { PlusIcon } from '@heroicons/vue/solid';
  import RedCrossIcon from '~/assets/Icons/RedCrossIcon.vue';
  import type { ResponseStatus } from '~/types';
  import { checkPermission } from '~/utils/auth/buttonController';

  // Add this interface for payment form
  interface PaymentDetails {
    date: string;
    mode: string;
    ref: string;
    bookOfAccounts: string;
    amount: number;
  }

  const { onClose, modalFor } = defineProps<{
    onClose: () => void;
    identifier: string | null;
    modalFor: string | null;
  }>();

  const route = useRoute();

  const formRef = ref();

  // Update formModel to be the primary source of truth
  const formModel = ref({
    payments: [
      {
        date: '',
        mode: '',
        ref: '',
        bookOfAccounts: '',
        amount: 0
      }
    ]
  });

  // Update validation rules structure
  const paymentRules = {
    payments: {
      type: 'array',
      required: true,
      validator: async (_rule: any, value: PaymentDetails[]) => {
        for (const [index, payment] of value.entries()) {
          if (!payment.date) throw new Error(`Date is required for payment ${index + 1}`);
          if (!payment.mode) throw new Error(`Payment mode is required for payment ${index + 1}`);
          if (!payment.bookOfAccounts) throw new Error(`BOA is required for payment ${index + 1}`);
          if (!payment.amount || payment.amount <= 0)
            throw new Error(`Valid amount is required for payment ${index + 1}`);
        }
      }
    }
  };

  const responseStatus = ref<ResponseStatus>('idle');

  const sendEmail = async () => {
    responseStatus.value = 'pending';

    try {
      await formRef.value.validate();
      // If validation passes, check if we have any empty required fields
      const hasEmptyFields = formModel.value.payments.some(
        payment => !payment.date || !payment.mode || !payment.bookOfAccounts || !payment.amount || payment.amount <= 0
      );

      if (hasEmptyFields) {
        responseStatus.value = 'error';
        throw new Error('Please fill in all required fields');
      }

      responseStatus.value = 'success';
      // onClose();
    } catch (error) {
      responseStatus.value = 'error';
      console.error('Validation failed:', error);
    }
  };

  // Add function to handle new payment row
  const addPaymentRow = () => {
    formModel.value.payments.push({
      date: '',
      mode: '',
      ref: '',
      bookOfAccounts: '',
      amount: 0
    });
  };

  // Add function to remove payment row
  const removePaymentRow = (index: number) => {
    if (formModel.value.payments.length > 1) {
      formModel.value.payments.splice(index, 1);
    }
  };
</script>

<template>
  <a-modal
    centered
    width="1000px"
    title="Payments"
    :open="modalFor"
    class="manage-payment-modal account-info-modal"
    @cancel="onClose"
  >
    <template #footer>
      <div class="flex gap-1 w-full justify-end border-t border-gray-300 p-3">
        <a-button
          key="back"
          class="rounded text-sm font-medium border border-gray-300 text-gray-500 px-3 py-1.5 shadow-[0_1px_2px_0px_##0000000D]"
          @click="onClose"
          >Cancle</a-button
        >
        <a-button
          key="submit"
          class="px-3 border border-blue-700 rounded py-1.5 flex items-center justify-center text-sm font-medium shadow-[0_1px_2px_0px_##0000000D]"
          :loading="responseStatus === 'pending'"
          type="primary"
          @click="sendEmail"
          >Save Payment</a-button
        >
      </div>
    </template>
    <div
      v-if="checkPermission(route.path, 'mailActionButton')"
      class="flex flex-col min-h-[330px] max-h-[330px] bg-gray-50 relative"
    >
      <a-form
        ref="formRef"
        :model="formModel"
        :rules="paymentRules"
        class="overflow-y-scroll max-h-[270px]"
        validate-trigger="['change', 'blur', 'submit']"
      >
        <table>
          <thead>
            <tr class="bg-gray-50">
              <td class="border border-gray-200 py-2 px-3 max-w-[42px] min-w-[42px] text-xs text-gray-600">#</td>
              <td class="border border-gray-200 py-2 px-3 max-w-[135px] min-w-[135px] text-xs text-gray-600">
                Date <span class="text-red-500">*</span>
              </td>
              <td class="border border-gray-200 py-2 px-3 max-w-[200px] min-w-[200px] text-xs text-gray-600">
                Mode <span class="text-red-500">*</span>
              </td>
              <td class="border border-gray-200 py-2 px-3 w-full text-xs text-gray-600">Ref</td>
              <td class="border border-gray-200 py-2 px-3 max-w-[154px] min-w-[154px] text-xs text-gray-600">
                Book of Accounts <span class="text-red-500">*</span>
              </td>
              <td class="border border-gray-200 py-2 px-3 max-w-[200px] min-w-[200px] text-xs text-gray-600">
                Amount
                <span class="text-red-500">*</span>
              </td>
              <td class="border border-gray-200 py-2 px-3 max-w-[40px] min-w-[40px] text-xs text-gray-600"></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(payment, index) in formModel.payments" :key="index" class="bg-white">
              <td class="border border-gray-200 py-2 px-3">{{ index + 1 }}</td>
              <td class="border border-gray-200 py-2 px-3">
                <a-form-item
                  :name="['payments', index, 'date']"
                  :validate-status="responseStatus === 'error' && !payment.date ? 'error' : ''"
                  :help="responseStatus === 'error' && !payment.date ? 'Date is required' : ''"
                >
                  <a-date-picker v-model:value="formModel.payments[index].date" placeholder="Select" />
                </a-form-item>
              </td>
              <td class="border border-gray-200 py-2 px-3">
                <a-form-item
                  :name="['payments', index, 'mode']"
                  :validate-status="responseStatus === 'error' && !payment.mode ? 'error' : ''"
                  :help="responseStatus === 'error' && !payment.mode ? 'Mode is required' : ''"
                >
                  <a-select
                    v-model:value="formModel.payments[index].mode"
                    show-search
                    style="width: 100%"
                    :placeholder="'Select'"
                    :options="[
                      {
                        value: 'jack',
                        label: 'Jack'
                      },
                      {
                        value: 'lucy',
                        label: 'Lucy'
                      }
                    ]"
                  />
                </a-form-item>
              </td>
              <td class="border border-gray-200 py-2 px-3">
                <a-form-item :name="['payments', index, 'ref']">
                  <a-input v-model:value="formModel.payments[index].ref" placeholder="Select" />
                </a-form-item>
              </td>
              <td class="border border-gray-200 py-2 px-3">
                <a-form-item
                  :name="['payments', index, 'bookOfAccounts']"
                  :validate-status="responseStatus === 'error' && !payment.bookOfAccounts ? 'error' : ''"
                  :help="responseStatus === 'error' && !payment.bookOfAccounts ? 'BOA is required' : ''"
                >
                  <a-select
                    v-model:value="formModel.payments[index].bookOfAccounts"
                    show-search
                    style="width: 100%"
                    :placeholder="'Select'"
                    :options="[
                      {
                        value: 'FCRA',
                        label: 'FCRA'
                      },
                      {
                        value: 'Non-FCRA',
                        label: 'Non-FCRA'
                      }
                    ]"
                  />
                </a-form-item>
              </td>
              <td class="border border-gray-200 py-2 px-3">
                <a-form-item
                  :name="['payments', index, 'amount']"
                  :validate-status="
                    responseStatus === 'error' && (!payment.amount || payment.amount <= 0) ? 'error' : ''
                  "
                  :help="
                    responseStatus === 'error' && (!payment.amount || payment.amount <= 0)
                      ? 'Valid amount is required'
                      : ''
                  "
                >
                  <a-input-number v-model:value="formModel.payments[index].amount" addon-before="₹" />
                </a-form-item>
              </td>
              <td class="border border-gray-200 py-2 px-3">
                <a-button
                  class="!w-fit !h-fit !p-0 !sm-medium !border-none !bg-transparent !shadow-none"
                  :class="{
                    'text-gray-200': formModel.payments.length === 1,
                    'text-blue-700': formModel.payments.length !== 1
                  }"
                  :disabled="formModel.payments.length === 1"
                  @click="removePaymentRow(index)"
                >
                  <TrashIcon class="w-5 h-5" />
                </a-button>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="flex w-full py-2 px-3 bg-white border-b border-gray-200 shadow-[0_1px_3px_0px_#0000001A,0_1px_2px_0px_#0000000F]"
        >
          <a-button
            class="!w-fit !h-fit !p-0 !sm-medium !text-blue-700 !border-none !bg-transparent !shadow-none flex items-center gap-2"
            @click="addPaymentRow"
          >
            <PlusIcon class="w-5 h-5" />
            <p class="sm-medium">Add Payment</p>
          </a-button>
        </div>
      </a-form>
      <div class="flex flex-col items-end w-full border-t border-zinc-200 bg-white absolute bottom-0">
        <div class="flex gap-2.5 pr-10 py-1">
          <p>Advance Amount</p>
          <p>12345678990</p>
        </div>
        <div class="flex gap-2.5 pr-10 py-1">
          <p>Balance Payment Due (net of TDS)</p>
          <p>12345678990</p>
        </div>
      </div>
    </div>
    <div v-else class="w-full h-full justify-center items-center flex flex-col gap-2 pt-4">
      <RedCrossIcon />
      <p class="text-sm text-zinc-500">You are not authorized to email receipts.</p>
      <a-button class="text-sm text-blue-700 border-none bg-transparent p-0 w-fit h-fit shadow-none" @click="onClose"
        >Go to {{ route.path.split('/')[1].charAt(0).toUpperCase() + route.path.split('/')[1].slice(1) }} List</a-button
      >
    </div>
  </a-modal>
</template>

<style>
  @import '~/assets/css/modal.css';

  .manage-payment-modal .ant-modal-body {
    padding: 0px !important;
  }

  .manage-payment-modal .ant-modal-footer {
    margin-top: 0px !important;
  }

  .manage-payment-modal .ant-form-item {
    margin-bottom: 0px !important;
  }
</style>
