<script setup lang="tsx">
  import CustomDate from '~/components/UI/Forms/CustomDate.vue';
  import CustomDropdown from '~/components/UI/Forms/CustomDropdown.vue';
  import CustomInput from '~/components/UI/Forms/CustomInput.vue';
  import { getToday, toDateTime } from '~/functions/dateTimeUtilities';
  import { get, post, put } from '~/services/donationScreen';
  import type { AccountingPeriodDrawer, GetResponse, PostResponse, ResponseStatus } from '~/types';
  import AuditLogs from '../RoutedDrawerComponents/AuditLogs.vue';
  import RoleBasedButton from '~/components/UI/Others/RoleBasedButton.vue';
  import EditPasswordModal from '../../Modals/EditPasswordModal.vue';

  const props = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const route = useRoute();

  const action = computed(() => {
    return route.query.action as string;
  });

  const formRef = ref();
  const formState = reactive({
    startDate: getToday(),
    eightyGSerialNumber: '-',
    regularSerialNumber: '-',
    openStatus: 'Yes'
  });

  const responseState = ref<ResponseStatus>('idle');
  const data = ref<AccountingPeriodDrawer>({} as AccountingPeriodDrawer);

  const isModalVisible = ref<boolean>(false);
  const editStatus = ref<ResponseStatus>('idle');

  const fetchData = async () => {
    responseState.value = 'pending';
    try {
      const response = await get<GetResponse<AccountingPeriodDrawer>>(`v1/get_acc_period/${props.identifier}`);
      if (response.status === 200) {
        data.value = response.data.data;
        Object.assign(formState, {
          startDate: toDateTime(response?.data?.data?.selected_period?.year_start_date) || '',
          eightyGSerialNumber: response?.data?.data?.selected_period?.serial_80g?.toString() || '',
          regularSerialNumber: response?.data?.data?.selected_period?.serial_reg?.toString() || '',
          openStatus: response?.data?.data?.selected_period?.is_open || ''
        });
        responseState.value = 'success';
      } else {
        responseState.value = 'error';
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      responseState.value = 'error';
    }
  };

  const rules = {
    startDate: [
      {
        required: true,
        message: 'Accounting Start Date is required',
        trigger: 'submit'
      }
    ],
    eightyGSerialNumber: [
      {
        required: true,
        message: '80G Serial Number is required',
        trigger: 'submit'
      }
    ],
    regularSerialNumber: [
      {
        required: true,
        message: 'Regular Serial Number is required',
        trigger: 'submit'
      }
    ],
    openStatus: [
      {
        required: true,
        message: 'Open Status is required',
        trigger: 'submit'
      }
    ]
  };

  const updateStateField = (value: string, stateKey: keyof typeof formState) => {
    (formState as any)[stateKey] = value;
  };

  const submitHandler = async (modalData: { password: string }) => {
    try {
      editStatus.value = 'pending';
      const response = await put<PostResponse<AccountingPeriodDrawer>>(`v1/acc_periods/${props.identifier}`, {
        data: {
          is_open: formState.openStatus,
          password: modalData.password
        }
      });

      if (response.status === 401) {
        editStatus.value = 'error';
        return openFailedCustomNotification('Wrong Password!');
      }

      if (response.data.status === 'success') {
        openSuccessfulCustomNotification('Accounting Period Updated Successfully');
        isModalVisible.value = false;
        props.onClose();
      } else {
        openFailedCustomNotification('Failed to Update Accounting Period');
        editStatus.value = 'error';
      }
    } catch (error) {
      console.error(error);
      openFailedCustomNotification('Failed to Update Accounting Period');
      editStatus.value = 'error';
    }
  };

  const handleCreate = async () => {
    editStatus.value = 'pending';
    try {
      const response = await post<PostResponse<AccountingPeriodDrawer>>('/v1/add_new_acc_period', {
        data: {
          year_start_date: formState.startDate
        }
      });

      if (response.data.status === 'success') {
        openSuccessfulCustomNotification('Accounting Period Created Successfully');
        props.onClose();
        editStatus.value = 'success';
      } else {
        openFailedCustomNotification('Failed to Create Accounting Period');
        editStatus.value = 'error';
      }
    } catch (error) {
      console.error(error);
      openFailedCustomNotification('Failed to Create Accounting Period');
      editStatus.value = 'error';
    }
  };

  const handleCreateAccountingPeriod = async () => {
    try {
      await formRef.value.validate();

      if (route.query.action === 'edit') {
        return openModal();
      }

      await handleCreate();
    } catch (error) {
      console.error(error);
    }
  };

  const openModal = async () => {
    try {
      const isFormValid = await formRef.value.validate();
      if (!isFormValid) throw new Error('Form validation failed!');

      isModalVisible.value = true;
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalCancel = () => {
    isModalVisible.value = false;
  };

  onMounted(() => {
    if (props.identifier) {
      fetchData();
    }
  });
</script>

<template>
  <a-drawer
    :open="route.query.drawerFrom === 'accountingPeriods'"
    :title="
      action === 'edit'
        ? 'Update Accounting Period'
        : action === 'add' || action === 'duplicate'
          ? 'Add New Accounting Period'
          : 'Accounting Period'
    "
    width="750"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    @close="props.onClose"
  >
    <template #extra>
      <RoleBasedButton
        v-if="!props.identifier || action"
        component-name="addActionButton"
        active-class="py-2 px-3 bg-blue-600 hover:bg-blue-700 text-white rounded sm-medium duration-150 border border-blue-600"
        disabled-class="py-2 px-3 rounded sm-medium duration-150 !bg-gray-200 !shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_0px_#0000000F] border-none"
        :loading="editStatus === 'pending'"
        @click="handleCreateAccountingPeriod"
        >{{ action === 'edit' ? 'Update Period' : 'Create New Period' }}</RoleBasedButton
      >
    </template>
    <a-form
      ref="formRef"
      class="flex flex-col gap-4 p-3 setup-drawer-form"
      :model="formState"
      :rules="rules"
      :colon="false"
      :label-col="{ style: { width: '250px', textAlign: 'left' } }"
      hide-required-mark="true"
    >
      <CustomDate
        label-text="Accounting Start Date"
        name="startDate"
        :value="toDateTime(formState.startDate)"
        state-key="startDate"
        :is-compulsory="true"
        :disabled="!action || action === 'edit'"
        :rule="rules.startDate"
      />
      <CustomInput
        label-text="Last 80G Serial Number"
        name="eightyGSerialNumber"
        :value="formState.eightyGSerialNumber"
        state-key="eightyGSerialNumber"
        :is-compulsory="true"
        :rule="rules.eightyGSerialNumber"
        :is-disabled="true"
        @update:value="updateStateField"
      />
      <CustomInput
        label-text="Last Regular Serial Number"
        name="regularSerialNumber"
        :value="formState.regularSerialNumber"
        state-key="regularSerialNumber"
        :is-compulsory="true"
        :rule="rules.regularSerialNumber"
        :is-disabled="true"
        @update:value="updateStateField"
      />
      <CustomDropdown
        :options="[
          { title: 'Open', value: 'Yes' },
          { title: 'Closed', value: 'No' }
        ]"
        label-text="Open Status"
        name="openStatus"
        :value="formState.openStatus"
        state-key="openStatus"
        :is-compulsory="true"
        :rule="rules.openStatus"
        :disabled="action === 'edit' ? false : true"
        @update:value="updateStateField"
      />
      <AuditLogs v-if="responseState === 'success'" :items="data?.audit_log_data" title="Accounting Period" />
    </a-form>
    <EditPasswordModal
      :visible="isModalVisible"
      :loading="editStatus === 'pending'"
      modal-title="Update Accounting Period"
      @update:cancel="handleModalCancel"
      @update:update="submitHandler"
    />
  </a-drawer>
</template>

<style>
  @import '~/assets/css/donationDrawer.css';

  .setup-drawer-form .ant-form-item {
    margin-bottom: 0px;
  }
</style>
