<script setup lang="tsx">
  import CustomDropdown from '~/components/UI/Forms/CustomDropdown.vue';
  import CustomInput from '~/components/UI/Forms/CustomInput.vue';
  import CustomTextArea from '~/components/UI/Forms/CustomTextArea.vue';
  import { get, post } from '~/services/donationScreen';
  import type { GetResponse, ResponseStatus, PayeeTypeDrawer, PostResponse } from '~/types';
  import AuditLogs from '../RoutedDrawerComponents/AuditLogs.vue';
  import EditPasswordModal from '../../Modals/EditPasswordModal.vue';

  const props = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const route = useRoute();

  const action = computed(() => {
    return route.query.action as string;
  });

  const formRef = ref();
  const formState = reactive({
    payeeTypeName: '',
    payeeTypeCategory: '',
    description: ''
  });
  const isModalVisible = ref<boolean>(false);
  const editStatus = ref<ResponseStatus>('idle');

  const responseState = ref<ResponseStatus>('idle');
  const data = ref<PayeeTypeDrawer>({} as PayeeTypeDrawer);

  const fetchData = async () => {
    responseState.value = 'pending';
    try {
      const response = await get<GetResponse<PayeeTypeDrawer>>(`v1/get_vendor_type/${props.identifier}`);
      if (response.status === 200) {
        data.value = response.data.data;
        Object.assign(formState, {
          payeeTypeName: response?.data?.data?.vendor_type?.vendor_type_name || '',
          payeeTypeCategory: response?.data?.data?.vendor_type?.vendor_type_category || '',
          description: response?.data?.data?.vendor_type?.vendor_type_description || ''
        });
        responseState.value = 'success';
      } else {
        responseState.value = 'error';
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      responseState.value = 'error';
    }
  };

  const rules = {
    payeeTypeName: [
      {
        required: true,
        message: 'Payee Type Name is required',
        trigger: 'submit'
      }
    ],
    payeeTypeCategory: [
      {
        required: true,
        message: 'Payee Type Category is required',
        trigger: 'submit'
      }
    ],
    description: []
  };

  const updateStateField = (value: string, stateKey: keyof typeof formState) => {
    (formState as any)[stateKey] = value;
  };

  const submitHandler = async (modalData: { password: string }) => {
    try {
      editStatus.value = 'pending';
      const response = await post<PostResponse<PayeeTypeDrawer>>(`v1/vendor_type/${props.identifier}`, {
        data: {
          vendor_type_name: formState.payeeTypeName,
          vendor_type_category: formState.payeeTypeCategory,
          vendor_type_description: formState.description,
          password: modalData.password
        }
      });

      if (response.status === 401) {
        editStatus.value = 'error';
        return openFailedCustomNotification('Wrong Password!');
      }

      if (response.data.status === 'success') {
        openSuccessfulCustomNotification('Payee Type Updated Successfully');
        isModalVisible.value = false;
        props.onClose();
      } else {
        openFailedCustomNotification('Failed to Update Payee Type');
        editStatus.value = 'error';
      }
    } catch (error) {
      console.error(error);
      openFailedCustomNotification('Failed to Update Payee Type');
      editStatus.value = 'error';
    }
  };

  const handleCreate = async () => {
    editStatus.value = 'pending';
    try {
      const response = await post<PostResponse<PayeeTypeDrawer>>('v1/add_vendor_type', {
        data: {
          vendor_type_name: formState.payeeTypeName,
          vendor_type_category: formState.payeeTypeCategory,
          vendor_type_description: formState.description
        }
      });

      if (response.data.status === 'success') {
        openSuccessfulCustomNotification('Payee Type Created Successfully');
        props.onClose();
        editStatus.value = 'success';
      } else {
        openFailedCustomNotification('Failed to Create Payee Type');
        editStatus.value = 'error';
      }
    } catch (error) {
      console.error(error);
      openFailedCustomNotification('Failed to Create Payee Type');
      editStatus.value = 'error';
    }
  };

  const handleCreatePayeeType = async () => {
    try {
      await formRef.value.validate();

      if (route.query.action === 'edit') {
        return openModal();
      }

      await handleCreate();
    } catch (error) {
      console.error(error);
    }
  };

  const openModal = async () => {
    try {
      const isFormValid = await formRef.value.validate();
      if (!isFormValid) throw new Error('Form validation failed!');

      isModalVisible.value = true;
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalCancel = () => {
    isModalVisible.value = false;
  };

  onMounted(() => {
    if (props.identifier) {
      fetchData();
    }
  });
</script>

<template>
  <a-drawer
    :open="route.query.drawerFrom === 'payeeTypes'"
    :title="
      action === 'edit'
        ? 'Update Payee Type'
        : action === 'add' || action === 'duplicate'
          ? 'Add New Payee Type'
          : 'Payee Type'
    "
    width="750"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    @close="props.onClose"
  >
    <template #extra>
      <a-button
        v-if="!props.identifier || action"
        class="!px-3 !py-2 w-fit h-fit !rounded"
        type="primary"
        :loading="editStatus === 'pending'"
        @click="handleCreatePayeeType"
      >
        {{ action === 'edit' ? 'Update Payee Type' : 'Create Payee Type' }}
      </a-button>
    </template>
    <a-form
      ref="formRef"
      class="flex flex-col gap-4 p-3 setup-drawer-form"
      :model="formState"
      :rules="rules"
      :colon="false"
      :label-col="{ style: { width: '183px', textAlign: 'left' } }"
      hide-required-mark="true"
    >
      <CustomInput
        label-text="Payee Type Name"
        name="payeeTypeName"
        :value="formState.payeeTypeName"
        state-key="payeeTypeName"
        :is-compulsory="true"
        :is-disabled="!action"
        @update:value="updateStateField"
      />
      <CustomDropdown
        :options="[
          { title: 'Employee', value: 'Employee' },
          { title: 'Consultant', value: 'Consultant' },
          { title: 'Supplier', value: 'Supplier' },
          { title: 'Contractor', value: 'Contractor' },
          { title: 'NGOs/Sub-grantee', value: 'NGOs/Sub-grantee' },
          { title: 'Others', value: 'Others' }
        ]"
        label-text="Payee Type Category"
        name="payeeTypeCategory"
        :value="formState.payeeTypeCategory"
        state-key="payeeTypeCategory"
        :rule="rules.payeeTypeCategory"
        :is-compulsory="true"
        :disabled="!action"
        @update:value="updateStateField"
      />
      <CustomTextArea
        label="Description"
        name="description"
        :value="formState.description"
        state-key="description"
        :is-disabled="!action"
        @update:value="updateStateField"
      />
      <AuditLogs v-if="responseState === 'success'" :items="data?.audit_log_data" />
    </a-form>
    <EditPasswordModal
      :visible="isModalVisible"
      :loading="editStatus === 'pending'"
      modal-title="Update Payee Type"
      @update:cancel="handleModalCancel"
      @update:update="submitHandler"
    />
  </a-drawer>
</template>

<style>
  @import '~/assets/css/donationDrawer.css';

  .setup-drawer-form .ant-form-item {
    margin-bottom: 0px;
  }
</style>
