export const formatToIndianCurrency = (amount: number | null) => {
  if (amount == null) return '-';
  const formattedAmount = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    currencyDisplay: 'symbol'
  }).format(amount);

  if (amount < 0) {
    return formattedAmount.replace(/^-₹/, '-₹ ');
  }
  return formattedAmount.replace(/^₹/, '₹ ');
};

export const formatToIndianCurrencyWithoutDecimals = (amount: number | null) => {
  if (amount == null) return '-';
  const formattedAmount = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(amount);

  if (amount < 0) {
    return formattedAmount.replace(/^-₹/, '-₹ ');
  }
  return formattedAmount.replace(/^₹/, '₹ ');
};
