import type { Rule } from 'ant-design-vue/es/form';
import type { AddUserFormState } from '~/types';

export const addUserFormDefaultState: AddUserFormState = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  password: '',
  permissions: {
    systemLevelRoles: [
      {
        checked: false,
        checkboxText: 'Read Only',
        name: 'read_only'
      },
      {
        checked: false,
        checkboxText: 'System Administrator',
        name: 'admin'
      }
    ],
    auditorRoles: [
      {
        checked: false,
        checkboxText: 'Auditor',
        name: 'auditor'
      },
      {
        checked: false,
        checkboxText: 'Donor Auditor',
        name: 'donor_auditor'
      }
    ],
    dashboardReportRoles: [
      {
        checked: false,
        checkboxText: 'Grant Report Manager',
        name: 'grant_report_manager'
      },
      {
        checked: false,
        checkboxText: 'Donor Report Manager',
        name: 'donor_report_manager'
      }
    ],
    donorDonationRoles: [
      {
        checked: false,
        checkboxText: 'Donor Manager',
        name: 'donor_manager'
      },
      {
        checked: false,
        checkboxText: 'Donation Manager',
        name: 'donation_manager'
      }
    ],
    budgetRoles: [
      {
        checked: false,
        checkboxText: 'Submitter',
        name: 'budget_submitter'
      },
      {
        checked: false,
        checkboxText: 'Approver',
        name: 'budget_approver'
      },
      { checked: false, checkboxText: 'Member', name: 'budget_member' }
      // { checked: false, checkboxText: 'Operator', name: 'budget_operator' }
    ],
    grantRoles: [
      {
        checked: false,
        checkboxText: 'Submitter',
        name: 'grant_submitter'
      },
      {
        checked: false,
        checkboxText: 'Member',
        name: 'grant_member'
      }
      // { checked: false, checkboxText: 'Operator', name: 'grant_operator' }
    ],
    // pledgesRoles: [
    //   {
    //     checked: false,
    //     checkboxText: 'Pledge Manager',
    //     name: 'pledge_manager'
    //   }
    // ],
    vendorRoles: [
      {
        checked: false,
        checkboxText: 'Submitter',
        name: 'vendor_submitter'
      },
      { checked: false, checkboxText: 'Approver', name: 'vendor_approver' },
      { checked: false, checkboxText: 'Admin', name: 'vendor_admin' }
    ],
    utilitiesCategoryRoles: [
      {
        checked: false,
        checkboxText: 'Utilities Access',
        name: 'utilities_access'
      }
    ],
    expenseCategoryRoles: [
      {
        checked: false,
        checkboxText: 'Payroll Expense Approver',
        name: 'payroll_expense_approver'
      },
      {
        checked: false,
        checkboxText: 'Payroll Expense Submitter',
        name: 'payroll_expense_submitter'
      },
      {
        checked: false,
        checkboxText: 'Payroll Expense Admin',
        name: 'payroll_expense_admin'
      },
      {
        checked: false,
        checkboxText: 'Default Expense Approver',
        name: 'default_expense_approver'
      },
      {
        checked: false,
        checkboxText: 'Default Expense Submitter',
        name: 'default_expense_submitter'
      },
      {
        checked: false,
        checkboxText: 'Default Expense Admin',
        name: 'default_expense_admin'
      }
    ]
  }
};

const permissionKeys = {
  read_only: 'systemLevelRoles',
  admin: 'systemLevelRoles',
  auditor: 'auditorRoles',
  donor_auditor: 'auditorRoles',
  budget_report_manager: 'dashboardReportRoles',
  grant_report_manager: 'dashboardReportRoles',
  expense_report_manager: 'dashboardReportRoles',
  donor_report_manager: 'dashboardReportRoles',
  donor_manager: 'donorDonationRoles',
  donation_manager: 'donorDonationRoles',
  budget_submitter: 'budgetRoles',
  budget_approver: 'budgetRoles',
  budget_member: 'budgetRoles',
  //  budget_operator: 'budgetRoles',
  grant_submitter: 'grantRoles',
  grant_member: 'grantRoles',
  //  grant_operator: 'grantRoles',
  //  pledges_manager: 'pledgesRoles',
  vendor_submitter: 'vendorRoles',
  vendor_approver: 'vendorRoles',
  vendor_admin: 'vendorRoles',
  utilities_access: 'utilitiesCategoryRoles',
  payroll_expense_approver: 'expenseCategoryRoles',
  payroll_expense_submitter: 'expenseCategoryRoles',
  payroll_expense_admin: 'expenseCategoryRoles',
  default_expense_approver: 'expenseCategoryRoles',
  default_expense_submitter: 'expenseCategoryRoles',
  default_expense_admin: 'expenseCategoryRoles'
};

export const addNewUserRules: Record<string, Rule[]> = {
  firstName: [
    {
      required: true,
      message: 'First name is required!',
      trigger: 'submit'
    }
  ],
  lastName: [
    {
      required: true,
      message: 'Last name is required!',
      trigger: 'submit'
    }
  ],
  emailAddress: [
    {
      type: 'email',
      required: true,
      message: 'Please enter a valid email address',
      trigger: 'submit'
    }
  ],
  password: [
    {
      required: true,
      message: 'Password is required!',
      trigger: 'submit'
    }
  ]
};

export const createFormStateObject = (formState: AddUserFormState, user: any) => {
  formState.firstName = user.first_name;
  formState.lastName = user.last_name;
  formState.emailAddress = user.email;

  const permissionsArray = user.role.split(',').filter(Boolean);
  for (const permission of permissionsArray) {
    const key = permissionKeys[permission as keyof typeof permissionKeys] || 'expenseCategoryRoles';

    formState.permissions[key as keyof AddUserFormState['permissions']].forEach((p: any) => {
      if (p.name === permission) {
        p.checked = true;
      }
    });
  }
  return formState;
};

export const createFormData = (formState: any) => {
  const formData: any = {
    first_name: formState.firstName,
    last_name: formState.lastName,
    email: formState.emailAddress,
    password: formState.password
  };

  const roleArray: string[] = [];
  for (const p of Object.values(formState.permissions)) {
    for (const p2 of p as any) {
      if (p2.checked) {
        roleArray.push(p2.name);
      }
    }
  }
  formData.role = roleArray.join(',');
  return formData;
};
