<script setup lang="ts">
  import { formatToIndianCurrency } from '~/functions/currencyUtilities';
  import type { GrantsAndBudgetAllocation } from '~/types';

  const props = defineProps<{
    totalInvoiceAmount: number | null;
    allocationDetails: GrantsAndBudgetAllocation[];
    totalAllocationAmount?: number | null;
    showTds?: boolean;
  }>();

  const tdsAmount = ref(0);

  watch(props.allocationDetails, newVal => {
    tdsAmount.value = newVal.reduce((acc, curr) => acc + (curr.accountInfo.tdsAmount ?? 0), 0);
  });
</script>
<template>
  <div
    class="flex flex-col bg-white text-gray-600 sm-medium w-full border border-gray-200 rounded shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_0px_#0000000F]"
  >
    <div class="flex items-center justify-between w-full p-3 border-b border-gray-200">
      <p>Total Invoice Amount</p>
      <p>{{ formatToIndianCurrency(props.totalInvoiceAmount) }}</p>
    </div>
    <div v-if="showTds" class="flex items-center justify-between w-full px-3 py-2 border-b border-gray-200">
      <p>TDS</p>
      <p>{{ formatToIndianCurrency(tdsAmount) }}</p>
    </div>
    <div class="flex flex-col gap-3 w-full p-3 border-b border-gray-200">
      <div v-for="(allocation, index) in allocationDetails" :key="index" class="flex w-full justify-between">
        <div class="flex flex-col">
          <div class="flex flex-col">
            <div class="flex gap-2 items-center xs-medium">
              <p class="w-16 text-gray-500">Grant</p>
              <p class="w-32 truncate">{{ allocation.expenseGrants }}</p>
            </div>
            <div class="flex gap-2 items-center xs-medium">
              <p class="w-16 text-gray-500">Budget</p>
              <p class="w-32 truncate">{{ allocation.expenseGrants }}</p>
            </div>
            <div class="flex gap-2 items-center xs-medium">
              <p class="w-16 text-gray-500">Purpose</p>
              <p class="w-32 truncate">{{ allocation.purpose }}</p>
            </div>
          </div>
          <div class="flex gap-2 xs-medium">
            <p class="text-gray-800">{{ allocation.accountInfo.bookOfAccount }}</p>
          </div>
        </div>
        <p>{{ formatToIndianCurrency(allocation.allocationAmount) }}</p>
      </div>
      <div v-if="props.totalAllocationAmount" class="flex w-full justify-between">
        <p>Total Allocated Amount</p>
        <p>{{ formatToIndianCurrency(props.totalAllocationAmount) }}</p>
      </div>
    </div>
    <div
      v-if="props.totalInvoiceAmount && props.totalAllocationAmount"
      class="flex items-center justify-between w-full p-3"
    >
      <p>Allocation Difference</p>
      <p class="text-red-600">
        {{ formatToIndianCurrency((props.totalInvoiceAmount ?? 0) - (props.totalAllocationAmount ?? 0)) }}
      </p>
    </div>
  </div>
</template>
