export interface LoginResponse {
  first_name: string;
  last_name: string;
  role: string;
  user_email: string;
  expenses_module_enabled: boolean;
}

export enum Role {
  Admin = 'admin',
  DonationManager = 'donation_manager',
  DonorManager = 'donor_manager',
  ReadOnly = 'read-only',
  Auditor = 'auditor',
  DonorAuditor = 'donor_auditor',
  GrantReportManager = 'grant_report_manager',
  ExpenseReportManager = 'expense_report_manager',
  BudgetReportManager = 'budget_report_manager',
  DonorReportManager = 'donor_report_manager',
  ExpenseApprover = '_expense_approver',
  ExpenseAdmin = '_expense_admin',
  ExpenseSubmitter = 'expense_submitter',
  BudgetApprover = 'budget_approver',
  BudgetMember = 'budget_member',
  GrantSubmitter = 'grant_submitter',
  GrantMember = 'grant_member',
  UtilitiesAccess = 'utilities_access',
  VendorSubmitter = 'vendor_submitter',
  VendorApprover = 'vendor_approver',
  VendorAdmin = 'vendor_admin',
  AdvanceSubmitter = 'advance_submitter',
  AdvanceApprover = 'advance_approver',
  AdvanceAdmin = 'advance_admin',
  OrgReportManager = 'org_report_manager',
  PayeeReportManager = 'payee_report_manager',
  DefaultExpenseApprover = 'default_expense_approver',
  DefaultExpenseSubmitter = 'default_expense_submitter',
  PayrollExpenseApprover = 'payroll_expense_approver',
  PayrollExpenseSubmitter = 'payroll_expense_submitter',
  CustomExp3ExpenseApprover = 'custom_exp3_expense_approver',
  CustomExp3ExpenseSubmitter = 'custom_exp3_expense_submitter'
}

export interface PermissionConfig {
  [route: string]: {
    [action: string]: Role[];
  };
}
