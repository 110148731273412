<script setup lang="tsx">
  import { MailIcon, PencilIcon, TrashIcon } from '@heroicons/vue/solid';
  import { cloneDeep } from 'lodash';
  import CheckboxCollection from '~/components/Admin/Form/CheckboxCollection.vue';
  import ExpenseCategoryCheckbox from '~/components/Admin/Form/ExpenseCategoryCheckbox.vue';
  import { addUserFormDefaultState, createFormStateObject } from '~/components/Admin/Form/UserUtils';
  import HeaderDisplay from '~/components/UI/Drawers/HeaderDisplay.vue';
  import InfoRow from '~/components/UI/Drawers/InfoRow.vue';
  import InfoSection from '~/components/UI/Drawers/InfoSection.vue';
  import { del, get, post } from '~/services/donationScreen';
  import type { AddUserFormState, GetResponse, GetUserResponse, UserInformationDrawer } from '~/types';

  const props = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const route = useRoute();

  const { isExpenseEnabled } = useAuth();
  const userInformationResponse = reactive<AddUserFormState>(cloneDeep(addUserFormDefaultState));
  const isEmailVerified = ref<boolean>(false);

  const fetchData = async () => {
    try {
      const response = await get<GetUserResponse>(`v1/get_user/${props.identifier}`);
      const selectedUser = response?.data?.data;
      isEmailVerified.value = selectedUser?.email_verified;

      const customRoles = response?.data?.data?.custom_exp_roles;
      if (customRoles && typeof customRoles === 'object') {
        Object.entries(customRoles).forEach(([key, value]) => {
          userInformationResponse.permissions.expenseCategoryRoles.push({
            checked: false,
            checkboxText: value,
            name: key
          });
        });
      }

      const formStateObject = createFormStateObject(userInformationResponse, selectedUser);
      Object.assign(userInformationResponse, cloneDeep(formStateObject));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const sendEmailVerification = async () => {
    try {
      const response = await post<GetResponse<UserInformationDrawer>>(
        `v1/users/resend_verification_link/${props.identifier}`
      );
      if (response.status === 200) {
        openSuccessfulCustomNotification('Email verification sent successfully');
      }
    } catch (err) {
      console.error(err);
      openFailedCustomNotification('Failed to send email verification');
    }
  };

  const deleteUser = async () => {
    try {
      const response = await del(`v1/users/delete_user/${props.identifier}`);
      if (response.status === 200) {
        openSuccessfulCustomNotification('User deleted successfully');
      }
    } catch (err) {
      console.error(err);
      openFailedCustomNotification('Failed to delete user');
    }
  };

  onMounted(() => {
    if (props.identifier) {
      fetchData();
    }
  });

  onMounted(() => {
    if (isExpenseEnabled.value) {
      userInformationResponse.permissions.dashboardReportRoles.push(
        {
          checked: false,
          checkboxText: 'Expense Report Manager',
          name: 'expense_report_manager'
        },
        {
          checked: false,
          checkboxText: 'Budget Report Manager',
          name: 'budget_report_manager'
        }
      );
    }
  });
</script>

<template>
  <a-drawer
    :open="route.query.drawerFrom === 'userInformation'"
    title="Add New Stock Item"
    width="824"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    @close="props.onClose"
  >
    <template #extra>
      <div class="flex gap-2">
        <a-button class="!px-2 !py-1.5 w-fit h-fit flex items-center gap-1 !sm-medium" @click="deleteUser">
          <TrashIcon class="w-4 h-4" />
          Delete user
        </a-button>
        <a-button
          :disabled="isEmailVerified"
          class="!px-2 !py-1.5 w-fit h-fit flex items-center gap-1 !sm-medium"
          @click="sendEmailVerification"
        >
          <MailIcon class="w-4 h-4" />
          Send Email Verification
        </a-button>
        <nuxt-link :to="`/admin/edit_user?identifier=${props.identifier}`">
          <a-button class="!px-2 !py-1.5 w-fit h-fit flex items-center gap-1 !sm-medium">
            <PencilIcon class="w-4 h-4" />
            Edit User
          </a-button>
        </nuxt-link>
      </div>
    </template>
    <div class="flex flex-col w-full">
      <HeaderDisplay
        :main-display="userInformationResponse.firstName + ' ' + userInformationResponse.lastName"
        :sub-display="userInformationResponse.emailAddress"
      />
      <InfoSection>
        <InfoRow label="Email" :value="userInformationResponse.emailAddress" />
      </InfoSection>
      <InfoSection title="Permissions">
        <div class="flex px-4 gap-3">
          <div class="w-[30%] min-w-[250px] flex flex-col gap-3">
            <CheckboxCollection
              title="System Level Roles"
              state-key="systemLevelRoles"
              :is-disabled="true"
              :checkbox-collection="userInformationResponse.permissions.systemLevelRoles"
            />
            <CheckboxCollection
              title="Auditor Roles"
              state-key="auditorRoles"
              :is-disabled="true"
              :checkbox-collection="userInformationResponse.permissions.auditorRoles"
            />
            <CheckboxCollection
              title="Dashboard & Report Roles"
              state-key="dashboardReportRoles"
              :is-disabled="true"
              :checkbox-collection="userInformationResponse.permissions.dashboardReportRoles"
            />
            <CheckboxCollection
              title="Donor & Donation Roles"
              state-key="donorDonationRoles"
              :is-disabled="true"
              :checkbox-collection="userInformationResponse.permissions.donorDonationRoles"
            />
          </div>
          <div class="w-[30%] min-w-[250px] flex flex-col gap-3">
            <CheckboxCollection
              title="Budget Roles"
              state-key="budgetRoles"
              :is-disabled="true"
              :checkbox-collection="userInformationResponse.permissions.budgetRoles"
            />
            <CheckboxCollection
              title="Grant Roles"
              state-key="grantRoles"
              :is-disabled="true"
              :checkbox-collection="userInformationResponse.permissions.grantRoles"
            />
            <!-- <CheckboxCollection
              title="Pledges Roles"
              state-key="pledgesRoles"
              :is-disabled="true"
              :checkbox-collection="userInformationResponse.permissions.pledgesRoles"
            />
            /> -->
            <CheckboxCollection
              title="Vendor Roles"
              state-key="vendorRoles"
              :is-disabled="true"
              :checkbox-collection="userInformationResponse.permissions.vendorRoles"
            />
            <CheckboxCollection
              title="Utilities Category Roles"
              state-key="utilitiesCategoryRoles"
              :is-disabled="true"
              :checkbox-collection="userInformationResponse.permissions.utilitiesCategoryRoles"
            />
          </div>
          <div v-if="isExpenseEnabled" class="w-[30%] min-w-[250px] flex flex-col gap-3">
            <ExpenseCategoryCheckbox
              title="Expense Category Roles"
              state-key="expenseCategoryRoles"
              :is-disabled="true"
              :checkbox-collection="[...userInformationResponse.permissions.expenseCategoryRoles]"
            />
          </div>
        </div>
      </InfoSection>
    </div>
  </a-drawer>
</template>

<style>
  @import '~/assets/css/donationDrawer.css';

  .setup-drawer-form .ant-form-item {
    margin-bottom: 0px;
  }
</style>
