import type { AxiosResponse } from 'axios';
import { getDefaultUrl } from '~/plugins/axios';
import type { CustomFile } from '~/types';

export const viewResponseFile = (response: AxiosResponse<any, any>) => {
  const file = new Blob([response.data], {
    type: response.headers['content-type']
  });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL, '_blank', 'width=800,height=600');
};

export const viewLocalFile = (file: CustomFile) => {
  const url = URL.createObjectURL(file.rawFile as Blob);
  window.open(url, '_blank', 'width=800,height=600');
};

export const downloadResponseFile = (response: AxiosResponse<any, any>, fileName: string) => {
  // Create blob with the correct type from response headers
  const blob = new Blob([response.data], {
    type: response.headers['content-type']
  });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(link.href);
};

export const downloadLocalFile = (file: CustomFile) => {
  const url = URL.createObjectURL(file.rawFile as Blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = file.filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const shareOnWhatsApp = (identifier: string) => {
  // Get the URL at runtime when the function is called
  const baseUrl = getDefaultUrl();
  const message = encodeURIComponent(`${baseUrl}/v1/donation_receipts/${identifier}`);
  const whatsappUrl = `https://api.whatsapp.com/send?text=Dear+Donor%2C+thank+you+for+your+contribution+to+ERP4Impact+Foundation.+Please+click+the+link+below+for+your+receipt%3A+${message}`;
  window.open(whatsappUrl, '_blank');
};
