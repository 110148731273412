<script setup lang="tsx">
  import { PencilIcon } from '@heroicons/vue/solid';
  import { get } from '~/services/donationScreen';
  import type {
    AddNewExpenseAllocationDropdownApiResponse,
    AllocationBudgetOptions,
    AllocationGrantsOptions,
    GetExpenseResponse,
    ResponseStatus
  } from '~/types';
  import InfoRow from '~/components/UI/Drawers/InfoRow.vue';
  import InfoSection from '~/components/UI/Drawers/InfoSection.vue';
  import DrawerLayout from '~/components/UI/Drawers/DrawerLayout.vue';
  import { formatToIndianCurrency } from '~/functions/currencyUtilities';
  import GrantsAndBudgetAllocation from '../RoutedDrawerComponents/GrantsAndBudgetAllocation.vue';
  import { cloneDeep } from 'lodash';
  import dayjs from 'dayjs';
  import AuditLogs from '~/components/shared/RoutedDrawers/RoutedDrawerComponents/AuditLogs.vue';
  import { getPaymentsDetails, getPaymentSummary } from '~/components/Expenses/Table/expenseTableUtils';
  import RoleBasedLinkButton from '~/components/UI/Others/RoleBasedLinkButton.vue';
  import type { AddNewRecurringExpenseFormState } from '~/types/recurringExpenses';
  import {
    getApproversNames,
    getTitleFromValue,
    parseFormDataToState,
    recurringDayOfMonthOptions,
    recurringExpenseFormDefaultState,
    recurringTypes
  } from '~/components/RecurringExpenses/Forms/newRecurringExpensesUtils';
  const { onClose, identifier } = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const data = ref<AddNewRecurringExpenseFormState>(cloneDeep(recurringExpenseFormDefaultState));
  const responseState = ref<ResponseStatus>('success');
  const isExpenseCancelled = ref<boolean>(false);
  const approversNames = ref<string[]>([]);
  const paymentAllocations = ref();
  const paymentsDetails = ref();

  const fetchData = async () => {
    responseState.value = 'pending';
    try {
      const expenseResponse = await get<GetExpenseResponse>(`/v1/get_recurring_expenses_template/${identifier}`);
      if (expenseResponse?.status === 200) {
        const selectedExpense = expenseResponse?.data?.data?.selected_expense;
        const allocationDropdownResponse = await get<AddNewExpenseAllocationDropdownApiResponse>(
          `/v1/add_new_expense/${selectedExpense.category}`
        );

        const budgetOptions = allocationDropdownResponse?.data?.data?.budgets_list?.map(budget => ({
          mainLabel: budget.budget_name,
          subLabel: budget.budget_id,
          value: budget.budget_id_value,
          amount: budget.amount - budget.utilization.approved + budget.utilization.not_approved,
          lineItem: budget.line_items.map(lineItem => ({
            label: `${lineItem.data.li} - ${lineItem.data.sub_li}`,
            value: lineItem.data.li_value,
            amount: lineItem.amount - lineItem.utilized.approved + lineItem.utilized.not_approved
          }))
        })) as AllocationBudgetOptions[];

        const grantOptions = allocationDropdownResponse?.data?.data?.grants_list?.map(grant => ({
          mainLabel: grant.grant_id,
          subLabel: grant.donor_name,
          value: grant.grant_id_value,
          amount: grant.amount - grant.utilization.approved + grant.utilization.not_approved,
          purpose: grant.purpose,
          lineItem: grant.line_items.map(lineItem => ({
            label: `${lineItem.data.li} - ${lineItem.data.sub_li}`,
            value: lineItem.data.li_value,
            amount: lineItem.amount - lineItem.utilized.approved + lineItem.utilized.not_approved
          }))
        })) as AllocationGrantsOptions[];

        const parsedData = parseFormDataToState(selectedExpense, true);
        // We are not storing all the data that we need to show in the drawer.
        // Get other fields from the allocation dropdown response.
        parsedData.grantsAndBudgetAllocation = parsedData.grantsAndBudgetAllocation.map((item: any) => {
          const budgetOption = budgetOptions.find(budget => budget.value === item.expenseBudget);
          const grantOption = grantOptions.find(grant => grant.value === item.expenseGrants);
          const budgetSubLabel = budgetOption?.subLabel;
          const grantSubLabel = grantOption?.subLabel;
          const budgetLineItemAmount = budgetOption?.lineItem.find(
            lineItem => lineItem.value === item.expenseLineItem
          )?.amount;
          const grantLineItemAmount = grantOption?.lineItem.find(
            lineItem => lineItem.value === item.grantsLineItem
          )?.amount;
          return { ...item, budgetSubLabel, grantSubLabel, budgetLineItemAmount, grantLineItemAmount };
        });

        approversNames.value = getApproversNames(parsedData);

        isExpenseCancelled.value = selectedExpense.is_cancelled;
        paymentAllocations.value = getPaymentSummary(selectedExpense.line_items);
        paymentsDetails.value = getPaymentsDetails(selectedExpense.payments);
        data.value = parsedData;
        responseState.value = 'success';
      } else {
        responseState.value = 'error';
      }
    } catch (error) {
      openFailedCustomNotification('Error fetching expense details!');
      console.error('Error fetching data:', error);
    }
  };

  onMounted(fetchData);
</script>

<template>
  <a-drawer
    v-if="identifier"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    title="Recurring Expense Information"
    :open="identifier !== null"
    width="1220"
    @close="onClose"
  >
    <template #extra>
      <div v-if="!isExpenseCancelled" class="flex gap-2">
        <RoleBasedLinkButton
          component-name="editActionButton"
          active-class="text-blue-700 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center text-gray-500 border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          disabled-class="flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          :to="`/recurring-expenses/edit-recurring-expense?identifier=${identifier}`"
        >
          <PencilIcon class="h-4 w-4" />
          Edit Recurring Expense
        </RoleBasedLinkButton>
      </div>
      <p v-else class="text-base font-bold text-red-600">Expense is Closed</p>
    </template>
    <DrawerLayout
      :response-state="responseState"
      :main-display="data.partyName"
      :sub-display="data.invoiceNumber"
      :side-display="formatToIndianCurrency(data.totalAmount)"
      @close="onClose"
    >
      <template #column1>
        <div class="p-3">
          <a-alert v-if="data.approvalStatus === 'approved'" message="Expense is Approved" type="success" show-icon />
          <a-alert
            v-if="data.approvalStatus === 'pending'"
            message="Expense is Pending Approval"
            type="warning"
            show-icon
          />
          <a-alert v-if="data.approvalStatus === 'draft'" message="Expense is Draft" type="info" show-icon />
        </div>
        <InfoSection>
          <InfoRow label="Expense Type" :value="data.expenseType" class="capitalize" />
          <InfoRow
            label="Invoice Date"
            :value="data.invoiceDate ? dayjs(data.invoiceDate).format('DD/MM/YYYY') : '-'"
          />
          <InfoRow label="Payee" :value="data.partyName" />
          <InfoRow label="Description" :value="data.description" />
        </InfoSection>
        <InfoSection title="Recurring Configuration">
          <InfoRow
            label="Start Date"
            :value="data.recurringConfigStartDate ? dayjs(data.recurringConfigStartDate).format('DD/MM/YYYY') : '-'"
          />
          <InfoRow
            label="End Date"
            :value="data.recurringConfigEndDate ? dayjs(data.recurringConfigEndDate).format('DD/MM/YYYY') : '-'"
          />
          <InfoRow
            label="Recurring Type"
            :value="getTitleFromValue(data.recurringConfigRecurringType, recurringTypes)"
          />
          <InfoRow
            label="Which day of the month"
            :value="getTitleFromValue(data.recurringConfigDayOfMonth, recurringDayOfMonthOptions)"
          />
        </InfoSection>
        <GrantsAndBudgetAllocation :allocations="data.grantsAndBudgetAllocation" />
        <InfoSection title="Approval">
          <InfoRow
            v-for="(name, index) in approversNames"
            :key="index"
            :label="`Approver #${index + 1}`"
            :value="name.split('-')[0]"
          />
        </InfoSection>
      </template>
      <template #column2>
        <AuditLogs :items="data?.auditLogs" title="Recurring Expense" />
      </template>
    </DrawerLayout>
  </a-drawer>
</template>

<style>
  @import '~/assets/css/donationDrawer.css';
</style>
